import { IProduct } from 'models/product'
import Link from 'next-translate-routes/link'
import React from 'react'
import Slider from 'react-slick'

import { PictureTag } from '../helper/picture-tag'

export interface EndlessSliderProps {
  Products: IProduct[]
  Headline: string
  bgColor: string
  sizeVW: string
}

export function SampleNextArrow(props) {
  const { className, style, onClick } = props

  return (
    <button
      className={`w-4 sm:w-4 h-4 sm:h-4 ${className}`}
      style={{
        ...style,
        display: 'block',
        right: '-5px',
        background: 'transparent',
      }}
      onClick={onClick}
    >
      <svg viewBox="0 0 11 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>arrow_forward_ios</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Rounded" transform="translate(-345.000000, -3434.000000)">
            <g id="Navigation" transform="translate(100.000000, 3378.000000)">
              <g
                id="-Round-/-Navigation-/-arrow_forward_ios"
                transform="translate(238.000000, 54.000000)"
              >
                <g>
                  <polygon
                    id="Path"
                    opacity="0.87"
                    points="24 24 0 24 0 0 24 0"
                  ></polygon>
                  <path
                    d="M7.38,21.01 C7.87,21.5 8.66,21.5 9.15,21.01 L17.46,12.7 C17.85,12.31 17.85,11.68 17.46,11.29 L9.15,2.98 C8.66,2.49 7.87,2.49 7.38,2.98 C6.89,3.47 6.89,4.26 7.38,4.75 L14.62,12 L7.37,19.25 C6.89,19.73 6.89,20.53 7.38,21.01 Z"
                    id="🔹-Icon-Color"
                    fill="#1D1D1D"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  )
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props

  return (
    <button
      className={`w-4 sm:w-4 h-4 sm:h-4 z-20  ${className}`}
      style={{
        ...style,
        left: '-5px',
        display: 'block',
        background: 'transparent',
      }}
      onClick={onClick}
    >
      <svg viewBox="0 0 11 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>arrow_back_ios</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Rounded" transform="translate(-548.000000, -3434.000000)">
            <g id="Navigation" transform="translate(100.000000, 3378.000000)">
              <g
                id="-Round-/-Navigation-/-arrow_back_ios"
                transform="translate(442.000000, 54.000000)"
              >
                <g>
                  <polygon
                    id="Path"
                    opacity="0.87"
                    points="0 0 24 0 24 24 0 24"
                  ></polygon>
                  <path
                    d="M16.62,2.99 C16.13,2.5 15.34,2.5 14.85,2.99 L6.54,11.3 C6.15,11.69 6.15,12.32 6.54,12.71 L14.85,21.02 C15.34,21.51 16.13,21.51 16.62,21.02 C17.11,20.53 17.11,19.74 16.62,19.25 L9.38,12 L16.63,4.75 C17.11,4.27 17.11,3.47 16.62,2.99 Z"
                    id="🔹-Icon-Color"
                    fill="#1D1D1D"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  )
}

export const EndlessSlider = ({
  Products,
  Headline,
  bgColor,
  sizeVW,
}: EndlessSliderProps) => {
  const settings = {
    dots: true,
    speed: 500,
    arrows: true,
    infinite: true,
    slidesToShow: 5,
    centerMode: true,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="z-0">
      <h2
        className={`pt-4 pb-8
					text-4xl italic font-bold font-swDisp text-center`}
      >
        {Headline}
      </h2>
      <div className="z-0 w-full">
        <Slider {...settings}>
          {Products.map((child, index) => (
            <>
              {child.Images && child.Images.length > 0 && (
                <Link href={`/produkt/${child.Slug}`}>
                  <a>
                    <PictureTag
                      imageUrl={child.Images[0]?.image.fullpath}
                      className={`${75} z-0`}
                      sizesVW={sizeVW}
                    />
                  </a>
                </Link>
              )}
            </>
          ))}
        </Slider>
      </div>
    </div>
  )
}

EndlessSlider.defaultProps = {
  itemcount: 4,
  bgColor: 'grey',
  centeredHeadline: false,
  largeText: false,
  sizeVW: '50',
}
export default React.memo(EndlessSlider)
