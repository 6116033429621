import React, { FC } from 'react'

interface IProps {
  className?: string
  width?: number
  children?: any
}

const IconBasket: FC<IProps> = ({
  className,
  width = 40,
  children,
}: IProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        className={className}
        viewBox="0 0 22 27.704"
      >
        <g
          id="Component_34_2"
          data-name="Component 34 – 2"
          transform="translate(0 1.5)"
        >
          <g
            id="Rectangle_10"
            data-name="Rectangle 10"
            transform="translate(0 5.204)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          >
            <rect width="22" height="21" stroke="none" />
            <rect x="1.5" y="1.5" width="19" height="18" fill="none" />
          </g>
          <path
            id="Path_127"
            data-name="Path 127"
            d="M738.854,402.343V397.71a4.161,4.161,0,1,0-8.322,0v4.633"
            transform="translate(-724.032 -393.503)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </svg>
      {children}
    </>
  )
}

export default IconBasket
