import { RecipeBox } from 'components/components/recipe-box'
import { IRecipe } from 'models/recipe'
import React from 'react'
import Slider from 'react-slick'

import useBreakpoints from '../../hooks/use-breakpoints'
import { SampleNextArrow, SamplePrevArrow } from './endless-slider'

export interface RecipeSliderProps {
  recipes: IRecipe[]
  title: string
  bgColor: string
  itemcount?: number
  centeredHeadline?: boolean
  sizeVW?: string
}

export const RecipeSlider = ({
  recipes,
  title,
  itemcount,
  centeredHeadline,
}: RecipeSliderProps) => {
  const breakpoints = useBreakpoints()

  const settings = {
    dots: breakpoints.isXs,
    speed: 500,
    slidesToShow: itemcount,
    slidesToScroll: 1,
    infinite: false,

    nextArrow: breakpoints.isXs ? <></> : <SampleNextArrow />,
    prevArrow: breakpoints.isXs ? <></> : <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <>
      <h2
        className={`pt-4 pb-8 text-xl italic font-bold font-swDisp w-full ${
          centeredHeadline === true ? 'text-4xl text-center' : ''
        }`}
      >
        {title}
      </h2>
      <div className="z-0 w-full">
        <Slider {...settings}>
          {recipes &&
            recipes.length > 0 &&
            recipes.map((child) => (
              <div className={`px-2`} key={child.id}>
                <RecipeBox data={child} />
              </div>
            ))}
        </Slider>
      </div>
    </>
  )
}

RecipeSlider.defaultProps = {
  itemcount: 4,
  bgColor: 'grey',
  centeredHeadline: true,
  sizeVW: '10',
}
