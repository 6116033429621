import { PictureTag } from 'components/helper/picture-tag'
import { IProduct } from 'models/product'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import React, { useContext, useEffect, useState } from 'react'

import { formatter } from '../../helper/currency-formatter'
import useBreakpoints from '../../hooks/use-breakpoints'
import { ReviewMiniView } from '../../pages/produkt/[slug]'
import { shopContext } from '../../providers/shop-context'
import CtaButtons from './cta-buttons'
import { WishlistButton } from './wishlist-button'

export interface ProductBoxProps {
  data: IProduct
  bgColor?: string
  showCta?: boolean
  showPrize?: boolean
  center?: boolean
  imageSize?: string
  sizeVW?: string
  className?: string
}

interface ProductPriceProps {
  shopwareProduct: any
}

export const ProductPrice = ({ shopwareProduct }: ProductPriceProps) => {
  const { getGroupPrice } = useContext(shopContext)
  const groupPrice = getGroupPrice(shopwareProduct)
  const reducedPrice =
    shopwareProduct?.price?.length > 0 &&
    shopwareProduct?.price[0].listPrice &&
    shopwareProduct?.price[0].listPrice.gross

  const fullPrice =
    (shopwareProduct?.price?.length > 0 &&
      shopwareProduct?.price[0].listPrice &&
      shopwareProduct?.price[0].gross) ||
    shopwareProduct?.price[0]?.gross

  const percentage = ((fullPrice - reducedPrice) / reducedPrice) * 100

  return (
    <div className={`flex flex-col`}>
      <p className=" pt-4 font-swDisp text-xl font-bold dark:text-white sm:text-2xl">
        {Number.isFinite(percentage) && (
          <span className={`pr-2 text-lg sm:text-xl text-9 `}>
            {Math.ceil(percentage)}%
          </span>
        )}
        {fullPrice && formatter.format(groupPrice ?? fullPrice)}
      </p>
      <p className="font-swText text-base font-normal dark:text-white">
        {!groupPrice && reducedPrice && (
          <>
            UVP:{' '}
            <span className={`line-through`}>
              {formatter.format(reducedPrice)}
            </span>
          </>
        )}
      </p>
    </div>
  )
}

export const LineProductPrice = ({ shopwareProduct }: ProductPriceProps) => {
  const { getGroupPrice } = useContext(shopContext)
  const groupPrice = getGroupPrice(shopwareProduct)
  const reducedPrice =
    shopwareProduct?.price?.length > 0 &&
    shopwareProduct?.price[0].listPrice &&
    shopwareProduct?.price[0].listPrice.gross

  const fullPrice =
    (shopwareProduct?.price?.length > 0 &&
      shopwareProduct?.price[0].listPrice &&
      shopwareProduct?.price[0].gross) ||
    shopwareProduct?.price[0]?.gross

  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center">
      <span className="font-swText text-base font-normal dark:text-white pl-4">
        <span className={`font-swText font-normal`}>{t('ONLY')}</span>
        <span
          className={`pr-1 text-xs sm:text-xl text-red-500 line-through`}
        ></span>
        {fullPrice && formatter.format(groupPrice ?? fullPrice)}
        <sup>*</sup>
      </span>
      <span>
        {!groupPrice && reducedPrice && formatter.format(reducedPrice)}
      </span>
    </div>
  )
}

export const productBox = ({
  data,
  showCta,
  showPrize,
  center,
  sizeVW,
  className,
  bgColor,
}: ProductBoxProps) => {
  const { isXs } = useBreakpoints()

  const { t } = useTranslation('common')

  const [selectedVariant, setSelectedVariant] = useState<IProduct>()

  const { getIdForShopwareProduct, shopwareProducts } = useContext(shopContext)

  const [shopwareProduct, setShopwareProduct] = useState<any>()

  useEffect(() => {
    setShopwareProduct(getIdForShopwareProduct(selectedVariant || data))
  }, [shopwareProducts, data, selectedVariant])

  useEffect(() => {
    setSelectedVariant(
      data.children &&
        data.children.filter((child) => child.classname === 'Product')?.length >
          0
        ? (data.children
            .filter((child) => child.classname === 'Product')
            ?.find((x) =>
              x.OSProductNumber?.toLowerCase().includes('xm')
            ) as IProduct) ??
            (data.children.filter(
              (child) => child.classname === 'Product'
            )[0] as IProduct)
        : data
    )
  }, [])

  if (shopwareProduct === undefined && showPrize === true) {
    return <></>
  }
  return (
    <>
      {data &&
        ((shopwareProduct &&
          shopwareProduct?.price?.length > 0 &&
          shopwareProduct?.price[0]?.gross > 0) ||
          !showPrize) && (
          <>
            <div
              className={
                className &&
                `h-full flex flex-col flex-grow justify-content items-center content-center p-2 hover:shadow-lg`
              }
            >
              <Link href={`/produkt/${data.Slug}`}>
                <div
                  className={`mx-auto flex mb-auto flex-grow flex-0 cursor-pointer relative ${
                    bgColor ? `bg-${bgColor} bg-opacity-70` : 'bg-product-bg'
                  } dark:bg-transparent`}
                >
                  <div>
                    {data.Sale && data.Sale[0] && (
                      <>
                        {(data.Sale[0].Shop === undefined ||
                          data.Sale[0].Shop?.find(
                            (x) =>
                              x.id.toString() ===
                              process.env.NEXT_PUBLIC_SHOPWARE_ID
                          )) && (
                          <div
                            className={`rotate-90 absolute origin-bottom-left -mt-4 z-30 px-4  bg-${data.Sale[0].Farbe}`}
                          >
                            <div className="m-auto flex font-bold sm:text-2xl">
                              {data.Sale[0].Name}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <PictureTag
                      imageUrl={
                        (selectedVariant?.DosenBild &&
                          selectedVariant?.DosenBild.fullpath) ??
                        (data.Images && data?.Images[0]?.image?.fullpath) ??
                        '/sonstiges/failover.png'
                      }
                      sizesVW={sizeVW}
                      className={`w-full sm:w-9/12 mx-auto p-4`}
                      altText={
                        selectedVariant?.DosenBild?.alt ?? data.ArticleName
                      }
                    />
                  </div>
                  <WishlistButton productId={shopwareProduct?.id} />
                </div>
              </Link>
              <div className="mt-auto w-full pt-4 grid grid-cols-1 sm:grid-cols-2">
                <div className={`flex flex-col`}>
                  <div className={`flex-col flex sm:hidden`}>
                    <div
                      className={`text-center sm:text-left mx-auto sm:mr-auto`}
                    >
                      <ReviewMiniView data={data} />
                    </div>
                    {showPrize === true && shopwareProduct && (
                      <div className={`sm:text-right ml-auto hidden sm:flex`}>
                        <ProductPrice shopwareProduct={shopwareProduct} />
                      </div>
                    )}
                  </div>
                  <Link href={`/produkt/${data.Slug}`}>
                    <a>
                      <p
                        className={`relative text-center sm:text-left mx-auto text-sm sm:text-base italic font-bold max-w-full
                        } font-swDisp dark:text-white h-12 sm:h-auto`}
                      >
                        {data.ArticleName}
                      </p>
                    </a>
                  </Link>
                </div>
                {showPrize === true && shopwareProduct && (
                  <div
                    className={`text-center sm:text-right mx-auto sm:ml-auto flex sm:hidden`}
                  >
                    <ProductPrice shopwareProduct={shopwareProduct} />
                  </div>
                )}
                <div className={` flex-col hidden sm:flex`}>
                  <ReviewMiniView data={data} />
                  {showPrize === true && shopwareProduct && (
                    <div className={`sm:text-right ml-auto`}>
                      <ProductPrice shopwareProduct={shopwareProduct} />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-auto w-full">
                {showCta === true && (
                  <CtaButtons
                    data={selectedVariant}
                    rawData={data}
                    shopwareProduct={shopwareProduct}
                    isSmall={true}
                  />
                )}
              </div>
            </div>
          </>
        )}
    </>
  )
}

productBox.defaultProps = {
  bgColor: 'grey',
  showCta: true,
  imageSize: 'w-2/3',
  showPrize: true,
  center: true,
  sizeVW: '10',
  className: 'z-0',
}

export default React.memo(productBox)
