import axios from 'axios'
import _ from 'lodash'
import { useTranslation } from 'next-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { Element } from 'react-scroll'

import { useProductElements } from '../../graph/get-product-elements'
import useBreakpoints from '../../hooks/use-breakpoints'
import { IProduct } from '../../models/product'
import {
  LinkElement,
  ReviewContainer,
  ReviewDetail,
} from '../../pages/produkt/[slug]'
import { shopContext } from '../../providers/shop-context'
import { ComponentFactory } from '../component-factory'
import { ProductDetail } from '../components/product-details'
import { ProductRecommendations } from '../recommendations/product-recommendations'
import { RecipeSlider } from '../slider/recipe-slider'
import { BaseText } from '../text/base-text'

interface ProductContentDefaultProps {
  data: IProduct
  selectedVariant?: IProduct
}

export const ReviewDetailContainer = (data: IProduct) => {
  const [showAll, setShowAll] = useState(false)

  const { t } = useTranslation('common')

  const { isLoggedIn } = useContext(shopContext)

  const { isXs } = useBreakpoints()

  const [reviewData, setReviewData] = useState(undefined)

  useEffect(() => {
    axios
      .get(
        `https://pim.stayspiced.com/spiceworld/reviews/${data.OSProductNumber}`
      )
      .then((res) => {
        setReviewData(res.data)
      })
      .catch((err) => {})
  }, [data])

  return (
    <div
      className={`border-t border-b -mx-4 sm:mx-0  border-gray-300 py-4 my-8 bg-${data.ColorCode} bg-opacity-70`}
    >
      <Element name="reviews" className="element">
        <h2 className="pl-4 font-swDisp text-2xl font-bold italic dark:text-white sm:text-4xl">
          {t('RATINGS')}
        </h2>
        <div className="flex flex-row flex-wrap">
          <div className="smborder-r mx-auto flex w-full flex-wrap border-b border-black sm:w-5/12 sm:border-b-0 ">
            <div className="mx-auto mb-auto flex w-full flex-wrap">
              {reviewData?.count > 0 && (
                <>
                  <div className="mx-auto flex shrink flex-col">
                    <div
                      className={`cursor-pointer p-1 sm:p-0 w-32 h-32 mt-1 sm:mt-4 clear-both row relative float-none flex mx-auto text-center rounded-full bg-aktion mb-4 `}
                    >
                      <div className="m-auto flex font-swDisp text-3xl font-bold italic">
                        {reviewData?.median.toFixed(1)}
                      </div>
                    </div>
                    {data && <ReviewContainer data={data} />}
                  </div>
                  <div className="mx-auto flex shrink flex-col">
                    <div
                      className={`cursor-pointer w-32 h-32 mt-1 sm:mt-4 clear-both row relative float-none flex mx-auto text-center rounded-full bg-aktion mb-4`}
                    >
                      <div className="m-auto flex font-swDisp text-3xl font-bold italic">
                        <div className="m-auto flex font-swDisp text-3xl font-bold italic">
                          {Math.round(
                            (reviewData?.ratings?.filter((x) => x.rating > 3)
                              ?.length /
                              reviewData?.count) *
                              100
                          )}
                          %
                        </div>
                      </div>
                    </div>
                    <p className="font-swDisp text-xl font-bold">
                      {t('RECOMMENDATION')}
                    </p>
                  </div>
                </>
              )}
              {data.Reviews.length > 4 && !isXs && (
                <div className="mb-auto flex w-full flex-col pt-4">
                  <button
                    onClick={() => setShowAll(!showAll)}
                    className="mx-4 border border-black bg-transparent py-2 "
                  >
                    Alle anzeigen
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="w-full px-2 sm:w-7/12">
            {_.orderBy(reviewData?.ratings, (x) => x.date, 'desc')
              .filter((r) => r.title && r.title !== '')
              ?.slice(0, showAll ? undefined : 4)
              .map((review) => (
                <ReviewDetail review={review} />
              ))}
          </div>
          {data.Reviews.length > 4 && isXs && (
            <div className="mb-auto flex w-full flex-col pt-4">
              <button
                onClick={() => setShowAll(!showAll)}
                className="mx-4 border border-black bg-transparent py-2 "
              >
                Alle anzeigen
              </button>
            </div>
          )}
        </div>
      </Element>
    </div>
  )
}

export const ProductContentDefault = ({
  data,
  selectedVariant,
}: ProductContentDefaultProps) => {
  const [sticky, setSticky] = useState<boolean>(false)

  const { isXs } = useBreakpoints()

  const [selectedIcon, setSelectedIcon] = useState<string>()
  const { data: genericElements } = useProductElements('de')
  const { t } = useTranslation('common')

  return (
    <div className={`sm:border-r border-grey-300 sm:pr-2`}>
      <div
        className={`hidden sm:flex ${
          sticky ? `sticky top-14 sm:top-44 ` : ''
        } white py-4 bg-white z-50 gap-x-2 sm:gap-x-8 border-b border-gray-300 `}
      >
        <LinkElement
          setSticky={setSticky}
          title={t('USAGE')}
          anchor={`usage`}
          offset={-240}
          show={data.Usage !== null}
        />
        <LinkElement
          setSticky={setSticky}
          title={t('RECIPES')}
          anchor={`recipes`}
          offset={-200}
          show={data.Recipes !== null && data.Recipes.length > 0}
        />
        <LinkElement
          setSticky={setSticky}
          title={t('TASTE')}
          anchor={`taste`}
          offset={-200}
          show={data.Taste !== null}
        />
        <LinkElement
          setSticky={setSticky}
          title={t('PRODUCTION')}
          anchor={`production`}
          offset={-220}
          show={data.Production !== null}
        />
        <LinkElement
          setSticky={setSticky}
          title={t('DETAILS')}
          anchor={`detail`}
          offset={-220}
          show
        />

        <LinkElement
          setSticky={setSticky}
          title={t('REVIEWS')}
          anchor={`reviews`}
          offset={-220}
          show={data.Reviews !== null}
        />
      </div>
      <div className="flex flex-col pt-8">
        {data.Usage && (
          <Element name="usage" className="flex flex-col sm:flex-row">
            {isXs && (
              <div className="flex flex-col">
                <p className={`mx-auto text-center pb-2`}>{selectedIcon}</p>
                <div className={`flex pb-4 mx-auto`}>
                  {data?.Declaration?.map((decl) => (
                    <button
                      onClick={() => setSelectedIcon(decl.Description)}
                      className={`flex-col`}
                    >
                      <img
                        className="w-16"
                        src={`${process.env.NEXT_PUBLIC_SVG_URL}/${decl.Icon.image.fullpath}`}
                      />
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className="w-full sm:w-3/5">
              <BaseText
                headline={data.ArticleName}
                subHeadline={t('USAGE')}
                text={data.Usage}
                secondaryText={data.NewShortDescription}
              />
              {data.GoodToKnow && (
                <BaseText
                  subHeadline={t('GOOD_TO_KNOW')}
                  text={data.GoodToKnow}
                />
              )}
            </div>
            {!isXs && (
              <div className={`sm:w-2/5 flex `}>
                <div className="mx-4 flex w-4/5 flex-col gap-4">
                  {data?.Declaration?.map((decl) => (
                    <div className={`group cursor-pointer bg-white `}>
                      {decl.Description && (
                        <span
                          className={`hidden absolute bg-white group-hover:block border border-9 p-2`}
                        >
                          {decl.Description}
                        </span>
                      )}
                      <img
                        className="w-16"
                        src={`${process.env.NEXT_PUBLIC_SVG_URL}/${decl.Icon.image.fullpath}`}
                      />
                    </div>
                  ))}
                </div>
                <div className={`flex flex-col`}>
                  <div>
                    <img
                      loading="lazy"
                      className="ml-auto w-full"
                      src={`https://pim.stayspiced.com${
                        selectedVariant?.DosenBild?.fullpath ??
                        (data?.Images && data?.Images[0]?.image.fullpath) ??
                        `/sonstiges/failover.png`
                      }`}
                    />
                  </div>
                  <div>
                    {selectedVariant?.SchuettungsBild && (
                      <img
                        loading="lazy"
                        src={`${process.env.NEXT_PUBLIC_BLOB_URL}${selectedVariant.SchuettungsBild.fullpath}`}
                        className={`mx-auto w-48`}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Element>
        )}

        {data.Recipes && (
          <Element name="recipes" className="element">
            <div className="my-8 border-gray-500 py-4 sm:border-y">
              <RecipeSlider
                title={`${t('MATCHING_RECIPES')} ${data.ArticleName}`}
                recipes={data.Recipes}
                itemcount={3}
              />
            </div>
          </Element>
        )}
        {data.Taste && (
          <Element name="taste" className="element">
            <BaseText subHeadline={t('TASTE')} text={data.Taste} />
          </Element>
        )}
        {data.Production && (
          <Element name="production" className="element">
            <BaseText subHeadline={t('PRODUCTION')} text={data.Production} />
          </Element>
        )}
        <div className="my-8 border-y border-gray-300 py-4">
          <Element name="detail" className="element">
            <ProductDetail product={data} selectedVariant={selectedVariant} />
          </Element>
        </div>

        {data.Reviews && <ReviewDetailContainer {...data} />}
        <ProductRecommendations data={data.OSProductNumber} />

        {data &&
          data.children &&
          data.children.filter(
            (child) =>
              child.classname !== 'Product' && child.classname !== 'Accordion'
          ).length > 0 &&
          data.children
            .filter(
              (child) =>
                child.classname !== 'Product' && child.classname !== 'Accordion'
            )
            .map((children) => {
              switch (children.classname) {
                default:
                  return (
                    <ComponentFactory data={[children]} key={children.id} />
                  )
              }
            })}
      </div>
    </div>
  )
}
