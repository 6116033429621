import { shopContext } from 'providers/shop-context'
import React, { useContext } from 'react'

import LoadingSVG from '../../public/images/loading.svg'

export const Loading = () => {
  const { isLoading } = useContext(shopContext)
  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-white bg-opacity-90 z-200">
          <LoadingSVG />
          <br/>
          <svg className="spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="23" fill="none" stroke-width="3"></circle>
          </svg>
        </div>
      )}
    </>
  )
}
