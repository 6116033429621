import { languageContext } from 'context/language-context'
import useBreakpoints from 'hooks/use-breakpoints'
import { useTranslation } from 'next-i18next'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useFooter } from '../../graph/get-footer'
import { shopContext } from '../../providers/shop-context'

export const NewsletterFooter = () => {
  const { currentLanguage } = useContext(languageContext)

  const { data } = useFooter(currentLanguage)

  const { addNewsletterRcpt } = useContext(shopContext)

  const { isXs } = useBreakpoints()

  const [newsletterSent, setNewsletterSent] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (newsletterData) => {
    await addNewsletterRcpt(newsletterData)

    setNewsletterSent(true)
  }

  const { t } = useTranslation()

  return (
    <>
      <div className={`border-t border-gray-300 mt-8 pt-4 `} />
      {!isXs && !newsletterSent && (
        <div
          className="relative z-0 flex h-33 w-full"
          style={{
            backgroundImage: `url(${process.env.NEXT_PUBLIC_BLOB_URL}/${data.NewsletterImage?.image?.fullpath}&bg=1)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="absolute top-1/4 w-full px-4 text-white sm:right-0 sm:w-2/5 sm:p-0">
              <h3 className="text-center font-swDisp text-2xl font-bold italic sm:text-left sm:text-4xl">
                {data.NewsletterHeadline}
              </h3>
              <p className="py-2 font-swDisp text-xl">
                {data.NewsletterSubline}
              </p>
              <div className="w-full">
                <input
                  name="email"
                  placeholder="E-Mail"
                  className="w-full p-2 font-swText text-black sm:w-4/5"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Bitte eine gültige E-Mail Adresse eingeben',
                    },
                  })}
                />
                <p className={`text-red-500 text-sm font-swDisp`}>
                  {errors?.email?.message}
                </p>
              </div>
              <button
                type="submit"
                className="mt-2 inline-block w-full border border-black bg-white py-2 font-swDisp font-bold text-black hover:bg-aktion sm:w-4/5"
              >
                {data.NewsletterButton}
              </button>
            </div>
          </form>
        </div>
      )}
      {isXs && !newsletterSent && (
        <div
          className="relative z-0 flex h-50 w-full bg-9"
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="absolute w-full p-4 text-white sm:right-0 sm:w-2/5 sm:p-0">
              <h3 className="text-center font-swDisp text-2xl font-bold italic sm:text-left sm:text-4xl">
                {data.NewsletterHeadline}
              </h3>
              <p className="py-2 font-swDisp text-xl">
                {data.NewsletterSubline}
              </p>
              <div className="w-full">
                <input
                  name="email"
                  placeholder="E-Mail"
                  className="w-full p-2 font-swText text-black sm:w-4/5"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Bitte eine gültige E-Mail Adresse eingeben',
                    },
                  })}
                />
                <p className={`text-red-500 text-sm font-swDisp`}>
                  {errors?.email?.message}
                </p>
              </div>
              <button
                type="submit"
                className="mt-2 inline-block w-full border border-black bg-white py-2 font-swDisp font-bold text-black hover:bg-aktion sm:w-4/5"
              >
                {data.NewsletterButton}
              </button>
            </div>
          </form>
        </div>
      )}
      {newsletterSent === true && (
        <div className="container mx-auto">
          <h2
            className={`text-2xl font-bold font-swDisp mx-auto text-center pb-8`}
            dangerouslySetInnerHTML={{ __html: t('NEWSLETTER_SUCCESS') }}
          ></h2>
        </div>
      )}
    </>
  )
}
