import React from 'react'

export interface BaseTextProps {
  headline?: string
  introLine?: string
  subHeadline?: string
  text?: string
  secondaryText?: string
  tagType?: string
}

export const DynamicHeadline = ({ tagType, text, className }: any) => {
  switch (tagType) {
    case 'h1':
      return (
        <h1 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      )

    case 'h2':
      return (
        <h2 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      )

    case 'h3':
      return (
        <h3 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      )

    case 'h4':
      return (
        <h4 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      )

    default:
      return (
        <h2 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      )
  }
}

export const BaseText = ({
  headline,
  subHeadline,
  text,
  secondaryText,
  introLine,
  tagType,
}: BaseTextProps) => {
  return (
    <>
      {introLine && (
        <h2 className="pt-4 font-swDisp  dark:text-white">{subHeadline}</h2>
      )}
      {headline && (
        <DynamicHeadline
          className="font-swDisp text-2xl font-bold italic dark:text-white sm:text-4xl"
          text={headline}
          tagType={tagType}
        ></DynamicHeadline>
      )}
      {secondaryText && (
        <div
          className="pt-4 font-swText  dark:text-white"
          dangerouslySetInnerHTML={{ __html: secondaryText }}
        />
      )}
      {subHeadline && (
        <h2 className="pt-4 font-swDisp text-base font-bold italic dark:text-white  sm:text-xl">
          {subHeadline}
        </h2>
      )}
      <div
        className="pt-4 font-swText  dark:text-white"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </>
  )
}

BaseText.defaultProps = {
  tagType: 'h2',
}
