import { languageContext } from 'context/language-context'
import { useFooter } from 'graph/get-footer'
import React, { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

import useBreakpoints from '../../hooks/use-breakpoints'
import { IComponentElement } from '../../models/landingpage'
import {
  IDeclarationIcon,
  IFooterLink,
  IFooterText,
} from '../../models/settings'
import { ConditionalLink } from '../building-blocks/banner'
import { NewsletterFooter } from '../components/newsletter-footer'
import { PaymentLogos } from '../components/payment-logos'
import { ShippingLogos } from '../components/shipping-logos'
import { SocialLogos } from '../components/social-logos'
import { SummaryBoxFooter } from '../trust/trusted-shops-wrapper'

export const FooterLink = (data: IFooterLink) => {
  function showCookieLayer() {
    // @ts-ignore
    UC_UI.showSecondLayer()
  }

  return (
    <p>
      {!data?.Name?.includes('Cookies') && (
        <ConditionalLink
          link={`${data.Landingpage?.Slug ?? data.Link}`}
          shouldShowLink={
            data.Landingpage !== undefined || data.Link !== undefined
          }
        >
          <a>{data.Name}</a>
        </ConditionalLink>
      )}
      {data?.Name?.includes('Cookies') && (
        <button onClick={() => showCookieLayer()}>{data.Name}</button>
      )}
    </p>
  )
}

export const FooterText = (data: IFooterText) => {
  return (
    <p
      className="font-swText"
      dangerouslySetInnerHTML={{ __html: data.Description }}
    />
  )
}

export const FooterTrusted = () => {
  return (
    <>
      <SummaryBoxFooter />
    </>
  )
}

export const FooterSocial = () => {
  return (
    <>
      <p className="pt-4 font-swDisp text-xl font-bold italic ">Follow us</p>

      <SocialLogos />
    </>
  )
}

export const FooterItem = (data: IComponentElement) => {
  return (
    <div className="flex flex-col py-2" key={uuidv4()}>
      <div className="w-full font-swText font-bold italic">{data.Name}</div>
      <div className="w-full">
        {data.children?.map((child) => (
          <>
            {child.classname === 'FooterLink' && (
              <FooterLink
                {...(child as IFooterLink)}
                key={child.id ?? uuidv4()}
              />
            )}
            {child.classname === 'FooterText' && (
              <FooterText
                {...(child as IFooterText)}
                key={child.id ?? uuidv4()}
              />
            )}
          </>
        ))}
      </div>
    </div>
  )
}

export const FooterColumn = (data: IComponentElement) => {
  const { isXs } = useBreakpoints()
  return (
    <div
      className={`grow ${
        data?.children?.find((c) => c.classname === 'FooterTrusted') ||
        data?.children?.find((c) => c.classname === 'FooterSocial')
          ? 'hidden sm:flex flex-col sm:col-span-1 text-center sm:text-left'
          : ''
      }`}
    >
      <p className="sm:pb-4 font-swDisp text-base sm:text-xl font-bold italic">
        {data.Name}
      </p>
      {data.children &&
        data.children.length > 0 &&
        !(
          isXs && data?.children?.find((c) => c.classname === 'FooterTrusted')
        ) &&
        data.children.map((child: IComponentElement, index: number) => (
          <>
            {child.classname === 'FooterTrusted' && !isXs && (
              <FooterTrusted key={child.id ?? uuidv4()} />
            )}
            {child.classname === 'FooterSocial' && !isXs && (
              <FooterSocial key={child.id ?? uuidv4()} />
            )}
            {child.classname !== 'FooterTrusted' &&
              child.classname !== 'FooterSocial' && (
                <FooterItem {...child} key={child.id ?? uuidv4()} />
              )}
          </>
        ))}
    </div>
  )
}

export const RenderMobileFooterColumn = (data: IComponentElement) => {
  return (
    <div
      className={`grow 
      }`}
    >
      <p className="sm:pb-4 font-swDisp text-xl font-bold italic">
        {data.Name}
      </p>
      {data.children &&
        data.children.length > 0 &&
        data.children.map((child: IComponentElement) => (
          <>
            {child.classname !== 'FooterTrusted' &&
              child.classname !== 'FooterSocial' && (
                <FooterItem {...child} key={child.id ?? uuidv4()} />
              )}
          </>
        ))}
    </div>
  )
}

export const DeclarationIcon = (data: IDeclarationIcon) => {
  return (
    <div className="mx-auto flex flex-1 flex-col content-center items-center text-center align-middle">
      <ConditionalLink
        link={`/${data?.Landingpage?.Slug}`}
        shouldShowLink={data?.Landingpage !== null}
        className={`text-center mx-auto`}
      >
        <img
          className="mx-auto h-8 w-8 sm:h-12 sm:w-12"
          src={`https://pim.stayspiced.com/${data.Icon.image.fullpath}`}
        />
        <p className="pt-2  text-center font-swDisp text-xs sm:text-sm">
          {data.Title}
        </p>
      </ConditionalLink>
    </div>
  )
}

export const Footer = () => {
  const { currentLanguage } = useContext(languageContext)

  const { data } = useFooter(currentLanguage)
  const { isXs } = useBreakpoints()

  return (
    <>
      {data && (
        <div className={`bg-sw-gray`}>
          <NewsletterFooter />
          <div className="container mx-auto  gap-x-8 grid grid-cols-2 py-16 px-4 sm:flex sm:px-0">
            {data.children.map((footerColumn, index) => (
              <>
                <FooterColumn
                  {...footerColumn}
                  key={footerColumn.id ?? uuidv4()}
                />
                {isXs && index == 1 && (
                  <div
                    className={`col-span-2 grid grid-cols-3 py-2 my-4 border-t-xl border-b-xl border-gray-200`}
                  >
                    <div className={`col-span-3`}>
                      <RenderMobileFooterColumn
                        {...data.children.find((c) =>
                          c.children.find(
                            (c) => c.classname === 'FooterTrusted'
                          )
                        )}
                        key={uuidv4}
                      />
                    </div>
                    <div className={``}>
                      <ShippingLogos />
                    </div>
                    <div className={`col-span-3 `}>
                      <PaymentLogos />
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
          {isXs && (
            <div
              className={`px-4 pb-16 text-center border-t-xl border-gray-200`}
            >
              <FooterSocial key={uuidv4()} />
              <FooterTrusted key={uuidv4()} />
            </div>
          )}
          <div className="container mx-auto flex flex-col sm:flex-row w-full border-t-xl py-4">
            <div className="w-full hidden sm:flex sm:w-1/3 mr-auto flex justify-center items-center">
              <ShippingLogos />
            </div>
            <div
              className={`w-full mx-auto sm:w-1/3 flex justify-center items-center`}
            >
              <p className={`text-center italic`}>© Spiceworld GmbH</p>
            </div>
            <div className="hidden sm:flex sm:w-1/3 ml-auto">
              <PaymentLogos />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
