import moment from 'moment'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import { useContext, useEffect, useState } from 'react'

import { formatter } from '../../helper/currency-formatter'
import { shopContext } from '../../providers/shop-context'

export const OpenInvoices = () => {
  const { customerOrders } = useContext(shopContext)
  const [openInvoices, setOpenInvoices] = useState<any[]>(undefined)

  const { t } = useTranslation()
  useEffect(() => {
    setOpenInvoices(
      customerOrders.filter(
        (i) =>
          (i.stateMachineState.technicalName === 'open' &&
            (i.transactions[i.transactions.length - 1].stateMachineState
              .technicalName === 'cancelled' ||
              i.transactions[i.transactions.length - 1].stateMachineState
                .technicalName === 'failed')) ||
          i.transactions[i.transactions.length - 1].stateMachineState
            .technicalName === 'reminded'
      )
    )
  }, [customerOrders])

  return (
    <>
      <div className={`grid grid-cols-1 sm:grid-cols-3 gap-2`}>
        {openInvoices &&
          openInvoices.length > 0 &&
          openInvoices.map((invoice) => {
            return (
              <div
                className={`w-full flex border bg-9 p-2 justify-center items-center my-2 text-white`}
              >
                <p>
                  {invoice.orderNumber} (
                  {moment(invoice.orderDate).format('D.M.Y')}) -{' '}
                  {formatter.format(invoice.positionPrice)}
                </p>
                <Link href={`/payment?id=${invoice.id}`}>
                  <a className={`ml-auto p-2 bg-aktion text-black`}>
                    <span>{t('PAY_NOW')}</span>
                  </a>
                </Link>
              </div>
            )
          })}
      </div>
      {openInvoices?.length > 0 && (
        <p className={`font-base font-bold italic font-swDisp text-center`}>
          {' '}
          {t('OPEN_INVOICE')}
        </p>
      )}
    </>
  )
}
