import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const SocialLogos = () => {
  return (
    <div className="mx-auto text-center sm:mr-auto block sm:flex flex-wrap">
      <a
        href={`https://facebook.com/stayspicedcom`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          className="p-2 text-xxl hover:text-blue-600"
          icon={faFacebook}
        />
      </a>
      <a
        href={`https://pinterest.com/stayspicedcom`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          className="p-2 text-xxl hover:text-red-600"
          size="2x"
          fixedWidth={true}
          icon={faPinterest}
        />
      </a>
      <a
        href={`https://instagram.com/stayspicedcom`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          className="p-2 text-xxl hover:text-orange-600"
          icon={faInstagram}
        />
      </a>
      <a
        href={`https://tiktok.com/@stayspiced`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          className="p-2 text-xxl hover:text-orange-600"
          icon={faTiktok}
        />
      </a>
      <a
        href={`https://linkedin.com/company/stayspiced/`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          className="p-2 text-xxl hover:text-orange-600"
          icon={faLinkedin}
        />
      </a>
    </div>
  )
}
