import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import { useRouter } from 'next-translate-routes/router'
import React, { useState } from 'react'

import useBreakpoints from '../../hooks/use-breakpoints'
import { IProduct } from '../../models/product'
import { BaseText } from '../text/base-text'

export interface ProductDetailProps {
  product: IProduct
  selectedVariant?: IProduct
  isSet?: boolean
}

export const ProductDetailLine = ({
  title,
  value,
  capitalize,
}: ProductDetailLineProps) => (
  <div className="py-4 sm:flex">
    <div className="w-full font-swText font-bold sm:w-2/6">{title}</div>
    <div className={`w-full sm:w-4/6 ${capitalize ? 'capitalize' : ''}  `}>
      {value}
    </div>
  </div>
)

export const SetProductDetail = ({
  title,
  capitalize,
  products,
}: ProductSetDetailLineProps) => (
  <div className="py-4 sm:flex">
    <div className="w-full font-swText font-bold sm:w-2/6">{title}</div>
    <div className={`w-full sm:w-4/6 ${capitalize ? 'capitalize' : ''}  `}>
      {products &&
        products?.map((product: IProduct) => (
          <Link key={product.id} href={`/produkt/${product.Slug}`}>
            <a>
              <p>{product.ArticleName}</p>
            </a>
          </Link>
        ))}
    </div>
  </div>
)

ProductDetailLine.defaultProps = {
  capitalize: true,
}

export const NutritionLine = ({
  title,
  value,
  unit,
}: ProductDetailLineProps) => (
  <>
    {
      <div className="flex w-full py-1">
        <div className="w-4/6 flex-1  font-swText">{title}</div>
        <div className="w-2/6 ">
          <p className="float-right ml-auto">
            {value ?? 0} <span className="text-sm">{unit}</span>{' '}
          </p>
        </div>
      </div>
    }
  </>
)

export const getSachmerkmal = (product: IProduct, type: string, id: string) =>
  product.Sachmerkmale?.find(
    (sm) => sm.name.toLowerCase() === type.toLowerCase()
  )?.features.find((sm) => sm.description === id)?.text

export const getCountry = (product: IProduct, type: string, id: string) =>
  product.Sachmerkmale?.find(
    (sm) => sm.name.toLowerCase() === type.toLowerCase()
  )?.features.find((sm) => sm.description === id)?.country

export const getRealCountry = (iso: string): string | null => {
  const { locale } = useRouter()

  const regionNames = new Intl.DisplayNames([locale], { type: 'region' })

  try {
    return regionNames.of(iso)
  } catch (err) {
    return ''
  }
}

export const ProductDetail = ({
  product,
  selectedVariant,
  isSet,
}: ProductDetailProps) => {
  const { t } = useTranslation('common')
  const { isXs } = useBreakpoints()
  const [showDetails, setShowDetails] = useState(!isXs)
  return (
    <>
      <button
        className={`flex w-full items-center`}
        onClick={() => setShowDetails(!showDetails)}
      >
        <BaseText headline="Details" />
        {isXs && (
          <FontAwesomeIcon
            className="w-4 text-lg text-base ml-auto"
            icon={showDetails ? faMinus : faPlus}
          />
        )}
      </button>
      {showDetails && (
        <div className="flex flex-col gap-8 sm:flex-row">
          <div className="flex w-full flex-col gap-x-8 divide-y divide-solid divide-gray-300 border-gray-300 sm:w-7/12">
            {!isSet && product.Ingredients && (
              <ProductDetailLine
                title={`${t('INGREDIENTS')}: `}
                value={product.Ingredients?.replaceAll('Zutaten: ', '')
                  ?.replaceAll('Ingredienti: ', '')
                  ?.replaceAll('Ingrediente:', '')}
              />
            )}

            {isSet && (
              <SetProductDetail
                title={`${t('INGREDIENTS')}:`}
                products={product.ContainedProducts}
              />
            )}

            <ProductDetailLine
              title={`${t('ORDERNUMBER')}:`}
              value={product.OSProductNumber}
            />
            {product.GRIND && (
              <ProductDetailLine
                title={`${t('GRIND')}:`}
                value={product.GRIND[0]?.Name}
              />
            )}
            {product.USAGEREL && (
              <ProductDetailLine
                title={`${t('RECOMMEND')}:`}
                value={product.USAGEREL?.map((rel) => rel.Name).join(', ')}
              />
            )}
            <ProductDetailLine
              title={`${t('WEIGHT')}:`}
              capitalize={false}
              value={`${
                (selectedVariant?.NettoWeight ?? product?.NettoWeight) * 1000
              } g`}
            />
            {product.SCHARFGRAD && (
              <ProductDetailLine
                title={`${t('SCHARFGRAD')}:`}
                value={product?.SCHARFGRAD[0]?.Name}
              />
            )}
            {product.WORLD && (
              <ProductDetailLine
                title={`${t('WORLD')}:`}
                value={product?.WORLD[0]?.Name}
              />
            )}
            {getCountry(product, 'stamm', '0103') && (
              <ProductDetailLine
                title={`${t('ORIGINCOUNTRY')}:`}
                value={getRealCountry(
                  getCountry(product, 'stamm', '0103') ?? ''
                )}
              />
            )}
            {getSachmerkmal(product, 'handling', '0301') && (
              <ProductDetailLine
                title={`${t('STORAGE')}:`}
                value={getSachmerkmal(product, 'handling', '0301')}
                capitalize={false}
              />
            )}
            {selectedVariant?.EANCode && (
              <ProductDetailLine
                title={'EAN / GTIN:'}
                value={selectedVariant?.EANCode}
                capitalize={false}
              />
            )}
          </div>
          {(product.CaloriesKJ ||
            product.CaloriesKCAL ||
            product.SaturatedFat ||
            product.TotalCarbs ||
            product.TotalSugars ||
            product.Protein) && (
            <div className="w-full flex-row sm:w-5/12 ">
              <div className="flex flex-col gap-x-8 border-y border-black">
                <div className="flex w-full py-1">
                  <div className="w-4/6 flex-1 grow font-swText font-bold">
                    {t('NUTRITION')}{' '}
                  </div>
                  <div className="w-2/6 ">
                    <p className="float-right ml-auto font-bold">
                      {product.Portion ?? 100}{' '}
                      {product.NutritionPortionUnit ?? 'g'}
                    </p>
                  </div>
                </div>
                <NutritionLine
                  title={t('ENERGY')}
                  value={product.CaloriesKJ}
                  unit={`kJ`}
                />
                <NutritionLine
                  title={t('CALORIES')}
                  value={product.CaloriesKCAL}
                  unit={`kCal`}
                />
                <NutritionLine
                  title={t('FAT')}
                  value={product.TotalFat}
                  unit={`g`}
                />
                <NutritionLine
                  title={t('SATURATED_FAT')}
                  value={product.SaturatedFat}
                  unit={`g`}
                />
                <NutritionLine
                  title={t('SALT')}
                  value={product.Sodium}
                  unit={`g`}
                />
                <NutritionLine
                  title={t('CARBONHYDRATE')}
                  value={product.TotalCarbs}
                  unit={`g`}
                />
                <NutritionLine
                  title={t('SUGAR')}
                  value={product.TotalSugars}
                  unit={`g`}
                />
                <NutritionLine
                  title={t('PROTEIN')}
                  value={product.Protein}
                  unit={`g`}
                />
              </div>
              <p>{product.AdditionalHintPortions}</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

interface ProductDetailLineProps {
  title: string
  value: string | number
  unit?: string
  capitalize?: boolean
}

interface ProductSetDetailLineProps {
  title: string
  products: IProduct[]
  capitalize?: boolean
}
