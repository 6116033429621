import React, { FC } from 'react'

interface IProps {
  className?: string
  width?: number
}

const IconUser: FC<IProps> = ({ className, width = 40 }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 23.077 26.026"
      className={className}
    >
      <g id="Group_73" data-name="Group 73" transform="translate(1.5 1.5)">
        <path
          id="Path_129"
          data-name="Path 129"
          d="M689.827,405.185a6.592,6.592,0,1,1,6.592-6.593A6.6,6.6,0,0,1,689.827,405.185Zm0-10.184a3.592,3.592,0,1,0,3.592,3.592A3.6,3.6,0,0,0,689.827,395Z"
          transform="translate(-679.788 -393.501)"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M701.577,413.976H681.5A1.5,1.5,0,0,1,680,412.4c.271-5.234,5.339-9.335,11.537-9.335s11.265,4.1,11.536,9.335a1.5,1.5,0,0,1-1.5,1.578Zm-18.263-3h16.449c-1.017-2.853-4.314-4.912-8.224-4.912S684.331,408.124,683.314,410.976Z"
          transform="translate(-681.5 -389.451)"
        />
      </g>
    </svg>
  )
}

export default IconUser
