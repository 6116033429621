import { useRouter } from 'next-translate-routes/router'
import React, { useEffect, useState } from 'react'

export interface LanguageContext {
  currentLanguage: string
  switchLanguage: any
}

export const useLanguageContext = (): LanguageContext => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    process.env.NEXT_PUBLIC_LANGUAGE
  )

  const router = useRouter()

  const { locale } = useRouter()

  useEffect(() => {
    if (locale && locale !== currentLanguage) {
      setCurrentLanguage(locale)
    }
  }, [locale])

  function switchLanguage(language: string) {
    router.push('/', undefined, { locale: language })

    setCurrentLanguage(language)
  }

  return {
    currentLanguage,
    switchLanguage,
  }
}

export const languageContext = React.createContext<LanguageContext>(undefined)
