import { PictureTag } from 'components/helper/picture-tag'
import { IRecipe } from 'models/recipe'
import Link from 'next-translate-routes/link'
import React, { useEffect, useRef } from 'react'

export interface RecipeBoxProps {
  data: IRecipe
  bgColor?: string
  center?: boolean
  imageSize?: string
  sizeVW?: string
  isLast?: boolean
  newLimit?: () => void
}

export const RecipeBox = ({
  data,
  bgColor,
  imageSize,
  center,
  sizeVW,
  isLast,
  newLimit,
}: RecipeBoxProps): React.ReactElement => {
  const product = data && data.Products ? data?.Products[0] : undefined
  const cardRef = useRef()

  useEffect(() => {
    if (!cardRef?.current) return

    const observer = new IntersectionObserver(([entry]) => {
      if (isLast && entry.isIntersecting) {
        newLimit()
        console.log('last')
        // observer.unobserve(entry.target)
      }
    })

    observer.observe(cardRef.current)
  }, [isLast])
  return (
    <div className="relative flex flex-col" ref={cardRef}>
      {data && (
        <>
          <div className={`pb-6 flex-grow`}>
            <Link href={`/rezept/${data.slug}`}>
              <a className="relative h-full w-full cursor-pointer">
                {data.Image && (
                  <PictureTag
                    imageUrl={data.Image?.image.fullpath}
                    className={`${imageSize} m-auto`}
                    sizesVW={sizeVW}
                    product={product}
                    width={250}
                  />
                )}
              </a>
            </Link>
          </div>

          <Link href={`/rezept/${data.slug}`}>
            <a
              className={`flex-grow pt-2 text-lg italic font-bold font-swDisp  dark:text-white cursor-pointer`}
              dangerouslySetInnerHTML={{
                __html: data.RecipeName?.replace('Rezept:', ''),
              }}
            />
          </Link>
          <p
            className={`italic ${
              center ? 'text-center' : ''
            } text-sm font-swText  dark:text-white recipe-box-description`}
            dangerouslySetInnerHTML={{
              __html: `${data?.Introduction}`,
            }}
          />
          <div className="clear-both" />
        </>
      )}
    </div>
  )
}

RecipeBox.defaultProps = {
  bgColor: 'transparent',
  imageSize: 'w-full',
  showPrize: true,
  center: false,
  sizeVW: '25',
}
