import {
  IAccordion,
  IBanner,
  IBannerWrapper,
  ICategoryTeaser,
  IComponentElement,
  IEndlessSlider,
  IFormular,
  IFullScreenHero,
  IHeadline,
  IHeroElement,
  IHeroEntry,
  IHeroRecipe,
  IKnownFromWrapper,
  ILPArtikelGrip,
  IMediaVideo,
  IPdfDocument,
  IProductCompare,
  IProductSlider,
  IProductTeaser,
  ISliderWrapper,
  ISpacer,
  ITestimonialWrapper,
  ITextBlock,
  ITextImage,
  ITrustWrapper,
  ITwoTextColumn,
} from 'models/landingpage'
import { IRecipeBox } from 'models/recipe'
import dynamic from 'next/dynamic'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import useBreakpoints from '../hooks/use-breakpoints'
import { PdfViewer } from './components/pdf-viewer'
import { FormularWrapper } from './formular/formular-wrapper'
import ProductCompare from './product-compare/product-compare'
import Spacer from './spacer/spacer'

const Banner = dynamic(() => import('./building-blocks/banner'))
const BannerWrapper = dynamic(() => import('./building-blocks/banner-wrapper'))
const FullScreenHero = dynamic(
  () => import('./building-blocks/full-screen-hero')
)
const HeroNew = dynamic(() => import('./building-blocks/hero-new'))
const Headline = dynamic(() => import('./building-blocks/headline'))
const HeroEntry = dynamic(() => import('./building-blocks/hero-entry'))
const HeroProduct = dynamic(() => import('./building-blocks/hero-product'))
const ProductTeaser = dynamic(() => import('./building-blocks/product-teaser'))
const ProductTeaserAlternative = dynamic(
  () => import('./building-blocks/product-teaser-alternative')
)
const RecipeWrapper = dynamic(() => import('./building-blocks/recipe-wrapper'))
const TextAccordion = dynamic(() => import('./building-blocks/text-accordion'))
const TextBlock = dynamic(() => import('./building-blocks/text-block'))
const TextImage = dynamic(() => import('./building-blocks/text-image'))
const LpArticleGrid = dynamic(() => import('./components/lp-article-grid'))
const MediaVideo = dynamic(() => import('./components/media-video'))
const SliderWrapper = dynamic(() => import('./components/slider-wrapper'))
const TextTwoColumn = dynamic(() => import('./components/text-two-column'))
const EndlessSlider = dynamic(() => import('./slider/endless-slider'))
const ProductSlider = dynamic(() => import('./slider/product-slider'))
const HeroRecipe = dynamic(() => import('./building-blocks/hero-recipe'))
const DealerMap = dynamic(() => import('./dealer-map/dealer-map'), {
  ssr: false,
})

const TestimonialWrapper = dynamic(
  () => import('./testimonial/testimonial-wrapper')
)

const CategoryTeaser = dynamic(() => import('./components/category-teaser'))

const TrustWrapper = dynamic(() => import('./trust/trust-wrapper'))
const KnownFromWrapper = dynamic(() => import('./known/known-from-wrapper'))
const TrustedShopsWrapper = dynamic(
  () => import('./trust/trusted-shops-wrapper')
)

export interface ComponentFactoryProps {
  data: IComponentElement[]
}

export const ComponentItem = (children) => {
  const breakpoints = useBreakpoints()

  switch (children.classname) {
    case 'HeroEntry':
      return <HeroEntry data={children as IHeroEntry} key={children.id} />

    case 'TrustWrapper':
      return <TrustWrapper data={children as ITrustWrapper} key={children.id} />

    case 'TrustedShopsSlider':
      return <TrustedShopsWrapper />

    case 'TestimonialWrapper':
      return (
        <TestimonialWrapper
          data={children as ITestimonialWrapper}
          key={children.id}
        />
      )

    case 'CategoryTeaser':
      return (
        <CategoryTeaser data={children as ICategoryTeaser} key={children.id} />
      )

    case 'HeroNew':
      return <HeroNew key={children.id} data={children as IFullScreenHero} />

    case 'HeroProduct':
      return <HeroProduct {...(children as IHeroElement)} key={children.id} />

    case 'HeroRecipe':
      return <HeroRecipe {...(children as IHeroRecipe)} key={children.id} />

    case 'ProductTeaser':
      return (
        <ProductTeaser data={children as IProductTeaser} key={children.id} />
      )

    case 'Accordion':
      return <TextAccordion {...(children as IAccordion)} key={children.id} />

    case 'ProductTeaserSlider':
      return (
        <ProductTeaserAlternative
          data={children as IProductTeaser}
          key={children.id}
        />
      )

    case 'KnownFromWrapper':
      return (
        <KnownFromWrapper
          key={children.id}
          data={children as IKnownFromWrapper}
        />
      )

    case 'TextTwoColumn':
      return (
        <TextTwoColumn {...(children as ITwoTextColumn)} key={children.id} />
      )

    case 'Formular':
      return <FormularWrapper {...(children as IFormular)} key={children.id} />

    case 'ProductCompare':
      return (
        <ProductCompare data={children as IProductCompare} key={children.id} />
      )

    case 'TextBlock':
      return <TextBlock data={children as ITextBlock} key={children.id} />

    case 'TextImage':
      return <TextImage {...(children as ITextImage)} key={children.id} />

    case 'MediaVideo':
      return <MediaVideo {...(children as IMediaVideo)} key={children.id} />

    case 'FullScreenHero':
      return (
        <FullScreenHero data={children as IFullScreenHero} key={children.id} />
      )

    case 'Headline':
      return <Headline data={children as IHeadline} key={children.id} />

    case 'EndlessSlider':
      return (
        <EndlessSlider {...(children as IEndlessSlider)} key={children.id} />
      )

    case 'PDFViewer':
      return <PdfViewer {...(children as IPdfDocument)} key={children.id} />

    case 'LPArtikelGrip':
      return (
        <LpArticleGrid {...(children as ILPArtikelGrip)} key={children.id} />
      )

    case 'Spacer':
      return <Spacer {...(children as ISpacer)} key={children.id} />

    case 'TrendingCategories':
      return (
        // <TrendingCategories
        //   {...(children as ITrendingCategories)}
        //   key={children.id}
        // />
        <></>
      )

    case 'ProductSlider':
      return (
        <ProductSlider
          products={(children as IProductSlider).Products}
          title={(children as IProductSlider).Headline}
          itemcount={breakpoints.is2xl ? 4 : 2}
          centeredHeadline
          largeText
          Farbe={(children as IProductSlider).Farbe}
          key={children.id}
        />
      )

    case 'RecipeBox':
      return <RecipeWrapper data={children as IRecipeBox} key={children.id} />

    case 'Banner':
      return <Banner data={children as IBanner} key={children.id} />

    case 'SliderWrapper':
      return (
        <SliderWrapper {...(children as ISliderWrapper)} key={children.id} />
      )

    case 'Map':
      return <DealerMap />

    case 'BannerWrapper':
      return (
        <BannerWrapper
          key={children.id}
          data={(children as IBannerWrapper).children as IBanner[]}
        />
      )

    default:
      return <></>
  }
}

export const ComponentFactory = ({
  data,
}: ComponentFactoryProps): JSX.Element => {
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((children) => (
          <>
            <a
              id={children?.id?.toString(10)}
              className={`-mt-[80px] sm:-mt-[250px] absolute block w-full`}
            />
            <div className={``} key={uuidv4()}>
              <ComponentItem {...children} />
            </div>
          </>
        ))}
    </>
  )
}
