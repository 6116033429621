import Link from "next-translate-routes/link"
import { useTranslation } from "next-i18next"
import React, { useContext, useEffect } from "react"

import { shopContext } from "../../providers/shop-context"
import { useRouter } from "next-translate-routes/router"

interface HeaderMenuRootProps {
  data: any
  stepUpLevel: any
}

export const HeaderMenuRootMobile = ({
  data,
  stepUpLevel,
}: HeaderMenuRootProps) => {
  const { setShowMenu } = useContext(shopContext)
  const { t } = useTranslation()
  const router = useRouter()
  useEffect(() => {
    if (data) {
      if (
        data.ReferenzSeite &&
        (data.SubMenuItem?.length === 0 || !data.SubMenuItem)
      ) {
        setShowMenu(false)
        router.push(
          `/${
            data.ReferenzSeite?.classname?.includes("Category")
              ? "kategorie/"
              : ""
          }${data.ReferenzSeite?.Slug}`
        )
        stepUpLevel()
      }
    }
  }, [data])
  return (
    <>
      {data && data.ReferenzSeite && (
        <li
          className="border-b border-gray-500 py-4 pl-8"
          onClick={() => setShowMenu(false)}
        >
          <Link
            href={`/${
              data.ReferenzSeite?.classname?.includes("Category")
                ? "kategorie/"
                : ""
            }${data.ReferenzSeite?.Slug}`}
          >
            <a>
              <p className={`hover:text-9`}>{t("SHOW_ALL")}</p>
            </a>
          </Link>
        </li>
      )}
      {data &&
        data?.SubMenuItem?.map((header) => (
          <li
            className="border-b border-gray-500 py-4 pl-8"
            onClick={() => setShowMenu(false)}
          >
            <Link
              href={`/${
                header?.classname?.includes("Category")
                  ? "kategorie/"
                  : header?.classname?.includes("Recipe")
                  ? "rezepte/"
                  : ""
              }${header.Slug}`}
            >
              <a>
                <p className={`hover:text-9`}>{header.Name}</p>
              </a>
            </Link>
          </li>
        ))}
    </>
  )
}
