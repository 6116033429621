import _ from 'lodash'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { Element } from 'react-scroll'

import { useProductElements } from '../../graph/get-product-elements'
import { IProduct } from '../../models/product'
import { LinkElement } from '../../pages/produkt/[slug]'
import { ComponentFactory } from '../component-factory'
import { ProductDetail } from '../components/product-details'
import { ProductOverview } from '../components/product-overview'
import { RecipeSlider } from '../slider/recipe-slider'
import { BaseText } from '../text/base-text'
import { ReviewDetailContainer } from './product-content-default'

interface ProductContentDefaultProps {
  data: IProduct
  selectedVariant?: IProduct
}

export const ProductContentAlternative = ({
  data,
  selectedVariant,
}: ProductContentDefaultProps) => {
  const [sticky, setSticky] = useState<boolean>(false)

  const [recipes, setRecipes] = useState(undefined)

  const { data: genericElements } = useProductElements('de')

  useEffect(() => {
    if (data && data.Recipes && data.Recipes.length > 0) {
      setRecipes(data.Recipes)
    } else if (data) {
      const allRecipes = _.flatten(
        data.ContainedProducts?.map((prod) => prod.Recipes)
      ).filter((r) => r)
      setRecipes(allRecipes)
    }
  }, [data])

  const [showContent, setShowContent] = useState<boolean>(false)

  const { t } = useTranslation('common')

  return (
    <>
      <div
        className={`hidden sm:flex ${
          sticky ? `sticky top-16 sm:top-38 ` : ''
        } white py-4 bg-white z-50 flex gap-x-8 border-b border-gray-300 border-r border-grey-500`}
      >
        <LinkElement
          setSticky={setSticky}
          title={t('RECIPES')}
          anchor={`recipes`}
          offset={-200}
          show={recipes && recipes?.length > 0}
        />
        <LinkElement
          setSticky={setSticky}
          title={t('CONTENT')}
          anchor={`inhalt`}
          offset={-200}
          show={data.ContainedProducts !== null}
        />
        <LinkElement
          setSticky={setSticky}
          title={t('USAGE')}
          anchor={`usage`}
          offset={-240}
          show={data.Usage !== null}
        />
        <LinkElement
          setSticky={setSticky}
          title={t('TASTE')}
          anchor={`taste`}
          offset={-200}
          show={data.Taste !== null}
        />
        <LinkElement
          setSticky={setSticky}
          title={t('PRODUCTION')}
          anchor={`production`}
          offset={-220}
          show={data.Production !== null}
        />
        <LinkElement
          setSticky={setSticky}
          title={`Details`}
          anchor={`detail`}
          offset={-220}
          show
        />
        <LinkElement
          setSticky={setSticky}
          title={t('REVIEWS')}
          anchor={`reviews`}
          offset={-220}
          show={data.Reviews !== null}
        />
      </div>
      <div className="flex flex-col pt-8">
        {recipes && recipes.length > 0 && (
          <Element name="recipes" className="element">
            <div className="my-8 border-y border-gray-500 py-4">
              <RecipeSlider
                title={`${t('MATCHING_RECIPES')} ${data.ArticleName}`}
                recipes={recipes}
                itemcount={3}
              />
            </div>
          </Element>
        )}

        {data.ContainedProducts && (
          <button
            type="button"
            className={`$ w-full py-2 mb-4 font-bold inline-block border border-black bg-black text-white hover:bg-9 hover:text-white font-swDisp`}
            onClick={() => setShowContent(!showContent)}
          >
            {t('CONTENT')} {showContent ? 'ausblenden' : 'anzeigen'}
          </button>
        )}
        {data.ContainedProducts && showContent && (
          <Element name="inhalt" className="element">
            <ProductOverview data={data.ContainedProducts} />
          </Element>
        )}
        {data.Usage && (
          <Element name="usage" className="flex">
            <div className="w-full sm:w-3/5">
              <BaseText
                headline={data.ArticleName}
                subHeadline={t('USAGE')}
                text={data.Usage}
                secondaryText={data.NewShortDescription}
              />
              {data.GoodToKnow && (
                <BaseText
                  subHeadline={t('GOOD_TO_KNOW')}
                  text={data.GoodToKnow}
                />
              )}
            </div>
            <div className={`hidden sm:block w-2/5 flex `}>
              <div className="mx-4 flex w-4/5 flex-col gap-4">
                {data?.Declaration?.map((decl) => (
                  <img
                    key={decl.id}
                    alt={decl.Description}
                    className="w-16"
                    src={`${process.env.NEXT_PUBLIC_SVG_URL}/${decl.Icon.image.fullpath}`}
                  />
                ))}
              </div>
              <div>
                <img
                  className="ml-auto w-full"
                  src={`https://pim.stayspiced.com${data?.Images[0]?.image.fullpath}`}
                />
              </div>
            </div>
          </Element>
        )}
        {data.Taste && (
          <Element name="taste" className="element">
            <BaseText subHeadline={t('TASTE')} text={data.Taste} />
          </Element>
        )}
        {data.Production && (
          <Element name="production" className="element">
            <BaseText subHeadline={t('PRODUCTION')} text={data.Production} />
          </Element>
        )}
        <div className="my-8 border-y border-gray-300 py-4">
          <Element name="detail" className="element">
            <ProductDetail
              product={data}
              selectedVariant={selectedVariant}
              isSet={true}
            />
          </Element>
        </div>
        {data.Reviews && <ReviewDetailContainer {...data} />}
        {data &&
          data.children &&
          data.children.filter((child) => child.classname !== 'Product')
            .length > 0 &&
          data.children
            .filter((child) => child.classname !== 'Product')
            .map((children) => {
              switch (children.classname) {
                default:
                  return (
                    <ComponentFactory data={[children]} key={children.id} />
                  )
              }
            })}
        {/* {genericElements && */}
        {/*  genericElements.children?.map((child) => { */}
        {/*    return <ComponentFactory data={[child]} key={child.id} /> */}
        {/*  })} */}
      </div>
    </>
  )
}
