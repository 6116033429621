import { Image } from 'models/product'
import React, { useRef } from 'react'
import ReactPlayer from 'react-player'
import Slider from 'react-slick'

import useBreakpoints from '../../hooks/use-breakpoints'
import { HeroVideo } from '../../models/landingpage'
import { PictureTag } from '../helper/picture-tag'
import { SampleNextArrow, SamplePrevArrow } from './endless-slider'

export interface ImageSliderProps {
  images: Image[]
  showSubSelection?: boolean
  selectedVariant?: any
  baseVariant?: any
  video?: HeroVideo
  imageSize?: string
}

export const ImageSlider = ({
  images,
  showSubSelection,
  selectedVariant,
  baseVariant,
  imageSize,
  video,
}: ImageSliderProps) => {
  const masterSlider = useRef()

  const { isXs } = useBreakpoints()

  const slaveSlider = useRef()

  const settingsMaster = {
    dots: isXs,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    centerMode: true,
    arrows: isXs,
    fade: false,
    nextArrow: isXs ? <></> : <SampleNextArrow />,
    prevArrow: isXs ? <></> : <SamplePrevArrow />,
    swipeToSlide: true,
    asNavFor: slaveSlider?.current,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const settingsSlave = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    asNavFor: masterSlider.current,
    swipeToSlide: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="relative w-full m-auto">
      <div className={`w-full m-auto`}>
        <Slider {...settingsMaster} ref={masterSlider}>
          {selectedVariant &&
            selectedVariant?.DosenBild?.fullpath &&
            (baseVariant?.SchuettungsBild?.fullpath ||
              baseVariant?.Declaration) && (
              <div className={`h-full relative flex flex-col align-middle p-2`}>
                <div
                  className={`absolute left-0 sm:left-32 top-10 sm:w-64 flex flex-col`}
                >
                  {baseVariant?.Declaration?.map((decl) => (
                    <img
                      loading="lazy"
                      className="w-16 sm:w-32 p-2 sm:p-4"
                      src={`${process.env.NEXT_PUBLIC_SVG_URL}/${decl.Icon.image.fullpath}`}
                    />
                  ))}
                </div>
                <PictureTag
                  rawUrl={`${process.env.NEXT_PUBLIC_BLOB_URL}${selectedVariant.DosenBild.fullpath}`}
                  sizesVW={'75'}
                  className={`mx-auto p-4 `}
                />
                {baseVariant?.SchuettungsBild && (
                  <img
                    src={`${process.env.NEXT_PUBLIC_BLOB_URL}${baseVariant.SchuettungsBild.fullpath}`}
                    className={`absolute bottom-10 sm:bottom-10 right-8  sm:right-48 w-24 sm:w-48 z-[85]`}
                  />
                )}
              </div>
            )}
          {images &&
            images.slice(0, 1).map((child, index) => (
              <div className={`h-full flex flex-col align-middle p-2`}>
                <PictureTag
                  rawUrl={`${process.env.NEXT_PUBLIC_BLOB_URL}${child.fullpath}`}
                  sizesVW={'75'}
                  className={`mx-auto p-4 `}
                />
              </div>
            ))}
          {images &&
            images.slice(1).map((child, index) => (
              <div className={`h-full flex flex-col align-middle p-2`}>
                <PictureTag
                  rawUrl={`${process.env.NEXT_PUBLIC_BLOB_URL}${child.fullpath}`}
                  sizesVW={'75'}
                  className={`mx-auto p-4 `}
                />
              </div>
            ))}
          {video && video?.data?.id && (
            <div className={`w-full h-full`}>
              <ReactPlayer
                // url={`https://stayspicedraw.azureedge.net/Videos/${video.data.filename}`}
                url={`https://www.youtube.com/watch?v=${video.data.id}`}
                className={`fullscreen-video`}
                fullscreen={true}
                width="100%"
                height="100%"
              />
            </div>
          )}
        </Slider>
      </div>
      {showSubSelection && !isXs && (
        <div className="relative w-full mx-auto ">
          <Slider {...settingsSlave} ref={slaveSlider}>
            {selectedVariant &&
              selectedVariant?.DosenBild?.fullpath &&
              (baseVariant?.SchuettungsBild?.fullpath ||
                baseVariant?.Declaration) && (
                <div
                  className={`h-full flex flex-col align-middle p-2 relative`}
                >
                  <div
                    className={`absolute left-0 sm:left-12 sm:top-8 sm:w-48 flex flex-col`}
                  >
                    {baseVariant?.Declaration?.map((decl) => (
                      <img
                        loading="lazy"
                        className="w-12 p-2"
                        src={`${process.env.NEXT_PUBLIC_SVG_URL}/${decl.Icon.image.fullpath}`}
                      />
                    ))}
                  </div>
                  <PictureTag
                    rawUrl={`${process.env.NEXT_PUBLIC_BLOB_URL}${selectedVariant.DosenBild.fullpath}`}
                    sizesVW={'75'}
                    className={`sm:w-9/12 mx-auto p-4 `}
                  />
                  {baseVariant?.SchuettungsBild && (
                    <img
                      loading="lazy"
                      src={`${process.env.NEXT_PUBLIC_BLOB_URL}${baseVariant.SchuettungsBild.fullpath}`}
                      className={`absolute bottom-8 right-16 w-16`}
                    />
                  )}
                </div>
              )}
            {images &&
              images.slice(0, 1).map((child, index) => (
                <div
                  className={`h-full flex flex-col align-middle items-center p-2`}
                >
                  <PictureTag
                    imageUrl={`${child.fullpath}`}
                    sizesVW={'75'}
                    className={`w-full sm:w-9/12 mx-auto p-4`}
                    wrapperClassName={`h-full`}
                  />
                </div>
              ))}
            {images &&
              images.slice(1).map((child, index) => (
                <div
                  className={`h-full flex flex-col align-middle items-center p-2`}
                >
                  <PictureTag
                    imageUrl={`${child.fullpath}`}
                    sizesVW={'75'}
                    className={`w-full sm:w-9/12 mx-auto p-4`}
                    wrapperClassName={`h-full`}
                  />
                </div>
              ))}
            {video && (
              <div className={`h-full flex flex-col align-middle p-2`}>
                <PictureTag
                  rawUrl={`https://img.youtube.com/vi/${video.data.id}/hqdefault.jpg`}
                  sizesVW={'75'}
                  className={`w-full sm:w-9/12 mx-auto p-4 `}
                />
              </div>
            )}
          </Slider>
        </div>
      )}
    </div>
  )
}

ImageSlider.defaultProps = {
  showSubSelection: true,
  imageSize: '1/3',
}
