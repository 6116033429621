import { gql, request } from 'graphql-request'
import { IProduct } from 'models/product'

const endpoint = process.env.NEXT_PUBLIC_GRAPH_URL

export async function getProductMinimal(
  product,
  currentLanguage: string,
  type = 'object'
) {
  const data = await request(
    endpoint,
    gql`
      query ($filter: String, $language: String) {
        getProductListing(
          defaultLanguage: $language
          first: 1
          sortBy: "OSProductNumber"
          filter: $filter
        ) {
          edges {
            node {
              id
              classname
              OSProductNumber
              IsActive
              ArticleName
              Images {
                image {
                  id
                  fullpath
                  metadata {
                    data
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      filter: `{"o_type": "${type}", "OSProductNumber": "${product}"}`,
      language: currentLanguage,
    }
  )

  return data.getProductListing.edges.map((edge) => edge.node)[0] as IProduct
}
