import Icon from '@material-tailwind/react/Icon'
import { LineItem } from '@shopware-pwa/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { languageContext } from 'context/language-context'
import { IProduct } from 'models/product'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import React, { useContext, useEffect } from 'react'

import { loadProduct } from '../../graph/get-product'
import { formatter } from '../../helper/currency-formatter'
import useBreakpoints from '../../hooks/use-breakpoints'
import { shopContext } from '../../providers/shop-context'
import { PictureTag } from '../helper/picture-tag'

export interface LineItemProps {
  lineItem: LineItem
}

export const ShopwareLineItem = ({ lineItem }: LineItemProps) => {
  const { currentLanguage } = useContext(languageContext)

  const [data, setData] = React.useState<IProduct>(undefined)

  useEffect(() => {
    // @ts-ignore
    loadProduct(lineItem.payload.productNumber, currentLanguage, 'variant')
      .then((pData) => {
        setData(pData)
      })
      .catch((err) => console.log(err))
  }, [])

  const {
    removeItem,
    increaseItem,
    decrementItem,
    changeQuantity,
    cartInformation,
  } = useContext(shopContext)

  const shippingRabate =
    (lineItem.payload as any)?.value &&
    (lineItem.payload as any)?.discountScope === 'delivery' &&
    cartInformation?.deliveries &&
    cartInformation?.deliveries.length > 0
      ? (cartInformation?.deliveries[0].shippingCosts?.totalPrice ?? 0) * -1
      : undefined

  const { isXs } = useBreakpoints()

  const { t } = useTranslation()

  return (
    <>
      <div className={`relative items-center grid grid-cols-12 pb-2 min-h-24`}>
        <div className="col-span-2">
          {data && data.Images && data.Images.length > 0 && (
            <PictureTag
              imageUrl={
                data.DosenBild?.fullpath ?? data.Images[0]?.image.fullpath
              }
              className="z-0 m-auto"
              sizesVW="7"
            />
          )}
        </div>
        <div className="flex col-span-10 sm:col-span-5 flex-col pl-2 sm:pl-0">
          <Link href={`/produkt/${data?.Slug}`}>
            <a>
              <p className="font-swDisp text-sm font-bold italic sm:text-base">
                {data?.ArticleName ?? lineItem?.label}
              </p>
            </a>
          </Link>
          {data?.PackagingRelation?.length > 0 && (
            <span
              className="font-swText text-sm sm:text-base"
              dangerouslySetInnerHTML={{
                __html: data?.PackagingRelation[0]?.PackagingName,
              }}
            />
          )}
          {data?.ArticleName && (
            <span className="font-swText text-xs font-light text-gray-800 sm:text-sm">
              ArtNr. {data?.OSProductNumber}
            </span>
          )}
          {isXs && (
            <div className={`w-full flex justify-content items-center`}>
              {!shippingRabate && (
                <div className="custom-number-input mr-auto h-10 w-32">
                  <div className="relative mt-1 flex h-8 w-full flex-row bg-transparent">
                    <button
                      type="button"
                      className="justify-center items-center pt-2 mr-4"
                      onClick={() => removeItem(lineItem)}
                    >
                      <Icon name="delete" />
                    </button>

                    <button
                      type="button"
                      onClick={() => decrementItem(lineItem)}
                      className="h-full w-20 cursor-pointer text-gray-600 outline-none  hover:bg-gray-400 hover:text-gray-700"
                    >
                      <span className="m-auto text-2xl font-bold">−</span>
                    </button>
                    <input
                      className="md:text-basecursor-default flex w-full items-center border border-1 text-center text-sm font-semibold text-gray-700  outline-none outline-none hover:text-black focus:text-black focus:outline-none"
                      name="custom-input-number"
                      type={`number`}
                      onChange={(value) =>
                        changeQuantity(lineItem, value.currentTarget.value)
                      }
                      value={lineItem.quantity}
                    />
                    <button
                      type="button"
                      onClick={() => increaseItem(lineItem)}
                      className=" h-full w-20 cursor-pointer text-gray-600 hover:bg-gray-400 hover:text-gray-700"
                    >
                      <span className="m-auto text-2xl font-bold">+</span>
                    </button>
                  </div>
                </div>
              )}
              <h3 className="ml-auto font-swDisp font-bold">
                {(shippingRabate ?? lineItem.price?.totalPrice) > 0 ||
                (shippingRabate ?? lineItem.price?.totalPrice) < 0
                  ? formatter.format(
                      shippingRabate ?? lineItem.price?.totalPrice ?? 0
                    )
                  : t('GRATIS')}
              </h3>
            </div>
          )}
        </div>
        {!isXs && (
          <div className={`flex flex-col col-span-3`}>
            {!shippingRabate && (
              <div className="mt-1 flex h-10 bg-transparent max-w-full">
                <button
                  type="button"
                  className="h-full pr-auto justify-center items-center pt-2 mr-4"
                  onClick={() => removeItem(lineItem)}
                >
                  <Icon name="delete" />
                </button>
                <button
                  type="button"
                  onClick={() => decrementItem(lineItem)}
                  className="h-full w-20  cursor-pointer border border-1 text-gray-600 outline-none  hover:bg-gray-400 hover:text-gray-700"
                >
                  <span className="m-auto text-2xl font-thin">−</span>
                </button>
                <input
                  className="text-md w-24 relative md:text-basecursor-default items-center border border-1 text-center font-semibold text-gray-700  outline-none hover:text-black focus:text-black focus:outline-none"
                  name="custom-input-number"
                  type={`number`}
                  onChange={(value) =>
                    changeQuantity(lineItem, value.currentTarget.value)
                  }
                  value={lineItem.quantity}
                />
                <button
                  type="button"
                  onClick={() => increaseItem(lineItem)}
                  className="h-full w-20 cursor-pointer border border-1 text-gray-600 hover:bg-gray-400 hover:text-gray-700"
                >
                  <span className="m-auto text-2xl font-thin">+</span>
                </button>
              </div>
            )}
          </div>
        )}
        {!isXs && (
          <div className={`col-span-2 flex flex-col ml-auto pr-4`}>
            <h3 className="ml-auto font-swDisp text-2xl font-bold italic">
              {(shippingRabate ?? lineItem.price?.totalPrice) > 0 ||
              (shippingRabate ?? lineItem.price?.totalPrice) < 0
                ? formatter.format(
                    shippingRabate ?? lineItem.price?.totalPrice ?? 0
                  )
                : t('GRATIS')}
            </h3>
            {!shippingRabate && lineItem?.price?.unitPrice > 0 && (
              <p className="ml-auto">
                {formatter.format(lineItem?.price?.unitPrice ?? 0)}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  )
}
