import { LineItem, Product } from '@shopware-pwa/commons'
import { Cart } from '@shopware-pwa/commons/interfaces/models/checkout/cart/Cart'
import { Order } from '@shopware-pwa/commons/interfaces/models/checkout/order/Order'
import { OrderLineItem } from '@shopware-pwa/commons/interfaces/models/checkout/order/OrderLineItem'

import { IProduct } from '../models/product'

export function makeItemObject(product: IProduct, shopwareProduct: any) {
  return {
    item_id: product?.OSProductNumber,
    item_name: product?.ArticleName,
    currency: "EUR",
    index: 0,
    item_brand: "Spiceworld",
    price:
      shopwareProduct?.price[0].listPrice?.gross ??
      shopwareProduct?.price[0].gross,
  }
}

export function makeItemObjectFromCartItem(lineItem: LineItem | OrderLineItem) {
  return {
    item_id: (lineItem.payload as any).productNumber,
    currency: "EUR",
    index: 0,
    item_brand: "Spiceworld",
    price: lineItem?.price?.unitPrice ?? 0,
    quantity: lineItem.quantity,
  }
}

export function makeItemObjectFromOrderItem(lineItem: OrderLineItem) {
  return {
    item_id: (lineItem.payload as any).productNumber,
    currency: "EUR",
    index: 0,
    item_brand: "Spiceworld",
    item_name: lineItem.label,
    price: lineItem?.unitPrice ?? 0,
    quantity: lineItem.quantity,
  }
}

export function trackItemView(product: IProduct, shopwareProduct: any) {
  window?.dataLayer?.push({ ecommerce: null })
  window?.dataLayer?.push({
    event: "view_item",
    ecommerce: {
      items: [makeItemObject(product, shopwareProduct)],
    },
  })
}

export function trackAddItemToCard(
  product: IProduct,
  shopwareProduct: any,
  quantity: number
) {
  window?.dataLayer?.push({ ecommerce: null })
  window?.dataLayer?.push({
    event: "add_to_cart",
    ecommerce: {
      items: [{ ...makeItemObject(product, shopwareProduct), quantity }],
    },
  })
}

export function trackRemoveItemToCard(cartItem: LineItem) {
  window?.dataLayer?.push({ ecommerce: null })
  window?.dataLayer?.push({
    event: "remove_from_cart",
    ecommerce: {
      items: [makeItemObjectFromCartItem(cartItem)],
    },
  })
}

export function trackBeginCheckout(cart: Cart) {
  window?.dataLayer?.push({ ecommerce: null })
  window?.dataLayer?.push({
    event: "begin_checkout",
    ecommerce: {
      items: cart.lineItems?.map((cartItem) =>
        makeItemObjectFromCartItem(cartItem)
      ),
    },
  })
}

export function trackShowCart(cart: Cart) {
  window?.dataLayer?.push({ ecommerce: null })
  window?.dataLayer?.push({
    event: "view_cart",
    ecommerce: {
      currency: "EUR",
      value: cart.price?.totalPrice,
      items: cart.lineItems?.map((cartItem) =>
        makeItemObjectFromCartItem(cartItem)
      ),
    },
  })
}

export function trackOrderCreated(order: Order, shopwareProducts: Product[]) {
  window?.dataLayer?.push({ ecommerce: null })
  window?.dataLayer?.push({
    event: "purchase",

    ecommerce: {
      items: order.lineItems.map((cartItem) =>
        makeItemObjectFromOrderItem(cartItem)
      ),
      transaction_id: order.id,
      value: order.amountTotal,
      tax: order.amountTotal - order.amountNet,
      shipping: order.shippingTotal,
      currency: "EUR",
    },
  })
  window?.dataLayer?.push({
    ts_checkout: {
      tsCheckoutOrderNr: order.orderNumber,
      tsCheckoutBuyerEmail: order.orderCustomer?.email,
      tsCheckoutOrderAmount: order.amountTotal,
      tsCheckoutOrderCurrency: "EUR",
      tsCheckoutOrderPaymentType: order.transactions[0]?.paymentMethod?.name,
      tsCheckoutProducts: order.lineItems.map((cartItem) => {
        return shopwareProducts?.find(
          (p) => p.productNumber === (cartItem.payload as any)?.productNumber
        )
      }),
    },
  })
}
