import { faCheck, faPenToSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icon from '@material-tailwind/react/Icon'
import { Box, Modal } from '@mui/material'
import { SxProps } from '@mui/system'
import { CustomerAddress } from '@shopware-pwa/commons/interfaces/models/checkout/customer/CustomerAddress'
import { useTranslation } from 'next-i18next'
import React, { useContext, useState } from 'react'

import useBreakpoints from '../../hooks/use-breakpoints'
import { shopContext } from '../../providers/shop-context'
import { AddressUpsertBox } from './address-upsert-box'

interface AddressBoxProps {
  address: CustomerAddress
  setModifyAddress?: any
  shouldModify?: boolean
}

interface IconBlobProps {
  title: string
}

export const IconBlob = ({ title }: IconBlobProps) => {
  return (
    <span className={`border bg-2 py-1 text-xs`}>
      <FontAwesomeIcon className="w-4 font-xs hover:text-9" icon={faCheck} />
      {title}
    </span>
  )
}

export const AddressBox = ({
  address,
  setModifyAddress,
  shouldModify = true,
}: AddressBoxProps) => {
  const {
    isAddressDefaultBilling,
    isAddressDefaultShipping,
    setDefaultShippingAddress,
    setDefaultBillingAddress,
    deleteAddress,
  } = useContext(shopContext)

  const { t } = useTranslation('common')

  return (
    <div
      className={`border relative flex flex-col border-gray-200 p-4 leading-6 font-swText w-full`}
    >
      <div className={`flex flex-col`}>
        <div className={`flex gap-x-4 pb-2 `}>
          {isAddressDefaultBilling(address.id) && (
            <IconBlob title={t('INVOICE_ADDRESS')} />
          )}
          {isAddressDefaultShipping(address.id) && (
            <IconBlob title={t('SHIPPING_ADDRESS')} />
          )}
          <div className={`flex ml-auto w-1/6`}>
            {setModifyAddress && shouldModify && (
              <button
                className={`ml-auto`}
                onClick={() => setModifyAddress(address)}
                type={`button`}
              >
                <FontAwesomeIcon icon={faPenToSquare} className={`ml-auto`} />
              </button>
            )}
            {!isAddressDefaultBilling(address.id) &&
              !isAddressDefaultShipping(address.id) &&
              setModifyAddress && (
                <button
                  type="button"
                  className="ml-auto"
                  onClick={() => deleteAddress(address)}
                >
                  <Icon name="delete" />
                </button>
              )}
          </div>
        </div>
        <p className={`italic`}>{address.company}</p>
        <p>
          {address.firstName} {address.lastName}
        </p>
        <p>{address.street}</p>
        <p>
          {address.zipcode} {address.city}
        </p>
        <p>{address.country?.name}</p>
        <p>{(address.countryState as any)?.name}</p>
      </div>
      <div className={`sm:flex sm:flex-col gap-4 pt-4 mt-auto`}>
        {!isAddressDefaultShipping(address.id) &&
          setModifyAddress &&
          shouldModify && (
            <button
              className="w-full cursor-pointer border border-black bg-white px-2 py-1 font-swText text-black hover:border-9 hover:bg-9 hover:text-white"
              type="button"
              onClick={() => setDefaultShippingAddress(address.id)}
            >
              {t('AS')} {t('SHIPPING_ADDRESS')} {t('DEFINE')}
            </button>
          )}
        {!isAddressDefaultBilling(address.id) &&
          setModifyAddress &&
          shouldModify && (
            <button
              className="w-full cursor-pointer border border-black bg-white px-2 py-1 font-swText text-black hover:border-9 hover:bg-9 hover:text-white mt-2 sm:mt-0"
              type="button"
              onClick={() => setDefaultBillingAddress(address.id)}
            >
              {t('AS')} {t('INVOICE_ADDRESS')} {t('DEFINE')}
            </button>
          )}
      </div>
    </div>
  )
}

export const AddressListBox = ({ showFurther = true }) => {
  const { isXs } = useBreakpoints()

  const style = isXs
    ? ({
        position: 'fixed',
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
        height: '100vh',
        width: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        overflowY: 'auto',
        p: 4,
      } as SxProps)
    : ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      } as SxProps)

  const {
    customerAddresses,
    isAddressDefaultBilling,
    isAddressDefaultShipping,
  } = useContext(shopContext)

  const [modifyAddress, setModifyAddress] = useState<CustomerAddress>(undefined)

  const [showAddress, setShowAddress] = useState(false)
  const { t } = useTranslation('common')

  return (
    <>
      <div className={`flex gap-4 grid grid-cols-1 sm:grid-cols-2`}>
        {customerAddresses &&
          customerAddresses.length > 0 &&
          customerAddresses
            .filter(
              (address) =>
                address.zipcode !== '00000' &&
                (isAddressDefaultShipping(address.id) ||
                  isAddressDefaultBilling(address.id))
            )

            .map((address) => (
              <AddressBox
                address={address}
                key={address.id}
                setModifyAddress={setModifyAddress}
                shouldModify={showFurther}
              />
            ))}
      </div>
      <div className={`py-2`}></div>
      {showFurther && (
        <button
          className="mt-4 w-full sm:w-1/2 cursor-pointer border border-black bg-white px-2 py-1 font-swText text-black hover:border-9 hover:bg-9 hover:text-white"
          type="button"
          onClick={() => setShowAddress(!showAddress)}
        >
          {t('SHOW_FURTHER_ADDRESSES')}{' '}
        </button>
      )}
      <div className={`py-4`}></div>
      {showAddress && showFurther && (
        <>
          <p className={`font-swDisp text-2xl font-bold w-full flex `}>
            {t('ADDRESSBOOK')}
          </p>
          <div className={`flex gap-4 grid grid-cols-1 sm:grid-cols-2`}>
            {customerAddresses &&
              customerAddresses.length > 0 &&
              customerAddresses
                .filter(
                  (address) =>
                    address.zipcode !== '00000' &&
                    !(
                      isAddressDefaultShipping(address.id) ||
                      isAddressDefaultBilling(address.id)
                    )
                )

                .map((address) => (
                  <AddressBox
                    address={address}
                    key={address.id}
                    setModifyAddress={setModifyAddress}
                    shouldModify={showFurther}
                  />
                ))}
          </div>
          <button
            className="my-4 w-full cursor-pointer border border-black bg-white px-2 py-1 font-swText text-black hover:border-9 hover:bg-9 hover:text-white"
            type="button"
            onClick={() => setModifyAddress({} as CustomerAddress)}
          >
            {t('NEW_ADDRESS')}{' '}
          </button>
        </>
      )}
      <Modal
        open={modifyAddress !== undefined}
        onClose={() => setModifyAddress(undefined)}
      >
        <Box sx={style}>
          <div className="ml-auto text-right text-xl">
            <button onClick={() => setModifyAddress(undefined)}>
              <Icon name="close" size={`xl`} />
            </button>
          </div>

          <AddressUpsertBox
            address={modifyAddress}
            setModifyAddress={setModifyAddress}
          />
        </Box>
      </Modal>
    </>
  )
}
