import React from 'react'

import { IHeader, IHeaderMenuRoot } from '../../models/settings'
import { HeaderMenuRoot } from './header-menu-root'

export interface HeaderRootDesktopProps {
  data: IHeader
}

export const HeaderRootDesktop = ({ data }: HeaderRootDesktopProps) => {
  return (
    <ul className="mx-auto flex justify-center">
      {data.children.map((entry) => (
        <HeaderMenuRoot data={entry as IHeaderMenuRoot} key={entry.id} />
      ))}
    </ul>
  )
}
