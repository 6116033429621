import _ from 'lodash'

import { IAttribute } from './category'
import { HeroVideo, IComponentElement } from './landingpage'
import { IRecipe } from './recipe'
import { IDeclarationIcon } from './settings'

export interface IPrice {
  AmountPrice: number
  Currency: string
  GrossPrice: boolean
  Price: number
  PriceCode: string
}

export interface IShopData {
  ShopwareId: string
  Product: IProduct
  Shop: {
    id: string
  }
}

export interface Review {
  Rating: number
  id: number
  Comment: string
  Datum: Date
  Email: string
  Name: string
  Headline: string
}

export interface IProduct extends IComponentElement {
  AlternativeView: boolean
  classname: string
  BulletPoints: { BulletPoint: string }[]
  IsActive: boolean
  SellUnit: string
  OSProductNumber: string
  EANCode: string
  Slug: string
  ShopData: IShopData[]
  IsBio: any
  ArticleName: string
  Images: ImageWrapper[]
  DosenBild: Image
  SchuettungsBild: Image
  SEO: Seo[]
  NewShortDescription: string
  GoodToKnow: any
  Usage?: string
  Reviews?: Review[]
  Taste?: string
  Production?: string
  FillWeight: string
  CaloriesKJ: number
  CaloriesKCAL: number
  TotalFat: number
  NettoWeight: number
  SaturatedFat: number
  HeroVideo: HeroVideo
  TotalCarbs: number
  TotalSugars: number
  Protein: number
  Sodium: number
  Prices: IPrice[]
  Fibers: any
  Broteinheiten: any
  categories: Category[]
  categoriesShop: CategoriesShop[]
  ContainedProducts: IProduct[]
  Sachmerkmale: Sachmerkmale[]
  PackagingRelation: PackagingRelation[]
  Recipes: IRecipe[]
  Sale: Sale[]
  Declaration: IDeclarationIcon[]
  WORLD: IAttribute[]
  VKZ: IAttribute[]
  GRIND: IAttribute[]
  USAGEREL: IAttribute[]
  NUTR: IAttribute[]
  VIP: IAttribute[]
  MERCH: IAttribute[]
  SCHARFGRAD: IAttribute[]
  IDEEN: IAttribute[]
  ANLASS: IAttribute[]
  Attributes: IAttribute[]
  ColorCode: string
  NutritionPortionUnit: string
  Portion: number
  AdditionalHintPortions: string
  Tip: string
  Ingredients: string
}

export const ValueForIdentifier = (product: IProduct, identifier: string) => {
  const alleMerkmale = product.Sachmerkmale?.map((s) => s.features).flat()

  return _.find(alleMerkmale, { description: identifier })
}

export interface Sale {
  Name: string
  Farbe: string
  Shop: [
    {
      id: number
    }
  ]
}

export interface Sachmerkmale {
  name: string
  features: Feature[]
}

export interface Feature {
  __typename: string
  name?: string
  description?: string
  text?: string
  selection?: string
  country?: string
}

export interface ImageWrapper {
  image: Image
}

export interface Image {
  id: string
  fullpath: string
  alt?: string
}

export interface Seo {
  __typename: string
  Title: string
  Keywords: string
  Description: string
}

export interface Category {
  id: string
  Name: string
  Landingpage: any
}

export interface CategoriesShop {
  id: string
  Slug: string
  Name: string
  Landingpage: any
}

export interface PackagingRelation {
  id: string
  Name: string
  Description: string
  PackagingName: string
  Images?: ImageWrapper[]
}
