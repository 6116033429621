import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { CalculatedPrice } from '@shopware-pwa/commons/interfaces/models/checkout/cart/price/CalculatedPrice'
import { PriceDefinitionInterface } from '@shopware-pwa/commons/interfaces/models/checkout/cart/price/PriceDefinitionInterface'
import { Order } from '@shopware-pwa/commons/interfaces/models/checkout/order/Order'
import { OrderDeliveryPosition } from '@shopware-pwa/commons/interfaces/models/checkout/order/OrderDeliveryPosition'
import { CustomField } from '@shopware-pwa/commons/interfaces/models/common/CustomField'
import { Media } from '@shopware-pwa/commons/interfaces/models/content/media/Media'
import moment from 'moment'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import React, { useContext, useState } from 'react'

import { formatter } from '../../helper/currency-formatter'
import { shopContext } from '../../providers/shop-context'
import { OrderLineItemRow } from '../shopware/order-line-item'
import { AddressBox } from './address-list-box'

interface OrderLineProps {
  order: Order
  index: number
}

export interface OrderLineItem {
  orderId: string
  identifier: string
  referenceId: string | null
  quantity: number
  unitPrice: number
  totalPrice: number
  label: string
  description: string | null
  good: boolean
  removable: boolean
  coverId: string | null
  stackable: boolean
  price: CalculatedPrice | null
  priceDefiniton: PriceDefinitionInterface | null
  payload: any
  parentId: string | null
  type: string | null
  order: Order | null
  orderDeliveryPosition: OrderDeliveryPosition[] | null
  customFields: CustomField[]
  cover: Media | null
  children: OrderLineItem[] | null
}

export const OrderLine = ({ order, index }: OrderLineProps) => {
  const [orderDetails, setOrderDetails] = useState<Order>(order)
  const { addToBasket } = useContext(shopContext)

  const [expanded, setExpanded] = React.useState<string | false>('panel0')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  async function addAllToBasket() {
    for (const orderDetailItem of orderDetails.lineItems) {
      await addToBasket(
        (orderDetailItem as any).productId,
        undefined,
        orderDetailItem.quantity
      )
    }
  }

  const { t } = useTranslation('common')

  return (
    <Accordion
      className={`bg-transparent`}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      <AccordionSummary
        className={`bg-none`}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <div className={`flex gap-4 w-full`}>
          {orderDetails && (
            <>
              <div className={`flex gap-x-4 font-swDisp w-full flex-wrap`}>
                <div className={`flex flex-col w-1/3 sm:w-1/4 `}>
                  <span
                    className={`text-xxs text-gray-400 italic leading-none`}
                  >
                    {t('INVOICENUMBER')}
                  </span>
                  <p className={`font-bold`}>{orderDetails.orderNumber}</p>
                </div>

                <div className={`flex flex-col w-1/2 sm:w-1/4`}>
                  <span
                    className={`text-xxs text-gray-400 italic leading-none`}
                  >
                    {t('ORDERDATE')}
                  </span>
                  <p className={`font-bold`}>
                    {moment(orderDetails.orderDateTime).format(
                      'DD.MM.YYYY HH:mm'
                    )}
                  </p>
                </div>
                <div className={`flex flex-col w-1/3 sm:w-1/4 pt-2 sm:pt-0`}>
                  <span
                    className={`text-xxs text-gray-400 italic leading-none`}
                  >
                    {t('ORDERTOTAL')}
                  </span>
                  <p className={`font-bold`}>
                    {' '}
                    {formatter.format(orderDetails.amountTotal ?? 0)}
                  </p>
                </div>
                <div className={`flex flex-col w-1/2 sm:w-1/4 pt-2 sm:pt-0`}>
                  <span
                    className={`text-xxs text-gray-400 italic leading-none`}
                  >
                    {t('SHIPSTATE')}
                  </span>

                  <p className={`font-bold`}>
                    {orderDetails?.deliveries.length > 0 &&
                      orderDetails?.deliveries[0].stateMachineState?.name}{' '}
                    (
                    {orderDetails?.deliveries.length > 0 &&
                      orderDetails?.deliveries[0].shippingMethod?.name}
                    )
                  </p>
                </div>
                <div className={`flex flex-col w-1/2 sm:w-1/4 pt-2 sm:pt-0`}>
                  <span
                    className={`text-xxs text-gray-400 italic leading-none`}
                  >
                    {t('PAYSTATE')}
                  </span>

                  <p className={`font-bold`}>
                    {orderDetails.stateMachineState.technicalName === 'open' &&
                      (orderDetails.transactions[
                        orderDetails.transactions.length - 1
                      ]?.stateMachineState.technicalName === 'cancelled' ||
                        orderDetails.transactions[
                          orderDetails.transactions.length - 1
                        ]?.stateMachineState.technicalName === 'failed') && (
                        <Link href={`/payment?id=${orderDetails.id}`}>
                          <a
                            className={` w-full py font-bold text-white inline-block border border-black text-center bg-9 hover:bg-black hover:text-white font-swDisp`}
                          >
                            {t('PAY_NOW')}{' '}
                          </a>
                        </Link>
                      )}
                    {orderDetails.transactions[
                      orderDetails.transactions.length - 1
                    ]?.stateMachineState.technicalName === 'paid' && (
                      <span>
                        {' '}
                        {
                          orderDetails.transactions[
                            orderDetails.transactions.length - 1
                          ]?.paymentMethod?.name
                        }
                      </span>
                    )}
                  </p>
                </div>
                <div className={`flex flex-col w-1/2 sm:w-1/4 pt-2 sm:pt-0`}>
                  <span
                    className={`text-xxs text-gray-400 italic leading-none`}
                  >
                    {t('TRACKING_HEAD')}
                  </span>

                  <p className={`font-bold`}>
                    {orderDetails?.deliveries?.length > 0 &&
                      orderDetails.deliveries[0]?.trackingCodes?.length > 0 && (
                        <a
                          href={`${(
                            orderDetails?.deliveries[0]?.shippingMethod as any
                          )?.trackingUrl.replace(
                            '%s',
                            orderDetails?.deliveries[0].trackingCodes[0]
                          )}`}
                          className={` w-full py font-bold text-white inline-block border border-black text-center bg-9 hover:bg-black hover:text-white font-swDisp`}
                        >
                          {t('TRACKING_LINK')}
                        </a>
                      )}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={`flex gap-4 grid grid-cols-1 sm:grid-cols-2 pb-4`}>
          {(orderDetails as any)?.billingAddress && (
            <div className={`flex flex-col`}>
              <p className={`font-swDisp italic font-bold pb-1`}>
                {t('INVOICE_ADDRESS')}
              </p>
              <AddressBox address={(orderDetails as any)?.billingAddress} />
            </div>
          )}
          {orderDetails?.deliveries.length > 0 &&
            (orderDetails?.deliveries[0] as any)?.shippingOrderAddress && (
              <div className={`flex flex-col`}>
                <p className={`font-swDisp italic font-bold pb-1`}>
                  {t('SHIPPING_ADDRESS')}
                </p>
                <AddressBox
                  address={
                    (orderDetails?.deliveries[0] as any)?.shippingOrderAddress
                  }
                />
              </div>
            )}
        </div>
        {orderDetails &&
          orderDetails.lineItems &&
          orderDetails.lineItems.length > 0 &&
          orderDetails.lineItems.map((lineItem) => (
            <OrderLineItemRow lineItem={lineItem} key={lineItem.referenceId} />
          ))}
        {orderDetails &&
          orderDetails.documents &&
          (orderDetails.documents as any[]).length > 0 && (
            <a
              href={`/api/invoice?id=${
                (orderDetails.documents[0] as any).id
              }&deepLinkCode=${
                (orderDetails.documents[0] as any).deepLinkCode
              }`}
              target="_blank"
              className={` w-full py-2 my-2 font-bold text-black inline-block bg-7 text-white border border-black text-center hover:bg-black hover:text-white font-swDisp`}
              rel="noreferrer"
            >
              {t('DOWNLOAD_INVOICE')}
            </a>
          )}
        <button
          onClick={() => addAllToBasket()}
          className={` w-full py-2 font-bold text-black inline-block bg-9 text-white border border-black text-center hover:bg-black hover:text-white font-swDisp`}
        >
          {t('BUY_AGAIN')}
        </button>
      </AccordionDetails>
    </Accordion>
  )
}
