import Link from 'next-translate-routes/link'
import { useRouter } from 'next-translate-routes/router'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { IProduct } from '../../models/product'

interface BreadcrumbProps {
  Product: IProduct
}

interface SpanRenderProps {
  name: string
  url: string
}

const SpanRender = ({ name, url }: SpanRenderProps) => (
  <Link href={url}>
    <a>
      <span className="px-2 font-swDisp hover:underline">{name}</span>
    </a>
  </Link>
)

export const Breadcrumb = ({ Product }: BreadcrumbProps) => {
  const router = useRouter()
  const { t } = useTranslation('common')

  return (
    <div className="relative flex py-4">
      <span
        className="cursor-pointer px-2 font-swDisp font-bold"
        onClick={() => router.back()}
      >
        {t('BACK')}
      </span>{' '}
      / <SpanRender name="Home" url="/" /> /{' '}
      {Product.categoriesShop?.length > 0 && (
        <SpanRender
          name={Product.categoriesShop[0]?.Name}
          url={`/kategorie/${Product.categoriesShop[0]?.Slug}`}
        />
      )}
    </div>
  )
}
