import { faCircleInfo, faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import { CustomerRegistrationParams } from '@shopware-pwa/commons'
import axios from 'axios'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { shopContext } from '../../providers/shop-context'
import { CustomInputField } from './address-upsert-box'

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

interface RegisterBoxProps {
  allowGuest?: boolean
  showLogin?: any
  setShowLogin?: any
  setLoginState?: any
  setLoginMessage?: any
  showClose?: boolean
}

export const RegisterBox = ({
  showLogin,
  setShowLogin,
  setLoginState,
  setLoginMessage,
  showClose = true,
}: RegisterBoxProps) => {
  const { pageRegister, salutations, availableCountries } =
    useContext(shopContext)

  const [showGuest, setShowGuest] = useState(false)

  const [guest, setGuest] = useState(false)

  const [googleApproved, setGoogleApproved] = useState(false)

  const [originAddress, setOriginAddress] = useState(undefined)
  const [approvedAddress, setApprovedAddress] = useState(undefined)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    setError,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    // check entered Data (do google address validation)
    setOriginAddress(data)
    if (true) {
      console.log(availableCountries)
      console.log(data)
      const googleResponse = await axios.post(
        'https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyDYSlC5gDUpeNSfGUxCbwzyH3Ng0SU3w7k',
        {
          address: {
            regionCode: availableCountries.find((c) => c.id === data.countryId)
              ?.iso,
            postalCode: data.zipcode,
            addressLines: [data.street, data.city],
          },
        }
      )

      if (googleResponse.status === 200) {
        const result = googleResponse.data?.result?.address
        console.log(result)
        console.log(result.unconfirmedComponentTypes)
        setApprovedAddress(result)

        setGoogleApproved(true)
      }
    }
  }
  const [googleCheckValue, setGoogleCheckValue] = React.useState('origin')

  const submitTheDataToShopware = async () => {
    let data = originAddress
    data.countryId =
      data.countryId ??
      availableCountries?.find(
        (x) => x.iso3 === process.env.NEXT_PUBLIC_DEFAULT_COUNTRY
      )?.id

    if (googleCheckValue === 'google') {
      data = {
        ...data,
        street: `${
          approvedAddress.addressComponents.find(
            (p) => p.componentType === 'route'
          ).componentName.text
        } ${
          approvedAddress.addressComponents.find(
            (p) => p.componentType === 'street_number'
          ).componentName.text
        }`,
        city: approvedAddress.addressComponents.find(
          (p) => p.componentType === 'locality'
        ).componentName.text,
        zipcode: approvedAddress.addressComponents.find(
          (p) => p.componentType === 'postal_code'
        ).componentName.text,
        billingAddres: {
          ...data.billingAddres,
          city: approvedAddress.addressComponents.find(
            (p) => p.componentType === 'locality'
          ).componentName.text,
          zipcode: approvedAddress.addressComponents.find(
            (p) => p.componentType === 'postal_code'
          ).componentName.text,
          street: `${
            approvedAddress.addressComponents.find(
              (p) => p.componentType === 'route'
            ).componentName.text
          } ${
            approvedAddress.addressComponents.find(
              (p) => p.componentType === 'street_number'
            ).componentName.text
          }`,
        },
      }
    }

    await pageRegister({
      ...data,
      password: data.guest ? undefined : data.password,
      storefrontUrl: process.env.NEXT_PUBLIC_SW_ENDPOINT,
      acceptedDataProtection: true,
      billingAddress: {
        city: data.city,
        firstName: data.firstName,
        lastName: data.lastName,
        salutationId: data.salutationId,
        street: data.street,
        zipcode: data.zipcode,
        countryId: data.countryId,
      },
    } as CustomerRegistrationParams)

    if (showLogin) {
      showLogin(false)
    }
  }

  const { t } = useTranslation('common')
  const watchEmail = watch('email')

  const [mailFound, setMailFound] = useState(undefined)
  useEffect(() => {
    if (validateEmail(watchEmail) && mailFound === undefined) {
      axios
        .post(
          `${process.env.NEXT_PUBLIC_SW_ENDPOINT}/store-api/stayspiced/check`,
          { username: watchEmail },
          { headers: { 'sw-access-key': 'SWSCCIROPA3LMSYL8J3IIJOKGW' } }
        )
        .then((data) => {
          if (data.data.found) {
            setMailFound(true)
            if (setLoginState && setLoginMessage) {
              setLoginState(true)
              setLoginMessage(t('LOGIN_FOUND'))
            }
          }
        })
        .catch()
    }
  }, [watchEmail])

  function handleKeyInput(evt: any) {
    if (evt.key === 'Escape') {
      try {
        setShowLogin(false)
      } catch (err) {}
    }
    if (evt.key === 'Enter') {
      evt.preventDefault()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', (evt) => {
      handleKeyInput(evt)
    })
  }, [])

  let autocomplete = React.useRef<any>()
  let fieldRef = React.useRef()

  function fillInAddress(place) {
    // Get the place details from the autocomplete object.
    let address1 = ''
    const postcode = ''

    // @ts-ignore
    // eslint-disable-next-line no-restricted-syntax
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          address1 = `${address1} ${component.long_name}`
          break
        }

        case 'route': {
          address1 = `${component.long_name} ${address1}`
          break
        }

        case 'postal_code': {
          setValue('zipcode', `${component.long_name}${postcode}`)
          break
        }

        case 'locality':
          setValue('city', component.long_name)
          break

        case 'country':
          setValue(
            'countryId',
            availableCountries
              .find(
                (c) =>
                  c.iso.toLowerCase() === component.short_name.toLowerCase()
              )
              ?.id.toString(),
            { shouldValidate: true }
          )
          break

        default:
          break
      }
    }

    setValue('street', address1)
  }

  const handleGoogleCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGoogleCheckValue((event.target as HTMLInputElement).value)
  }

  const [googleLoaded, setGoogleLoaded] = useState(true)
  const handlePlaceSelect = () => {
    // Extract City From Address Object
    // @ts-ignore
    const addressObject = autocomplete.getPlace()
    const address = addressObject.address_components

    // Check if address is valid
    if (address) {
      // Set State
      fillInAddress(addressObject)
    }
  }

  useEffect(() => {
    if (fieldRef && googleLoaded === true) {
      // Declare Options For
      const options = {
        types: ['route', 'locality', 'street_number', 'postal_code', 'country'],
      }

      // Initialize Google Autocomplete
      /* global google */
      // @ts-ignore
      autocomplete = new google.maps.places.Autocomplete(fieldRef, options)
      // Avoid paying for data that you don't need by restricting the
      // set of place fields that are returned to just the address
      // components and formatted address
      // @ts-ignore
      autocomplete.setFields(['address_components', 'formatted_address'])
      // Fire Event when a suggested name is selected
      // @ts-ignore
      autocomplete.addListener('place_changed', handlePlaceSelect)
    }
  }, [])

  return (
    <>
      {showLogin && (
        <div className={`flex flex-row`}>
          <h2 className={`text-2xl font-swDisp italic font-bold mr-auto`}>
            {t('REGISTER')}
          </h2>
          {showClose && (
            <button
              className="ml-auto"
              type="button"
              onClick={() => setShowLogin(false)}
            >
              <FontAwesomeIcon
                className="w-4 text-xxl hover:text-9"
                icon={faXmark}
              />
            </button>
          )}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="py-4">
        <div>
          {!googleApproved && (
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 ">
              <CustomInputField
                label={t('EMAIL')}
                name={`email`}
                register={register}
                required={true}
                className={`col-span-2 sm:col-span-1`}
              />
              {!guest && (
                <CustomInputField
                  label={t('PASSWORD')}
                  name={`password`}
                  register={register}
                  type={`password`}
                  required={!guest}
                  minLength={8}
                  className={`col-span-2 sm:col-span-1`}
                />
              )}

              {showGuest && (
                <FormControlLabel
                  className={`col-span-2`}
                  control={
                    <Checkbox
                      {...register('guest')}
                      onChange={(value) =>
                        setGuest(value.currentTarget.checked)
                      }
                    />
                  }
                  label={t('NO_ACCOUNT')}
                />
              )}

              <CustomInputField
                register={register}
                label={t('COMPANY')}
                name={'company'}
                className={`col-span-2`}
              />

              <FormControl
                fullWidth
                className={`col-span-2`}
                variant="standard"
              >
                <InputLabel id="salutation">{t('SALUTATION')}</InputLabel>
                <Controller
                  name="salutationId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="salutation-label"
                      id="salutation-select"
                      variant={`outlined`}
                      label={t('SALUTATION')}
                      required={true}
                      {...register('salutationId', { required: true })}
                    >
                      {salutations &&
                        salutations.length > 0 &&
                        salutations.map((s) => (
                          <MenuItem value={s.id} key={s.id}>
                            {s.displayName}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>

              <CustomInputField
                register={register}
                label={t('FIRSTNAME')}
                name={'firstName'}
                required={true}
              />
              <CustomInputField
                register={register}
                label={t('LASTNAME')}
                name={'lastName'}
                required={true}
              />

              <div className={`col-span-2 sm:col-span-1 w-full`}>
                <TextField
                  required={true}
                  variant="outlined"
                  name={'street'}
                  className={`w-full`}
                  label={t('STREET')}
                  inputRef={(ref) => {
                    fieldRef = ref
                  }}
                  {...register('street', { required: true })}
                />
              </div>

              <CustomInputField
                register={register}
                label={t('POSTAL')}
                name={'zipcode'}
                required={true}
              />
              <CustomInputField
                register={register}
                label={t('CITY')}
                name={'city'}
                required={true}
                className={`col-span-2`}
              />
              <FormControl
                fullWidth
                className={`col-span-2`}
                variant="standard"
              >
                <InputLabel id="salutation">{t('COUNTRY')}</InputLabel>
                <Controller
                  name="countryId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="salutation-label"
                      id="countryId"
                      variant={`outlined`}
                      label={t('COUNTRY')}
                      onChange={onChange}
                      value={
                        value ??
                        availableCountries?.find(
                          (x) =>
                            x.iso3 === process.env.NEXT_PUBLIC_DEFAULT_COUNTRY
                        )?.id
                      }
                    >
                      {availableCountries &&
                        availableCountries.length > 0 &&
                        availableCountries
                          .sort((x) => x.position)
                          .map((s) => (
                            <MenuItem value={s.id}>{s.name}</MenuItem>
                          ))}
                    </Select>
                  )}
                />
              </FormControl>
              <div
                className={`w-full flex flex-row items-center col-span-2 pt-4`}
              >
                <FormControlLabel
                  className={`col-span-2`}
                  control={
                    <Checkbox
                      required={true}
                      {...register('termsOfCondition', { required: true })}
                    />
                  }
                  label=""
                />
                <span
                  dangerouslySetInnerHTML={{ __html: t('REGISTER_INFO') }}
                />
              </div>
              <div
                className={`w-full flex flex-row items-center col-span-2 gap-0 mt-0 pt-0`}
              >
                <FormControlLabel
                  className={`col-span-2`}
                  control={
                    <Checkbox required={false} {...register('newsletter')} />
                  }
                  label={t('NEWSLETTER_CHECK') as string}
                />
                <div className="tooltip group -mt-2 inline-block cursor-pointer text-left">
                  <div className="relative h-8 w-8 p-2">
                    <div className="tooltip_bottom invisible absolute -top-2 -ml-1 h-3 w-6 overflow-hidden opacity-0 group-hover:visible group-hover:opacity-100">
                      <div
                        className="bg-brand invisible absolute left-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 rotate-45 opacity-0 group-hover:visible group-hover:opacity-100"
                        aria-hidden="true"
                      ></div>
                    </div>
                    <FontAwesomeIcon
                      className="w-8 pr-2 text-xl"
                      icon={faCircleInfo}
                    />
                  </div>
                  <div className="invisible absolute bottom-24 sm:bottom-48 left-16 sm:left-36 z-200 -mb-3 -ml-8 w-72 rounded bg-8 p-4 font-swText text-xs text-white opacity-0 group-hover:visible group-hover:opacity-100">
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('NEWSLETTER_INFO'),
                        }}
                      ></span>
                      <Link href="/datenschutz">
                        <a className="underline"> {t('PRIVACY')}</a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {googleApproved && (
            <div className={'py-4'}>
              {t('GOOGLE_ADDRESS_CHECK')}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="origin"
                name="radio-buttons-group"
                value={googleCheckValue}
                onChange={handleGoogleCheckChange}
              >
                <FormControlLabel
                  value={'origin'}
                  label={`${originAddress.street}, ${originAddress.zipcode}
                ${originAddress.city}`}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={'google'}
                  label={`${approvedAddress.formattedAddress}`}
                  control={<Radio />}
                />
              </RadioGroup>
              {approvedAddress.missingComponentTypes && (
                <div className={`py-4`}>
                  {approvedAddress.missingComponentTypes.map((p) => (
                    <p className={`text-red-500`}>
                      {t(p.toString().toUpperCase())}
                    </p>
                  ))}
                </div>
              )}
              {approvedAddress.unconfirmedComponentTypes && (
                <div className={`py-4`}>
                  {approvedAddress.unconfirmedComponentTypes.map((p) => (
                    <p className={`text-orange-500`}>
                      {t(p.toString().toUpperCase())}
                    </p>
                  ))}
                </div>
              )}
              <div className={`flex flex-col sm:flex-row gap-4`}>
                <button
                  className="w-full sm:w-1/2 cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white"
                  type="button"
                  onClick={() => setGoogleApproved(false)}
                >
                  {t('CHANGE_ADDRESS')}
                </button>

                <button
                  className="w-full sm:w-1/2 cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white"
                  type="button"
                  onClick={() => submitTheDataToShopware()}
                >
                  {t('CONFIRM')}
                </button>
              </div>
            </div>
          )}
          {!googleApproved && (
            <div className="col-span-2 w-full items-center sm:inline-flex">
              <div className="mr-auto">
                <button
                  className="w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white"
                  type="submit"
                >
                  {t('CONTINUE')}
                </button>
              </div>
              {showLogin && showClose && (
                <div className="ml-auto">
                  <button
                    className="mt-2 w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white sm:mt-0"
                    onClick={() => setLoginState(true)}
                  >
                    {t('EXISTING_ACCOUNT')}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
    </>
  )
}

RegisterBox.defaultProps = {
  allowGuest: false,
}
