import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { CalculatedPrice } from "@shopware-pwa/commons/interfaces/models/checkout/cart/price/CalculatedPrice"
import { PriceDefinitionInterface } from "@shopware-pwa/commons/interfaces/models/checkout/cart/price/PriceDefinitionInterface"
import { Order } from "@shopware-pwa/commons/interfaces/models/checkout/order/Order"
import { OrderDeliveryPosition } from "@shopware-pwa/commons/interfaces/models/checkout/order/OrderDeliveryPosition"
import { CustomField } from "@shopware-pwa/commons/interfaces/models/common/CustomField"
import { Media } from "@shopware-pwa/commons/interfaces/models/content/media/Media"
import { languageContext } from "context/language-context"
import moment from "moment"
import Link from "next-translate-routes/link"
import { useTranslation } from "next-i18next"
import React, { useContext, useEffect, useState } from "react"

import { getProductMinimal } from "../../graph/get-product-minimal"
import { formatter } from "../../helper/currency-formatter"
import { IProduct } from "../../models/product"
import { shopContext } from "../../providers/shop-context"
import { OrderLineItemRow } from "../shopware/order-line-item"
import { AddressBox } from "./address-list-box"
import { OrderLineItemRowOxid } from "components/shopware/order-line-item-oxid"

interface OrderLineProps {
  order: any
  index: number
}

export const OrderLineOxid = ({ order, index }: OrderLineProps) => {
  const [orderDetails, setOrderDetails] = useState<any>(order)

  const { addToBasket } = useContext(shopContext)

  const [expanded, setExpanded] = React.useState<string | false>("panel0")

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const { t } = useTranslation("common")

  return (
    <Accordion
      className={`bg-transparent`}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      <AccordionSummary
        className={`bg-none`}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <div className={`flex gap-4 w-full`}>
          {orderDetails &&
            orderDetails.RECHNUNGEN &&
            orderDetails?.WARENAUSGANG &&
            orderDetails.RECHNUNGEN.length > 0 && (
              <>
                <div className={`flex gap-x-4 font-swDisp w-full flex-wrap`}>
                  <div className={`flex flex-col w-1/3 sm:w-1/4 `}>
                    <span
                      className={`text-xxs text-gray-400 italic leading-none`}
                    >
                      {t("INVOICENUMBER")}
                    </span>
                    <p className={`font-bold`}>
                      {orderDetails.AUFTRAG} / {orderDetails.BASKET}
                    </p>
                  </div>

                  <div className={`flex flex-col w-1/2 sm:w-1/4`}>
                    <span
                      className={`text-xxs text-gray-400 italic leading-none`}
                    >
                      {t("ORDERDATE")}
                    </span>
                    <p className={`font-bold`}>
                      {moment(orderDetails.DATUM).format("DD.MM.YYYY")}
                    </p>
                  </div>
                  <div className={`flex flex-col w-1/3 sm:w-1/4 pt-2 sm:pt-0`}>
                    <span
                      className={`text-xxs text-gray-400 italic leading-none`}
                    >
                      {t("ORDERTOTAL")}
                    </span>
                    <p className={`font-bold`}>
                      {" "}
                      {formatter.format(
                        orderDetails.RECHNUNGEN[0]?.BRUTTO ?? 0
                      )}
                    </p>
                  </div>
                  <div className={`flex flex-col w-1/2 sm:w-1/4 pt-2 sm:pt-0`}>
                    <span
                      className={`text-xxs text-gray-400 italic leading-none`}
                    >
                      {t("SHIPSTATE")}
                    </span>

                    <p className={`font-bold`}>
                      {orderDetails?.WARENAUSGANG?.find((w) => w._id) && (
                        <span>
                          (
                          {
                            orderDetails?.WARENAUSGANG?.find((w) => w._id)
                              .SENDUNGSTYP
                          }
                          )
                        </span>
                      )}
                    </p>
                  </div>
                  <div className={`flex flex-col w-1/2 sm:w-1/4 pt-2 sm:pt-0`}>
                    <span
                      className={`text-xxs text-gray-400 italic leading-none`}
                    >
                      {t("PAYSTATE")}
                    </span>

                    <p className={`font-bold`}>{t("PAID")}</p>
                  </div>
                </div>
              </>
            )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={`flex gap-4 grid grid-cols-1 sm:grid-cols-2 pb-4`}>
          {/* {(orderDetails as any)?.billingAddress && (
            <div className={`flex flex-col`}>
              <p className={`font-swDisp italic font-bold pb-1`}>
                {t("SHIPPING_ADDRESS")}
              </p>
              <AddressBox address={(orderDetails as any)?.billingAddress} />
            </div>
          )}
          {orderDetails?.deliveries.length > 0 &&
            (orderDetails?.deliveries[0] as any)?.shippingOrderAddress && (
              <div className={`flex flex-col`}>
                <p className={`font-swDisp italic font-bold pb-1`}>
                  {t("INVOICE_ADDRESS")}
                </p>
                <AddressBox
                  address={
                    (orderDetails?.deliveries[0] as any)?.shippingOrderAddress
                  }
                />
              </div>
            )} */}
        </div>
        {orderDetails &&
          orderDetails.RECHNUNGEN &&
          orderDetails.RECHNUNGEN.length > 0 &&
          orderDetails.RECHNUNGEN[0].Positionen.map((lineItem) => (
            <OrderLineItemRowOxid lineItem={lineItem} key={lineItem.POSITION} />
          ))}
        {/* <button
          onClick={() => addAllToBasket()}
          className={` w-full py-2 font-bold text-black inline-block bg-9 text-white border border-black text-center hover:bg-black hover:text-white font-swDisp`}
        >
          {t("BUY_AGAIN")}
        </button> */}
      </AccordionDetails>
    </Accordion>
  )
}

interface ImageForSkuProps {
  sku: string
}

const ImageForSku = ({ sku }: ImageForSkuProps) => {
  const [product, setProduct] = useState<IProduct>(undefined)

  const { currentLanguage } = useContext(languageContext)

  useEffect(() => {
    getProductMinimal(sku, currentLanguage, "variant")
      .then((data) => setProduct(data))
      .catch((error) => console.log(error))
  }, [sku])

  return (
    <Link href={`/produkt/${product?.Slug}`}>
      <a>
        <img
          loading="lazy"
          className={`w-16`}
          src={`${process.env.NEXT_PUBLIC_BLOB_URL}${product?.Images[0]?.image?.fullpath}`}
        />
      </a>
    </Link>
  )
}
