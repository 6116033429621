import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { CustomerAddress } from '@shopware-pwa/commons/interfaces/models/checkout/customer/CustomerAddress'
import { useTranslation } from 'next-i18next'
import React, { useContext } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { UseFormRegister } from 'react-hook-form/dist/types/form'

import { shopContext } from '../../providers/shop-context'

interface AddressUpsertBoxProps {
  address?: CustomerAddress
  setModifyAddress: any
}

interface CustomInputFieldProps {
  register: UseFormRegister<FieldValues>
  type?: string
  label: string
  name: string
  required?: boolean
  defaultValue?: string
  className?: string
  pattern?: any
  inputRef?: any
  minLength?: number
}

export const CustomInputField = ({
  register,
  type,
  label,
  name,
  required,
  defaultValue,
  className,
  pattern,
  inputRef,
  minLength,
}: CustomInputFieldProps) => {
  const [hasError, setHasError] = React.useState(false)
  return (
    <div className={`${className} w-full`}>
      <TextField
        type={type}
        InputLabelProps={{ shrink: true }}
        required={required}
        onInvalid={() => setHasError(true)}
        error={hasError}
        variant="outlined"
        inputProps={{ minLength }}
        name={name}
        className={`w-full`}
        label={label}
        inputRef={inputRef}
        defaultValue={defaultValue}
        {...register(name, { required, minLength, pattern })}
      />
    </div>
  )
}

CustomInputField.defaultProps = {
  type: 'text',
  required: false,
  className: `col-span-1`,
}

export const AddressUpsertBox = ({
  address,
  setModifyAddress,
}: AddressUpsertBoxProps) => {
  const { addAddress, salutations, availableCountries } =
    useContext(shopContext)

  const { t } = useTranslation('common')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    await addAddress(data)

    setModifyAddress(undefined)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="height-auto py-8">
      <div className="flex flex-col space-y-8">
        <div className="relative inline-block w-full text-gray-700">
          <input
            type={'hidden'}
            name={`id`}
            value={address?.id}
            {...register('id')}
          />
          <select
            {...register('salutationId', { required: true })}
            className="focus:shadow-outline h-10 w-full appearance-none border pl-3 pr-6 text-base placeholder:text-gray-600"
          >
            {salutations &&
              salutations.length > 0 &&
              salutations.map((s) => (
                <option selected={address?.salutationId === s.id} value={s.id}>
                  {s.displayName}
                </option>
              ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
              <path
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <CustomInputField
          register={register}
          label={t('COMPANY')}
          name={'company'}
          defaultValue={address?.company}
        />
        <CustomInputField
          register={register}
          label={t('FIRSTNAME')}
          name={'firstName'}
          defaultValue={address?.firstName}
          required={true}
        />
        <CustomInputField
          register={register}
          label={t('LASTNAME')}
          name={'lastName'}
          defaultValue={address?.lastName}
          required={true}
        />

        <CustomInputField
          register={register}
          label={t('STREET')}
          name={'street'}
          defaultValue={address?.street}
          required={true}
        />
        <FormControl fullWidth className={`col-span-2`} variant="standard">
          <InputLabel id="salutation">{t('COUNTRY')}</InputLabel>
          <Select
            labelId="salutation-label"
            id="salutation-select"
            label={t('COUNTRY')}
            required={true}
            defaultValue={
              address.countryId ??
              availableCountries?.find(
                (x) => x.iso3 === process.env.NEXT_PUBLIC_DEFAULT_COUNTRY
              )?.id
            }
            {...register('countryId', { required: true })}
          >
            {availableCountries &&
              availableCountries.length > 0 &&
              availableCountries
                .sort((x) => x.position)
                .map((s) => <MenuItem value={s.id}>{s.name}</MenuItem>)}
          </Select>
        </FormControl>
        <CustomInputField
          register={register}
          label={t('POSTAL')}
          name={'zipcode'}
          defaultValue={address?.zipcode}
          required={true}
        />
        <CustomInputField
          register={register}
          label={t('CITY')}
          name={'city'}
          defaultValue={address?.city}
          required={true}
        />
        <div className="w-full items-center sm:inline-flex">
          <div className="mr-auto">
            <button
              className="w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white"
              type="submit"
            >
              {t('SAVE_ADDRESS')}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
