import { Box, Modal } from '@mui/material'
import { SxProps } from '@mui/system'
import { PasswordChange } from 'components/account/password-change'
import useBreakpoints from 'hooks/use-breakpoints'
import Link from 'next-translate-routes/link'
import { useTranslation } from 'next-i18next'
import React, { useContext, useState } from 'react'

import { shopContext } from '../../providers/shop-context'
import { BasketOverview } from '../checkout/basket-overview'
import { OpenInvoices } from '../checkout/open-invoices'
import { EmailChange } from './email-change'
import { PersonalInformation } from './personal-information'

export const Account = () => {
  const { cartInformation } = useContext(shopContext)

  const { t } = useTranslation('common')

  const { isXs } = useBreakpoints()

  const [showChange, setShowChange] = useState(false)

  const [showChangeMail, setShowChangeMail] = useState(false)

  const style = isXs
    ? ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      } as SxProps)
    : ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '33%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      } as SxProps)

  return (
    <>
      <p className="pb-8 font-swDisp text-4xl font-bold italic">
        {t('YOUR_ACCOUNT')}
      </p>
      <OpenInvoices />
      <div className="flex flex-row flex-wrap">
        <div className="w-full ">
          <PersonalInformation />
          <button
            className="w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white sm:w-64"
            type="button"
            onClick={() => setShowChange(!showChange)}
          >
            {t('CHANGE_PASSWORD')}
          </button>
          <button
            className="my-4 w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white sm:my-0 sm:mx-4 sm:w-64"
            type="button"
            onClick={() => setShowChangeMail(!showChangeMail)}
          >
            {t('CHANGE_MAIL')}
          </button>
        </div>
        <div className={`w-full pt-4 pb-8`}>
          <BasketOverview />
          {cartInformation && cartInformation.lineItems.length > 0 && (
            <Link href={`/warenkorb`}>
              <a
                className={` w-full py-2 font-bold text-white inline-block border border-black text-center bg-9 hover:bg-black hover:text-white font-swDisp`}
              >
                {t('GOTO_BASKET')}
              </a>
            </Link>
          )}
        </div>
        <Modal open={showChange}>
          <Box sx={style}>
            <PasswordChange
              showLogin={showChange}
              setShowLogin={setShowChange}
            />
          </Box>
        </Modal>
        <Modal open={showChangeMail}>
          <Box sx={style}>
            <EmailChange
              showLogin={showChangeMail}
              setShowLogin={setShowChangeMail}
            />
          </Box>
        </Modal>
      </div>
    </>
  )
}
