import _ from 'lodash'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import React, { useContext } from 'react'

import { IProductCompare } from '../../models/landingpage'
import { ReviewMiniView } from '../../pages/produkt/[slug]'
import { shopContext } from '../../providers/shop-context'
import CtaButtons from '../components/cta-buttons'
import { ProductPrice } from '../components/product-box'
import { PictureTag } from '../helper/picture-tag'

interface ProductCompareProps {
  data: IProductCompare
}

const ProductCompare = ({ data }: ProductCompareProps) => {
  const { t } = useTranslation('common')
  const rowDefinition = _.groupBy(data?.TableStructure, (x) => x.group)
  const { getIdForShopwareProduct, shopwareProducts } = useContext(shopContext)
  let counter = 0
  return (
    <>
      <div className={`flex p-4`}>
        <div className={`z-0 container mx-auto `}>
          <h2
            className={`pt-4 pb-8
             text-xl italic font-bold font-swDisp `}
          >
            {data.Headline}
          </h2>
          <div className={`flex w-full compare`}>
            <table>
              <tr>
                <th className={`align-top text-left headcol`}>
                  <h3
                    className={`pt-4 pb-8
             text-xl italic font-bold font-swDisp `}
                  >
                    {data.ColumnHeadline}
                  </h3>
                  <p>{data.ColumnIntroText}</p>
                </th>
                {data?.TableContent?.map(({ product, headline, image }) => {
                  return (
                    <td className={`min-w-[9rem]`}>
                      {product && (
                        <Link href={`/produkt/${product.Slug}`}>
                          <a>
                            <PictureTag
                              imageUrl={
                                (product?.DosenBild &&
                                  product?.DosenBild.fullpath) ??
                                (product.Images &&
                                  product?.Images[0]?.image.fullpath) ??
                                '/sonstiges/failover.png'
                              }
                              sizesVW={'200'}
                              className={`w-full sm:w-9/12 mx-auto p-4`}
                              altText={
                                product?.DosenBild?.alt ?? product.ArticleName
                              }
                            />
                          </a>
                        </Link>
                      )}
                      {image && (
                        <PictureTag
                          imageUrl={
                            image.image.fullpath ?? '/sonstiges/failover.png'
                          }
                          sizesVW={'200'}
                          className={`w-full sm:w-9/12 mx-auto p-4`}
                          altText={headline}
                        />
                      )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className={`align-middle text-left  headcol`}></th>
                {data?.TableContent?.map(({ product, headline }) => {
                  return (
                    <td className={`text-center`}>
                      {product && (
                        <>
                          <Link href={`/produkt/${product.Slug}`}>
                            <a>
                              <p
                                className={`relative text-center sm:text-center mx-auto text-sm sm:text-base italic font-bold max-w-full
                        } font-swDisp dark:text-white h-20 sm:h-auto`}
                              >
                                {product.ArticleName}
                              </p>
                            </a>
                          </Link>
                          <ReviewMiniView data={product} />
                        </>
                      )}
                      {headline && (
                        <p
                          className={`relative text-center sm:text-center mx-auto text-sm sm:text-base italic font-bold max-w-full
                        } font-swDisp dark:text-white h-20 sm:h-auto`}
                        >
                          {headline}
                        </p>
                      )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                {data?.TableContent?.filter((content) => content.product)
                  .length > 0 && (
                  <th className={`align-middle text-left  headcol`}>
                    <h3 className={`py-4 text-xl font-bold italic`}>
                      {t('PRICE')}
                    </h3>
                  </th>
                )}
                {data?.TableContent?.map(({ product }) => {
                  return (
                    <td className={`align-middle text-center`}>
                      <ProductPrice
                        shopwareProduct={getIdForShopwareProduct(product)}
                      />
                    </td>
                  )
                })}
              </tr>

              {rowDefinition &&
                Object.keys(rowDefinition).map((key) => {
                  return (
                    <>
                      {key != '' && (
                        <tr>
                          <th className={`headcol`}>
                            <h3
                              className={`py-4
             text-xl italic font-bold font-swDisp `}
                            >
                              {key}
                            </h3>
                          </th>
                        </tr>
                      )}
                      {rowDefinition[key].map((row) => {
                        counter += 1
                        return (
                          <tr>
                            <th className={`headcol`}>
                              <p className={`py-4`}>{row.title}</p>
                            </th>
                            {data?.TableContent?.map(({ tableValues }) => {
                              return (
                                <td className={`align-middle text-center`}>
                                  {JSON.parse(
                                    `"${tableValues

                                      .split(',')
                                      [counter - 1].replaceAll('[', '')
                                      .replaceAll(']', '')
                                      .replaceAll('"', '')}"`
                                  )}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </>
                  )
                })}

              <tr>
                {data?.TableContent?.filter((content) => content.product)
                  .length > 0 && (
                  <th className={`align-middle text-left  headcol`}></th>
                )}
                {data?.TableContent?.map(({ product }) => {
                  return (
                    <td className={`text-center`}>
                      {product && (
                        <div className={`flex justify-center w-3/4 mx-auto`}>
                          <CtaButtons
                            data={product}
                            isSmall={true}
                            rawData={product}
                            shopwareProduct={getIdForShopwareProduct(product)}
                          />
                        </div>
                      )}
                    </td>
                  )
                })}
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCompare
