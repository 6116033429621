import axios from 'axios'
import { NextSeo } from 'next-seo'
import React, { useEffect, useState } from 'react'

export const RefLang = ({ router }) => {
  const [seoInfo, setSeoInfo] = useState(undefined)

  useEffect(() => {
    axios
      .get(
        `https://pim.stayspiced.com/ecom/languagepaths?locale=${router.locale}&slug=${router.asPath}`
      )
      .then((data) => {
        setSeoInfo(data.data)
      })
  }, [router])

  return (
    <>
      {seoInfo && (
        <NextSeo
          languageAlternates={Object.keys(seoInfo.slugs)
            .map((k) => {
              return {
                hrefLang: k,
                href: seoInfo.slugs[k],
              }
            })}
        />
      )}
    </>
  )
}
