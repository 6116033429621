import { trim } from 'lodash'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { searchContext } from '../../context/search-context'
import useDebounce from '../../hooks/use-debounce'

export const SearchBar = () => {
  const { searchQuery, setSearchQuery, items, setHasFocus, textSuggestions } =
    useContext(searchContext)

  const [localSearch, setLocalSearch] = useState('')

  const debouncedSearch = useDebounce(localSearch, 500)

  useEffect(() => {
    if (debouncedSearch === '') {
      setHasFocus(false)
    }
    setSearchQuery(debouncedSearch)
  }, [debouncedSearch])

  useEffect(() => {
    setLocalSearch(searchQuery)
  }, [])

  const router = useRouter()

  const inputBar = useRef<HTMLInputElement>()

  function checkIfEnterWasPressed(value: any) {
    setHasFocus(true)

    if (
      value.keyCode === 13 &&
      localSearch &&
      localSearch.length > 0 &&
      trim(localSearch) !== ''
    ) {
      setHasFocus(false)

      router.push(`/suche/${localSearch}`)
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <div className="fixed z-200 mx-auto w-full text-gray-600 sm:relative  sm:w-6/12">
        <input
          className="h-10 w-full rounded-none border-xl border-gray-300 bg-white px-5 pr-16  text-sm focus:outline-none"
          type="search"
          placeholder={t('SEARCH')}
          defaultValue={searchQuery}
          ref={inputBar}
          onFocus={() => setHasFocus(true)}
          onChange={(e) => setLocalSearch(e.target.value)}
          onKeyUp={(e) => checkIfEnterWasPressed(e)}
        />
        <div className="absolute right-0 top-0 mt-3 mr-4">
          <svg
            className="h-4 w-4 fill-current text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 56.966 56.966"
            width="512px"
            height="512px"
            onClick={() => setHasFocus(false)}
          >
            <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
        </div>
      </div>
    </>
  )
}
