import { accountContext, useAccountContext } from "context/account-context"
import { languageContext, useLanguageContext } from "context/language-context"
import { pageContext, usePage } from "context/page-context"
import Head from "next/head"
import Script from "next/script"
import { useRouter } from "next-translate-routes/router"
import React, { useEffect } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"

import { shopContext, useShopContext } from "../providers/shop-context"
import { Loading } from "./components/loading"
import { Footer } from "./layout/footer"
import { Header } from "./layout/header"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
})

// eslint-disable-next-line react/prop-types
export default function Layout({ children }) {
  const page = usePage()

  const shop = useShopContext()

  const account = useAccountContext()

  const language = useLanguageContext()

  const router = useRouter()

  const [isCanonical, setIsCanonical] = React.useState(false)

  useEffect(() => {
    if (router.locale === process.env.NEXT_PUBLIC_LANGUAGE) {
      setIsCanonical(true)
    }
  }, [router])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <pageContext.Provider value={page}>
          <shopContext.Provider value={shop}>
            <languageContext.Provider value={language}>
              <accountContext.Provider value={account}>
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                  />
                  {isCanonical && (
                    <link
                      rel="canonical"
                      href={`${process.env.NEXT_PUBLIC_FRONTEND}${router.asPath}`}
                    />
                  )}
                  {isCanonical && (
                    <link
                      rel="canonical"
                      href={`${process.env.NEXT_PUBLIC_FRONTEND}/${router.locale}${router.asPath}`}
                    />
                  )}
                  <link
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    rel="stylesheet"
                  />
                </Head>
                <Script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDYSlC5gDUpeNSfGUxCbwzyH3Ng0SU3w7k&libraries=places&callback="></Script>
                {/* <Script */}
                {/*  type="text/javascript" */}
                {/*  src="https://app.uptain.de/js/uptain.js?x=cxQNU6h4RsHXQvB4" */}
                {/*  async */}
                {/* ></Script> */}
                {/* <Script src="//pay.google.com/gp/p/js/pay.js"></Script> */}
                <Header />
                <Loading />
                <ToastContainer />
                <div className="mx-auto sm:pt-4">{children}</div>
                <Footer />
              </accountContext.Provider>
            </languageContext.Provider>
          </shopContext.Provider>
        </pageContext.Provider>
      </QueryClientProvider>
    </>
  )
}
