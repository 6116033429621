import { faStar, faStarHalf } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import Slider from 'react-slick'

import { SampleNextArrow, SamplePrevArrow } from '../slider/endless-slider'

interface RatingBoxProps {
  data: any
}

const SummaryBox = () => {
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    axios
      .get(`https://pim.stayspiced.com/spiceworld/reviewOverview`)
      .then((res) => {
        const trustedData = res.data
        console.log(trustedData)
        setData({
          rating: trustedData.rating,
          title: 'Sehr gut',
          count: trustedData.count,
        })
      })
  }, [])

  if (!data) {
    return <></>
  }
  return (
    <div
      className={`border-xl flex flex-col h-52 mx-4 p-2  flex-col border-gray-400 rounded-2xl shadow-md m-2`}
    >
      <div className={`flex flex-row items-center text-center`}>
        <p className={`w-full`}>
          {Array.from({ length: 5 }, (v, i) => {
            if (i + 1 <= Math.floor(data.rating)) {
              return (
                <FontAwesomeIcon
                  className="w-8 text-2xl text-gold"
                  icon={faStar}
                />
              )
            }
            if (data.rating % 1 !== 0 && i + 1 === Math.ceil(data.rating)) {
              if (data.rating % 1 > 0.5) {
                return (
                  <FontAwesomeIcon
                    className="w-8 text-2xl text-gold"
                    icon={faStar}
                  />
                )
              }
              return (
                <FontAwesomeIcon
                  className="w-8 text-2xl text-gold"
                  icon={faStarHalf}
                />
              )
            }
            return (
              <FontAwesomeIcon
                className="w-8 text-2xl text-gray-200"
                icon={faStar}
              />
            )
          })}
        </p>
      </div>
      <p className={`text-lg font-bold text-center pt-6`}>
        {data.rating} ({data.count})
      </p>
      <p className={`text-lg font-bold text-center `}>{data.title}</p>
      <img src={`/images/trusted.png`} className={`w-12 pt-4 mx-auto`} />
    </div>
  )
}

export const SummaryBoxFooter = () => {
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    axios
      .get(`https://pim.stayspiced.com/spiceworld/reviewOverview`)
      .then((res) => {
        const trustedData = res.data
        console.log(trustedData)
        setData({
          rating: trustedData.rating,
          title: 'Sehr gut',
          count: trustedData.count,
        })
      })
  }, [])

  if (!data) {
    return <></>
  }
  return (
    <div
      className={`mt-4 flex justify-center items-center p-2  border-gray-400  bg-white`}
    >
      <img src={`/images/trusted.png`} className={`w-12 mr-auto`} />
      <div className={`flex flex-row items-center text-center mx-auto`}>
        <p className={`w-full`}>
          {Array.from({ length: 5 }, (v, i) => {
            if (i + 1 <= Math.floor(data.rating)) {
              return (
                <FontAwesomeIcon
                  className="w-6 sm:w-8 text-2xl text-gold"
                  icon={faStar}
                />
              )
            }
            if (data.rating % 1 !== 0 && i + 1 === Math.ceil(data.rating)) {
              if (data.rating % 1 > 0.5) {
                return (
                  <FontAwesomeIcon
                    className="w-6 sm:w-8 text-2xl text-gold"
                    icon={faStar}
                  />
                )
              }
              return (
                <FontAwesomeIcon
                  className="w-6 sm:w-8 text-2xl text-gold"
                  icon={faStarHalf}
                />
              )
            }
            return (
              <FontAwesomeIcon
                className="w-6 sm:w-8 text-2xl text-gray-200"
                icon={faStar}
              />
            )
          })}
        </p>
      </div>
      <div className={`flex-row flex justify-center items-center`}>
        <p className={`text-lg font-bold text-center px-2`}>{data.rating}</p>
        <p className={`text-base sm:text-lg font-bold text-center `}>
          {data.title}
        </p>
      </div>
    </div>
  )
}

const RatingBox = ({ data }: RatingBoxProps) => {
  return (
    <div
      className={`border-xl flex flex-col h-52 mx-4 p-2  flex-col border-gray-400 rounded-2xl shadow-md m-2`}
    >
      <div className={`flex flex-row items-center`}>
        <p>
          {Array.from({ length: 5 }, (v, i) => {
            if (i + 1 <= Math.floor(data.rating)) {
              return (
                <FontAwesomeIcon
                  className="w-4 text-base text-gold"
                  icon={faStar}
                />
              )
            }
            if (data.rating % 1 !== 0 && i + 1 === Math.ceil(data.rating)) {
              if (data.rating % 1 > 0.5) {
                return (
                  <FontAwesomeIcon
                    className="w-4 text-base text-gold"
                    icon={faStar}
                  />
                )
              }
              return (
                <FontAwesomeIcon
                  className="w-4 text-base text-gold"
                  icon={faStarHalf}
                />
              )
            }
            return (
              <FontAwesomeIcon
                className="w-4 text-base text-gray-200"
                icon={faStar}
              />
            )
          })}
        </p>
      </div>
      <p className={``}>
        {data.date} von {data.name || 'Anonym'}
      </p>
      <p className={`text-lg font-bold`}>{data.title}</p>
      {data?.text && (
        <p
          className={`text-base  pt-2 text-truncate h-26`}
          dangerouslySetInnerHTML={{ __html: data.text.substring(0, 150) }}
        />
      )}
    </div>
  )
}

const TrustedShopsWrapper = () => {
  const [reviews, setReviews] = useState(undefined)

  useMemo(() => {
    if (reviews === undefined || reviews.length === 0)
      axios
        .get(`https://pim.stayspiced.com/spiceworld/reviews`)
        .then((response) => {
          setReviews(response.data)
        })
        .catch((error) => console.log(error))
  }, [])

  const itemcount = 3

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: itemcount,
    slidesToScroll: itemcount,
    infinite: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  }

  return (
    <div className={`container mx-auto min-h-[20rem]`}>
      <div className={`grid grid-cols-12`}>
        <div className={`col-span-12 sm:col-span-2`}>
          <SummaryBox />
        </div>
        <div className={`col-span-12 sm:col-span-10`}>
          {reviews && reviews.ratings && (
            <Slider {...settings}>
              {reviews.ratings.map((rating) => (
                <RatingBox key={rating.id} data={rating} />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(TrustedShopsWrapper)
