import { PictureTag } from 'components/helper/picture-tag'
import { IProduct } from 'models/product'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect, useState } from 'react'

import IconHeart from '../../icons/icon-heart'
import { shopContext } from '../../providers/shop-context'
import { ProductPrice } from './product-box'

export interface FindologicBoxProps {
  data: any
  bgColor?: string
  showCta?: boolean
  showPrize?: boolean
  center?: boolean
  imageSize?: string
  sizeVW?: string
  className?: string
}

export const FindologicBox = ({
  data,
  center,
  sizeVW,
  className,
  bgColor,
  showPrize,
  showCta,
}: FindologicBoxProps) => {
  const modifiedData = {
    ...data,
  }

  const { addToBasket, getProductInfoByShopwareId, shopwareProducts } =
    useContext(shopContext)
  const { t } = useTranslation()

  // OSProductNumber
  const [shopwareProduct, setShopwareProduct] = useState<any>()

  useEffect(() => {
    if (shopwareProducts)
      setShopwareProduct(
        getProductInfoByShopwareId(`${data.document.id}m`) ??
          getProductInfoByShopwareId(`${data.document.id}`)
      )
  }, [data, shopwareProducts])

  const router = useRouter()

  const languageResposnse = data.document._i18n[router.locale]

  return (
    <>
      {data && modifiedData && (
        <>
          <div
            className={
              className &&
              `h-full flex flex-col h-72 sm:h-auto flex-grow justify-content items-center content-center p-2 hover:shadow-lg`
            }
          >
            <Link
              href={`${
                data?.document?.type === 'recipe' ? '/rezept/' : '/produkt/'
              }${languageResposnse.slug}`}
            >
              <div
                className={`mx-auto flex mb-auto flex-grow cursor-pointer bg-opacity-70 relative ${
                  bgColor ? `bg-${bgColor}` : 'bg-grey'
                } dark:bg-transparent`}
              >
                <div>
                  <PictureTag
                    imageUrl={
                      data?.document?.image ??
                      data?.document._product.images['1'] ??
                      '/sonstiges/failover.png'
                    }
                    sizesVW={sizeVW}
                    className={`w-full sm:w-9/12 mx-auto p-4`}
                  />
                </div>
                <div className="absolute top-2 right-4 ml-auto h-2 w-2 hover:text-9 sm:top-4 sm:right-8">
                  <IconHeart width={20} className={`fill-current`} />
                </div>
              </div>
            </Link>
            <div className="mt-auto w-full pt-4">
              <Link href={`/produkt/${languageResposnse.slug}`}>
                <a>
                  <p
                    className={`relative sm:mx-auto text-xs sm:text-xs italic font-bold max-w-full ${
                      center ? 'sm:text-center' : ''
                    } font-swDisp dark:text-white h-8 sm:h-12 `}
                    dangerouslySetInnerHTML={{
                      __html: languageResposnse.name,
                    }}
                  />
                </a>
              </Link>
              {showPrize && (
                <>
                  <div className={`sm:mx-auto sm:text-center`}>
                    <ProductPrice shopwareProduct={shopwareProduct} />
                  </div>
                  {(data.attributes as any)?.swId?.length > 0 && showCta && (
                    <div className={`flex gap-2 w-full relative`}>
                      <button
                        type="button"
                        className={`${className} w-full py-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp`}
                        onClick={() =>
                          addToBasket(
                            (data.attributes as any).swId[0],
                            {
                              OSProductNumber: data.id,
                              ArticleName: data.name,
                              Images: [
                                {
                                  image: {
                                    fullpath: data.imageUrl,
                                  },
                                },
                              ],
                            } as IProduct,
                            1
                          )
                        }
                      >
                        {t('TO_BASKET')}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

FindologicBox.defaultProps = {
  bgColor: 'grey',
  showCta: true,
  imageSize: 'w-2/3',
  showPrize: true,
  center: true,
  sizeVW: '10',
  className: 'z-0',
}
