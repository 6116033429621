import 'react-step-progress-bar/styles.css'

import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import _ from 'lodash'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect } from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar'

import { formatter } from '../../helper/currency-formatter'
import { shopContext } from '../../providers/shop-context'
import BasketPromotion from '../basket-promotion/basket-promotion'
import { ShopwareLineItem } from '../shopware/shopware-line-item'
import { BasketSum } from './basket-sum'

export const StepDetail = ({ step, currentCartSum }) => {
  return (
    <div className={`flex`}>
      {step.sumToReach > currentCartSum ? (
        <span className={`text-xs sm:text-lg font-swDisp p-2`}>
          {step.title}{' '}
          <span className={`font-bold`}>
            {' '}
            ab {formatter.format(step.sumToReach)}
          </span>
        </span>
      ) : null}
      {step.sumToReach < currentCartSum ? (
        <span
          className={`text-xs sm:text-lg justify-center flex items-center font-bold`}
        >
          {' '}
          <FontAwesomeIcon
            className="w-4 p-2 font-lg text-12 font-bold"
            icon={faCheckCircle}
          />
          {step.title}
        </span>
      ) : null}
    </div>
  )
}

export const BasketGamification = ({ cartInformation }) => {
  const [currentLevel, setCurrentLevel] = React.useState(0)
  const [headline, setHeadline] = React.useState('')
  const [steps, setSteps] = React.useState([])
  const [maxLevel, setMaxLevel] = React.useState(100)
  const [maxSum, setMaxSum] = React.useState(100)
  const [factor, setFactor] = React.useState(1)
  const [stepPositions, setStepPositions] = React.useState([])
  const router = useRouter()

  const { t } = useTranslation('common')
  useEffect(() => {
    if (router) {
      axios
        .get(
          `https://pim.stayspiced.com/spiceworld/gamification/${router.locale}`
        )
        .then((data) => {
          const factor =
            100 / (data.data.steps[data.data.steps.length - 1]?.sumToReach + 20)
          const newStepPositions = []
          data.data.steps.forEach((step) => {
            newStepPositions.push(step.sumToReach * factor)
          })
          setStepPositions([...newStepPositions, 100])
          setHeadline(data.data.headline)
          setSteps(_.orderBy(data.data.steps, (x) => x.sumToReach, 'asc'))
          setMaxLevel(
            data.data.steps[data.data.steps.length - 1]?.sumToReach + 20
          )
          setMaxSum(data.data.steps[data.data.steps.length - 1]?.sumToReach)
          setFactor(factor)
        })
    }
  }, [router])

  React.useEffect(() => {
    if (cartInformation) {
      setCurrentLevel(
        maxLevel - cartInformation?.price?.positionPrice < 0
          ? 100
          : 100 - (maxLevel - cartInformation?.price?.positionPrice) * factor
      )
    }
  }, [cartInformation?.price?.positionPrice, factor])

  React.useEffect(() => {}, [factor])

  return (
    <>
      {cartInformation?.price?.positionPrice < maxSum && (
        <h2 className="font-swDisp text-base sm:text-xl font-bold text-center mx-auto italic">
          {t('ONLY')}{' '}
          <span className={`text-12`}>
            {' '}
            {formatter.format(
              steps?.find(
                (step) =>
                  step.sumToReach > cartInformation?.price?.positionPrice
              )?.sumToReach - cartInformation?.price?.positionPrice
            )}{' '}
          </span>
          {t('TO_GAMIFICATION')}{' '}
          {
            steps.find(
              (step) => step.sumToReach > cartInformation?.price?.positionPrice
            )?.title
          }
        </h2>
      )}
      {cartInformation?.price?.positionPrice >= maxSum && (
        <h2 className="font-swDisp text-base sm:text-xl font-bold text-center mx-auto italic">
          {t('GAMIFICATION_LEVEL_REACHED')}
        </h2>
      )}
      <div className={` pt-6  block relative`}>
        {stepPositions.length === steps.length + 1 && (
          <ProgressBar
            percent={currentLevel}
            filledBackground={`#95C11F`}
            hasStepZero={false}
            stepPositions={stepPositions}
          >
            {steps.map((step) => (
              <Step transition="scale" position={step.sumToReach * factor}>
                {({ accomplished }) => (
                  <div className={`pb-10`}>
                    <div className={`pt-8 text-xxl font-bold text-center`}>
                      |
                    </div>
                  </div>
                )}
              </Step>
            ))}
            <Step transition="scale" position={100}>
              {({ accomplished }) => <></>}
            </Step>
          </ProgressBar>
        )}

        <div className={`grid grid-rows-2 grid-flow-col pt-4`}>
          {steps.map((step) => (
            <StepDetail
              step={step}
              currentCartSum={cartInformation?.price?.positionPrice}
            />
          ))}
        </div>
      </div>
      <p className={`text-xs font-swDisp p-2 pt-4 pb-12`}>
        {t('GAMIFICATION_NOTE')}
      </p>
    </>
  )
}

export const BasketGamificationMemo = React.memo(BasketGamification)

export const BasketOverview = () => {
  const { cartInformation, userInformation } = useContext(shopContext)

  const { t } = useTranslation('common')

  useEffect(() => {
    console.log(userInformation)
  }, [userInformation])

  return (
    <>
      {cartInformation &&
        cartInformation.lineItems &&
        cartInformation.lineItems.length > 0 && (
          <>
            <h1 className="pb-4 font-swDisp text-3xl font-bold  italic">
              {t('BASKET')} {/* Gamification */}
              <span className={`sm:pl-4 font-medium not-italic text-base`}>
                ({cartInformation?.lineItems?.length ?? 0} {t('PRODUCTS')})
              </span>
            </h1>
            {(userInformation === null ||
              userInformation?.groupId ===
                'cfbd5018d38d41d8adca10d94fc8bdd6') && (
              <BasketGamificationMemo cartInformation={cartInformation} />
            )}
            <div className="flex flex-col  items-stretch  divide-y divide-solid divide-black pb-2">
              {cartInformation &&
                cartInformation.lineItems &&
                cartInformation.lineItems.length > 0 &&
                cartInformation.lineItems.map((lineItem) => (
                  <ShopwareLineItem lineItem={lineItem} key={lineItem.id} />
                ))}
            </div>
            <BasketPromotion />
            <BasketSum />
          </>
        )}
    </>
  )
}
