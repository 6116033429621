import { SideMenu } from 'components/account/side-menu'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect, useState } from 'react'

import { shopContext } from '../../providers/shop-context'
import { UrlBuilder } from '../helper/picture-tag'
import { HeaderMenuRootMobile } from './header-menu-root-mobile'
import { HeaderRootDesktopProps } from './header-root-desktop'

export const HeaderRootMobile = ({ data }: HeaderRootDesktopProps) => {
  const { showMenu, isLoggedIn, setShowMenu } = useContext(shopContext)

  const [level, setLevel] = useState(0)

  const [id, setId] = useState(0)

  const [subId, setSubId] = useState(0)

  const router = useRouter()

  useEffect(() => {
    setShowMenu(false)
  }, [router.asPath])

  const { t } = useTranslation('common')

  return (
    <div
      className={`${
        showMenu ? 'w-full' : 'w-0'
      } h-auto transition-all fixed z-100 bg-white bg-opacity-50`}
    >
      <div
        className={`${
          showMenu ? 'w-11/12' : 'w-0'
        } h-screen transition-all relative z-100  bg-white flex flex-col`}
      >
        {showMenu && (
          <>
            <ul className={`transition-all`}>
              {data.children.map((entry) => (
                <>
                  {level === 0 && (
                    <li className={`py-4 pl-8 border-b border-gray-500`}>
                      <button
                        onClick={() => {
                          setLevel(level + 1)

                          setId(entry.id)
                        }}
                        className="relative block  font-swDisp text-base font-bold lg:text-xl"
                      >
                        {entry.Name}
                      </button>
                    </li>
                  )}
                  <ul className="">
                    {entry.id === id && level === 1 && (
                      <li className={`py-4 pl-8 border-b border-gray-500`}>
                        <button onClick={() => setLevel(level - 1)}>
                          Zurück
                        </button>
                      </li>
                    )}
                    {entry &&
                      entry.id === id &&
                      level === 1 &&
                      entry.children &&
                      entry.children.map((child: any) => {
                        return (
                          <li
                            className="flex cursor-pointer items-center justify-center border-b border-gray-500 pl-4 align-middle"
                            onClick={() => {
                              setLevel(level + 1)

                              setSubId(child.id)
                            }}
                          >
                            {child.HeroImage && (
                              <img
                                loading="lazy"
                                src={UrlBuilder(
                                  child.HeroImage.fullpath,
                                  400,
                                  'png',
                                  false
                                )}
                                className="w-3/12  py-4"
                              />
                            )}
                            <h3 className={`pl-4 w-10/12`}>{child.Name}</h3>
                          </li>
                        )
                      })}
                  </ul>
                  <ul className="">
                    {entry.id === id && level === 2 && (
                      <li className={`py-4 pl-8 border-b border-gray-500`}>
                        <button onClick={() => setLevel(level - 1)}>
                          Zurück
                        </button>
                      </li>
                    )}
                    {level === 2 && entry.id === id && (
                      <HeaderMenuRootMobile
                        data={entry.children.find((x) => x.id === subId)}
                        stepUpLevel={() => setLevel(level - 1)}
                      />
                    )}
                  </ul>
                </>
              ))}
            </ul>
            <div className="mt-auto mb-20">
              {level === 0 && isLoggedIn && <SideMenu />}
              {level === 0 && !isLoggedIn && (
                <div className="relative px-8 pb-48 sm:px-0 sm:pb-24">
                  <div
                    className={` sm:pt-4 inline sm:block relative flex flex-col space-y-4`}
                  >
                    <Link href={`/geschichte`}>
                      <p
                        className={`font-swDisp text-sm italic  hover:underline cursor-pointer`}
                      >
                        <img
                          className={`inline px-2`}
                          src={`/icons/Regional.png`}
                        />
                        {t('REGIONAL')}
                      </p>
                    </Link>

                    <Link href={`/qualitaet-der-reingewuerze`}>
                      <p
                        className={`font-swDisp text-sm italic hover:underline  cursor-pointer`}
                      >
                        <img
                          className={`inline px-2`}
                          src={`/icons/NoAdditional.png`}
                        />
                        {t('NO_ADDITIONAL')}
                      </p>
                    </Link>
                    <p
                      className={`font-swDisp text-sm italic  hover:underline`}
                    >
                      <a
                        href={`mailto:support@stayspiced.com`}
                        className={`hover:underline`}
                      >
                        <img
                          className={`inline px-2`}
                          src={`/icons/Mail.png`}
                        />
                        {t('MAIL_CONTACT')}
                      </a>
                    </p>
                    <p className={`font-swDisp text-sm italic`}>
                      <a
                        href={`tel:+43662890919`}
                        className={`hover:underline`}
                      >
                        <img
                          className={`inline px-2`}
                          src={`/icons/Phone.png`}
                        />
                        {t('TELEFON_CONTACT')}
                      </a>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
