import axios from 'axios'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'

import useBreakpoints from '../../hooks/use-breakpoints'
import { RecommendationBox } from './recommendation-box'

interface ProductRecommendationsProps {
  data: string
}

export const ProductRecommendations = ({
  data,
}: ProductRecommendationsProps) => {
  const breakpoints = useBreakpoints()
  const itemcount = 3
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: itemcount,
    slidesToScroll: itemcount,
    infinite: false,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  }

  const [rawRecommendation, setRawRecommendation] =
    useState<string[]>(undefined)
  const { t } = useTranslation('common')

  function refreshRecommendations() {
    axios
      .get(`/api/item?productNumber=${data}`)
      .then((recoResponse) => setRawRecommendation(recoResponse.data))
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (data) {
      // fetch the current recommendations from the API
      refreshRecommendations()
    }
  }, [data])

  useEffect(() => {
    if (rawRecommendation && rawRecommendation.length > 0) {
      // we have recos
      console.log(rawRecommendation)
    }
  }, [rawRecommendation])
  return (
    <div className={`w-full  max-w-full`}>
      <div className={`z-0 container mx-auto `}>
        <h2
          className={`pt-4 pb-8
            text-xl
           italic font-bold font-swDisp text-center`}
        >
          {t('PDP_RECOMMENDATIONS')}
        </h2>
        <div className="z-0 w-full">
          {rawRecommendation && rawRecommendation.length > 0 && (
            <Slider {...settings}>
              {rawRecommendation &&
                rawRecommendation.length > 0 &&
                rawRecommendation.map((r) => (
                  <RecommendationBox key={r} productNumber={r} />
                ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductRecommendations
