import { TextareaAutosize } from '@mui/base'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import axios from 'axios'
import { useTranslation } from 'next-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { IFormular } from '../../models/landingpage'
import { shopContext } from '../../providers/shop-context'
import { CustomInputField } from '../account/address-upsert-box'

export const FormularWrapper = ({
  Headline,
  Description,
  Formular,
}: IFormular) => {
  const [formularData, setFormularData] = useState<any>(undefined)

  useEffect(() => {
    axios.get(`https://pim.stayspiced.com/form/${Formular}`).then((data) => {
      console.log(data.data)
      setFormularData(data.data)
    })
  }, [])

  const { pageRegister, salutations, availableCountries } =
    useContext(shopContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [formSent, setFormSent] = useState<boolean>(false)
  const [formError, setFormError] = useState<boolean>(false)

  const onSubmit = async (data) => {
    setFormSent(false)
    setFormError(false)
    const response = await axios.post(`/api/zapier/send`, {
      url: formularData?.value?.formDefinition?.configuration?.action,
      formData: data,
    })
    if (response.status === 200) {
      setFormSent(true)
    } else {
      setFormError(true)
    }
  }

  useEffect(() => {
    console.log(errors)
  }, [errors])

  const { t } = useTranslation('common')

  return (
    <div className="relative py-4 mx-auto  container">
      <div className={`w-full sm:w-3/4 mx-auto px-4`}>
        <h3 className="font-swDisp text-3xl pt-4 font-bold italic dark:text-white mx-auto  sm:p-0 w-full">
          {Headline}
        </h3>
        <p>{Description}</p>
        <div className={`w-full `}>
          {formSent === false && (
            <form onSubmit={handleSubmit(onSubmit)} className="pb-8">
              {formularData &&
                formularData?.value?.formDefinition?.fields &&
                Object.keys(formularData?.value?.formDefinition?.fields).map(
                  (ff) => {
                    return (
                      <>
                        <p className={`font-swDisp text-lg pt-4 font-bold`}>
                          {ff.replaceAll(`_`, ` `)}
                        </p>
                        <div className="grid grid-cols-2 gap-4">
                          {formularData?.value?.formDefinition?.fields[
                            ff
                          ].fields.map((field) => {
                            switch (field.type) {
                              case 'text':
                                return (
                                  <CustomInputField
                                    label={field.displayName}
                                    name={field.name}
                                    type={field.type}
                                    required={field.constraints?.length > 0}
                                    register={register}
                                    className={`col-span-2 sm:col-span-1`}
                                  />
                                )
                              case 'country':
                                return (
                                  <FormControl
                                    fullWidth
                                    className={`col-span-2`}
                                    variant="standard"
                                    required={true}
                                    error={true}
                                  >
                                    <InputLabel id="salutation">
                                      {t('COUNTRY')}
                                    </InputLabel>
                                    <Select
                                      labelId="salutation-label"
                                      id="salutation-select"
                                      label={t('COUNTRY')}
                                      required={true}
                                      error={true}
                                      defaultValue={
                                        availableCountries?.find(
                                          (x) =>
                                            x.iso3 ===
                                            process.env
                                              .NEXT_PUBLIC_DEFAULT_COUNTRY
                                        )?.id
                                      }
                                      {...register('countryId', {
                                        required: true,
                                      })}
                                    >
                                      {availableCountries &&
                                        availableCountries.length > 0 &&
                                        availableCountries
                                          .sort((x) => x.position)
                                          .map((s) => (
                                            <MenuItem value={s.id}>
                                              {s.name}
                                            </MenuItem>
                                          ))}
                                    </Select>
                                  </FormControl>
                                )
                              case 'textarea':
                                return (
                                  <TextareaAutosize
                                    minRows={10}
                                    name={field.name}
                                    {...register(field.name, {
                                      required: field?.constraints?.length > 0,
                                    })}
                                    placeholder={field.displayName}
                                    className={`col-span-2 sm:col-span-2 border-xl p-2`}
                                  />
                                )
                              case 'checkbox':
                                return (
                                  <FormControlLabel
                                    className={`col-span-2`}
                                    control={
                                      <Checkbox
                                        {...(register(field.name),
                                        {
                                          required: true,
                                        })}
                                      />
                                    }
                                    label={field.displayName}
                                  />
                                )
                              case 'integer':
                                return (
                                  <CustomInputField
                                    label={field.displayName}
                                    name={field.name}
                                    type={`number`}
                                    register={register}
                                    className={`col-span-2 sm:col-span-1`}
                                  />
                                )
                              case 'html_tag':
                                return (
                                  <span
                                    className={`pb-4`}
                                    dangerouslySetInnerHTML={{
                                      __html: t(field.name),
                                    }}
                                  />
                                )
                              case 'choice':
                                return (
                                  <FormControl
                                    fullWidth
                                    className={`col-span-2`}
                                    variant="standard"
                                    required={field?.constraints?.length > 0}
                                    error={errors[field.name] !== undefined}
                                  >
                                    <InputLabel id={field.name}>
                                      {field.displayName}
                                    </InputLabel>
                                    <Select
                                      labelId="salutation-label"
                                      id="salutation-select"
                                      error={true}
                                      label={field.displayName}
                                      required={field?.constraints?.length > 0}
                                      {...register(field.name, {})}
                                    >
                                      {field?.options?.choices &&
                                        Object.keys(
                                          field?.options?.choices
                                        ).map((s) => (
                                          <MenuItem value={s} key={s}>
                                            {field?.options?.choices[s]}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                )
                            }
                          })}
                        </div>
                      </>
                    )
                  }
                )}
              <div className="mr-auto">
                <button
                  className="w-full cursor-pointer border border-black bg-aktion px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white"
                  type="submit"
                >
                  {t('CONTINUE')}
                </button>
              </div>
            </form>
          )}
          {formSent === true && (
            <p className="font-swDisp text-xl font-bold italic">
              {t('FORM_SUBMITTED')}
            </p>
          )}
          {formError === true && (
            <p className="font-swDisp text-xl font-bold italic">
              {t('FORM_ERROR')}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
