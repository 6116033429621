import { ISpacer } from '../../models/landingpage'

const Spacer = (props: ISpacer) => {
  return (
    <div
      className={`w-full h-${props.SpaceInRem * 4} ${
        props.seventyPercentBackground
          ? `bg-${props.Farbe}-70`
          : `bg-${props.Farbe}`
      }`}
    ></div>
  )
}

export default Spacer
