import { Language } from '@shopware-pwa/commons'
import { Cart } from '@shopware-pwa/commons/interfaces/models/checkout/cart/Cart'
import { LineItem } from '@shopware-pwa/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { Customer } from '@shopware-pwa/commons/interfaces/models/checkout/customer/Customer'
import { CustomerAddress } from '@shopware-pwa/commons/interfaces/models/checkout/customer/CustomerAddress'
import { Order } from '@shopware-pwa/commons/interfaces/models/checkout/order/Order'
import { PaymentMethod } from '@shopware-pwa/commons/interfaces/models/checkout/payment/PaymentMethod'
import { ShippingMethod } from '@shopware-pwa/commons/interfaces/models/checkout/shipping/ShippingMethod'
import { Product } from '@shopware-pwa/commons/interfaces/models/content/product/Product'
import { CustomerWishlistResponse } from '@shopware-pwa/commons/interfaces/models/content/wishlist/CustomerWishlist'
import { Country } from '@shopware-pwa/commons/interfaces/models/system/country/Country'
import { Salutation } from '@shopware-pwa/commons/interfaces/models/system/salutation/Salutation'
import {
  addPromotionCode,
  addWishlistProduct,
  changeCartItemQuantity,
  changeOrderPaymentMethod,
  confirmPasswordReset,
  createCustomerAddress,
  createInstance,
  createOrder,
  deleteCustomerAddress,
  getAvailableCountries,
  getAvailableLanguages,
  getAvailablePaymentMethods,
  getAvailableSalutations,
  getAvailableShippingMethods,
  getCart,
  getCustomer,
  getCustomerAddresses,
  getCustomerOrders,
  getOrderDetails,
  getProduct,
  getSessionContext,
  getWishlistProducts,
  login,
  newsletterSubscribe,
  register,
  removeCartItem,
  removeWishlistProduct,
  resetPassword,
  setCurrentLanguage,
  setCurrentPaymentMethod,
  setCurrentShippingMethod,
  setDefaultCustomerBillingAddress,
  setDefaultCustomerShippingAddress,
  ShopwareApiInstance,
  updateCustomerAddress,
  updateEmail,
  updatePassword,
} from '@shopware-pwa/shopware-6-client'
import axios from 'axios'
import { trackCartUpdated, trackCheckout } from 'helper/sendinblue'
import _, { head } from 'lodash'
import moment from 'moment'
import business from 'moment-business'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next-translate-routes/router'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  trackAddItemToCard,
  trackOrderCreated,
  trackRemoveItemToCard,
} from '../helper/gtag'
import { IPrice, IProduct } from '../models/product'

declare global {
  interface Window {
    // add you custom properties and methods
    sendinblue: any
    dataLayer: any
  }
}

const priceRules = {
  '4c93e8c9b2d648c297d280ebc2f6d589': '1c6b591ca902427ab4ecb118e8fc1b04',
}

const api = createInstance({
  endpoint: process.env.NEXT_PUBLIC_SW_ENDPOINT,
  accessToken: process.env.NEXT_PUBLIC_SW_ACCESSTOKEN,
  // languageId: "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
})

export const useShopContext = (): ShopContext => {
  const [token, setToken] = useState<any>(undefined)
  const [init, setInit] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const userGroup = 'END'

  const [userInformation, setUserInformation] = useState<Customer>(undefined)

  const [customerAddresses, setCustomerAddresses] =
    useState<CustomerAddress[]>(undefined)

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])

  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([])

  const [cartInformation, setCartInformation] = useState<Cart>()

  const [customerOrders, setCustomerOrders] = useState<Order[]>([])

  const [availableCountries, setAvailableCountries] = useState<Country[]>([])
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>([])

  const [shopwareProducts, setShopwareProducts] = useState<Product[]>(undefined)

  const [userWishlist, setUserWishlist] =
    useState<CustomerWishlistResponse>(undefined)

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod>(undefined)

  const [selectedShippingMethod, setSelectedShippingMethod] =
    useState<ShippingMethod>(undefined)

  const [creditCardToken, setCreditCardToken] = useState<any>(undefined)

  const [salutations, setSalutations] = useState<Salutation[]>()

  const [error, setError] = useState<any>()
  const [wrongGroup, setWrongGroup] = useState(undefined)

  const [deliveryDateEarliest, setDeliveryDateEarliest] = useState(undefined)
  const [deliveryDateLatest, setDeliveryDateLatest] = useState(undefined)

  const [currentShopLanguage, setCurrentShopLanguage] =
    useState<Language>(undefined)

  const refreshSessionContext = () => {
    getSessionContext(api)
      .then(async (data) => {
        localStorage.setItem('session', data.token)
        setUserInformation(data.customer as Customer)
        await initSystem()
        return setToken(data.token)
      })
      .catch((err) => {
        localStorage.removeItem('session')
        updateApiToken(undefined)
      })
  }

  const updateApiToken = (token: string) => {
    localStorage.removeItem('session')
    localStorage.setItem('session', token)
    api.update({
      contextToken: token,
    })
    refreshSessionContext()
  }

  const loadSessionCookieOrGenerateAnew = () => {
    if (localStorage.getItem('session')) {
      if (localStorage.getItem('session') === 'undefined') {
        localStorage.removeItem('session')
      } else {
        updateApiToken(localStorage.getItem('session'))
      }
    } else {
      refreshSessionContext()
    }
  }

  const createUserFromOxid = async (username, password) => {
    try {
      const data = await axios.post(
        `https://oxid.stayspiced.com/shop/user/index.php`,
        { username, password }
      )
      if (data && data.data.success) {
        // We have customer data
        const { userInfo } = data.data
        const { countryList } = data.data
        const country = countryList?.find(
          (x) =>
            x.oxcountry__oxid?.value === userInfo?.oxuser__oxcountryid?.rawValue
        )
        const shopwareCountry = availableCountries?.find(
          (x) => x.iso === country?.oxcountry__oxisoalpha2?.rawValue
        )
        const shopwareSalutation =
          salutations?.find(
            (x) => x.salutationKey === userInfo?.oxuser__oxsal?.rawValue
          ) ?? salutations[0]
        // Try to Register the User
        const validGroups = process.env.NEXT_PUBLIC_VALID_GROUPS.split(',')
        if (
          validGroups.find(
            (x) => x === userInfo?.oxuser__preiscode?.rawValue
          ) === undefined
        ) {
          setWrongGroup(userInfo?.oxuser__preiscode?.rawValue)
          return false
        }
        return await pageRegister({
          email: username,
          password,
          company: userInfo?.oxuser__company2?.rawValue,
          salutationId: shopwareSalutation?.id,
          firstName: userInfo?.oxuser__oxfname?.rawValue,
          lastName: userInfo?.oxuser__oxlname?.rawValue,
          storefrontUrl: process.env.NEXT_PUBLIC_SW_ENDPOINT,
          acceptedDataProtection: true,
          billingAddress: {
            city: userInfo?.oxuser__oxcity?.rawValue,
            firstName: userInfo?.oxuser__oxfname?.rawValue,
            lastName: userInfo?.oxuser__oxlname?.rawValue,
            salutationId: shopwareSalutation?.id,
            street: `${userInfo?.oxuser__oxstreet?.rawValue} ${userInfo.oxuser__oxstreetnr?.rawValue}`,
            zipcode: userInfo?.oxuser__oxzip?.rawValue,
            countryId: shopwareCountry?.id,
          },
        })
      }
      return false
    } catch (error) {
      return false
    }
  }

  async function initSystem() {
    await loadCustomerOrders()
    await loadAllAddressesForCustomers()
    await getPaymentMethods()
    await getShippingMethods()
    await refreshWishlist()
    setInit(true)
  }

  const pageLogin = async (username, password) => {
    try {
      const data = await login(
        {
          username,
          password,
        },
        api
      )
      if (data && (data as any)?.errors) {
        setError('LOGIN')
        // Todo Hook in for Oxid check
        return false
      }

      window?.sendinblue?.identify(username)
      await updateApiToken(data.contextToken)

      setError(undefined)
      return true
    } catch (err) {
      setError('LOGIN')
      // const oxidCreation = await createUserFromOxid(username, password)
      // if (oxidCreation === false) {
      //   return setToken(undefined)
      // }
    }
  }

  const isLoggedIn = userInformation !== undefined && userInformation !== null

  const pageLogout = () => {
    setUserInformation(undefined)

    setCartInformation(undefined)

    setPaymentMethods(undefined)

    setShippingMethods(undefined)

    setCustomerOrders(undefined)

    setCustomerAddresses(undefined)

    setToken(undefined)

    localStorage.removeItem('session')

    api.update({
      contextToken: undefined,
    })

    loadSessionCookieOrGenerateAnew()

    if (window) {
      window.location.replace('/')
    }
  }

  async function processCart(
    cart: Cart,
    currentShippingMethods: ShippingMethod[] = shippingMethods
  ): Promise<any> {
    if (cart.errors) {
      if (
        Object.keys(cart.errors).filter((x) => x.startsWith('shipping'))
          .length > 0
      ) {
        await selectShippingMethod(currentShippingMethods[0])
        return undefined
      }
    }
    let earliestDate =
      cart?.deliveries.length > 0
        ? moment((cart?.deliveries[0] as any)?.deliveryDate?.earliest)
        : undefined

    earliestDate =
      !business.isWeekDay(earliestDate) || moment().hours() < 13
        ? business.addWeekDays(earliestDate, 1)
        : earliestDate
    let latestDate = earliestDate.clone()
    latestDate = business.addWeekDays(latestDate, 1)
    setSelectedShippingMethod(currentShippingMethods[0])
    setDeliveryDateEarliest(earliestDate.format('DD.MM.YYYY'))
    setDeliveryDateLatest(latestDate.format('DD.MM.YYYY'))
    return cart
  }

  const loadUserInformation = async () => {
    const currentShippingMethods = await getShippingMethods()
    await getPaymentMethods()
    try {
      const cartData = await getCart(api)
      const processedCart = await processCart(cartData, currentShippingMethods)
      if (processedCart) {
        setCartInformation(processedCart)
      }
    } catch (err) {
      console.log(err)
    }
    try {
      const customerData = await getCustomer(null, api)
      setUserInformation(customerData)
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (!init && userInformation) {
      initSystem().catch((err) => console.log(err))
    }
  }, [userInformation, init])

  const addToBasket = async (
    shopwareProductId,
    product,
    amount = 1,
    recipient: string = '',
    sender: string = '',
    isAffiliate: boolean = false,
    referer: string = ''
  ) => {
    setBasketArticle(product)
    trackAddItemToCard(
      product,
      shopwareProducts.find((x) => x.id === shopwareProductId),
      amount
    )
    const shopwareProduct = shopwareProducts.find(
      (x) => x.id === shopwareProductId
    )
    const data = await customAddToBasket(
      shopwareProductId,
      amount,
      shopwareProduct?.price[0].gross,
      recipient,
      sender,
      shopwareProduct?.productNumber.startsWith('GS00x'),
      isAffiliate,
      referer
    )

    trackCartUpdated(userInformation, data)

    await setCartInformation(await processCart(data))
  }

  const pageRegister = async (data: any) => {
    try {
      const registerResponse = await register(data, api)

      if ((data as any).newsletter) {
        await addNewsletterRcpt(data)
      }
      if (registerResponse && data.password) {
        await pageLogin(data.email, data.password)
      } else if (registerResponse) {
        setUserInformation(registerResponse)
      }

      return true
    } catch (error) {
      return false
    }
  }

  const addAddress = async (data: CustomerAddress) => {
    try {
      const addressRequest = { ...data, customerId: userInformation.id }

      let addAddressResponse

      if (data.id === '') {
        addAddressResponse = await createCustomerAddress(addressRequest, api)
      } else {
        addAddressResponse = await updateCustomerAddress(addressRequest, api)
      }

      if (addAddressResponse) {
        await loadAllAddressesForCustomers()
      }
    } catch (err) {}
  }

  const deleteAddress = async (data: CustomerAddress) => {
    try {
      await deleteCustomerAddress(data.id, api)

      await loadAllAddressesForCustomers()
    } catch (err) {}
  }

  const removeItem = async (lineItem: LineItem) => {
    trackRemoveItemToCard(lineItem)
    removeCartItem(lineItem.id, api)
      .then(async (cart) => {
        try {
          const processedCart = await processCart(cart)
          if (processedCart) {
            setCartInformation(processedCart)
          }
        } catch (err) {
          console.log(err)
        }
      })
      .catch((err) => console.log(error))
  }

  const increaseItem = async (lineItem: LineItem) => {
    changeCartItemQuantity(lineItem.id, (lineItem.quantity += 1), api)
      .then(async (data) => setCartInformation(await processCart(data)))
      .catch((err) => console.log(err))
  }

  const changeQuantity = async (lineItem: LineItem, quantity: number) => {
    changeCartItemQuantity(lineItem.id, quantity, api)
      .then(async (data) => setCartInformation(await processCart(data)))
      .catch((err) => console.log(err))
  }

  const decrementItem = async (lineItem: LineItem) => {
    changeCartItemQuantity(lineItem.id, (lineItem.quantity -= 1), api)
      .then(async (data) => setCartInformation(await processCart(data)))
      .catch((err) => console.log(err))
  }

  const getPaymentMethods = () => {
    getAvailablePaymentMethods(api, {
      onlyAvailable: true,
    })
      .then((recvPaymentMethods) => {
        setSelectedPaymentMethod(
          recvPaymentMethods.elements.find(
            (pm) => pm.id === userInformation.defaultPaymentMethodId
          )
        )

        setPaymentMethods(recvPaymentMethods.elements)
      })

      .catch((err) => console.log)
  }

  const getShippingMethods = async () => {
    try {
      const shippingMethodsResponse = await getAvailableShippingMethods(api, {
        onlyAvailable: true,
      })
      await setShippingMethods(shippingMethodsResponse.elements)
      return shippingMethodsResponse.elements
    } catch (err) {
      console.log(err)
      return undefined
    }
  }

  const loadCustomerOrders = () =>
    getCustomerOrders(
      {
        associations: {
          lineItems: {},
          documents: {
            associations: {
              documentType: {},
            },
          },
          transactions: {
            associations: {
              paymentMethod: {},
            },
          },
          deliveries: {
            associations: {
              shippingMethod: {},
              shippingOrderAddress: {},
            },
          },
        },
      },
      api
    )
      .then((customerOrderData) =>
        setCustomerOrders((customerOrderData as any)?.elements)
      )
      .catch((err) => {})

  const isCurrentPaymentMethod = (paymentMethodToCheck: PaymentMethod) =>
    paymentMethodToCheck?.id === selectedPaymentMethod?.id

  const isCurrentShippingMethod = (shippingMethodToCheck: ShippingMethod) =>
    shippingMethodToCheck?.id === selectedShippingMethod?.id

  const selectPaymentMethod = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)

    setCurrentPaymentMethod(paymentMethod.id, api)
      .then((data) => true)
      .catch((err) => {})
  }

  const setShopLanguage = async (languageId: string) => {
    const newLanguage =
      availableLanguages.find((x) =>
        x.translationCode.code.startsWith(languageId)
      ) ?? head(availableLanguages)
    if (newLanguage) {
      await setCurrentLanguage((newLanguage as any).id, api)
      setCurrentShopLanguage(newLanguage)
    }
  }

  const selectShippingMethod = async (shippingMethod: ShippingMethod) => {
    setSelectedShippingMethod(shippingMethod)
    try {
      await setCurrentShippingMethod(shippingMethod.id, api)

      const cartData = await getCart(api)
      setCartInformation(cartData)
    } catch (err) {
      console.log(err)
    }
  }

  const loadOrderWithId = (orderId: string) =>
    getOrderDetails(
      orderId,
      {
        associations: {
          lineItems: {},
        },
      },
      api
    )

  const loadAllAddressesForCustomers = () =>
    getCustomerAddresses(null, api)
      .then((customerAddressesRecv) =>
        setCustomerAddresses([...customerAddressesRecv.elements])
      )
      .catch((error) => {})

  const isAddressDefaultBilling = (addressId: string) =>
    userInformation.defaultBillingAddressId === addressId

  const isAddressDefaultShipping = (addressId: string) =>
    userInformation.defaultShippingAddressId === addressId

  const setDefaultBillingAddress = async (addressId: string) => {
    setIsLoading(true)
    try {
      await setDefaultCustomerBillingAddress(addressId, api)
      await loadUserInformation()
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }

  const setDefaultShippingAddress = async (addressId: string) => {
    setIsLoading(true)
    try {
      await setDefaultCustomerShippingAddress(addressId, api)
      await loadUserInformation()
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }

  const router = useRouter()

  const createOrderAndHandlePayment = async (digitalWalletId = undefined) => {
    setIsLoading(true)
    try {
      const { locale } = router
      const language =
        availableLanguages.find((x) =>
          x.translationCode.code.startsWith(locale)
        ) ?? head(availableLanguages)
      await setCurrentLanguage((language as any)?.id ?? undefined, api)
      const orderResponse = await createOrder(
        {
          languageId: (language as any)?.id ?? undefined,
        },
        {
          ...api,
          config: {
            ...api.config,
            languageId: (language as any)?.id ?? undefined,
          },
        }
      )

      const paymentHandling = await api._axiosInstance.post(
        `${process.env.NEXT_PUBLIC_SW_ENDPOINT}/store-api/stayspiced-payment`,
        {
          orderId: orderResponse.id,
          finishUrl: `${process.env.NEXT_PUBLIC_FRONTEND}/${locale}/success?id=${orderResponse.id}`,
          errorUrl: `${process.env.NEXT_PUBLIC_FRONTEND}/${locale}/payment?id=${orderResponse.id}`,
          card: creditCardToken,
          stripeDigitalWalletsPaymentMethodId: digitalWalletId,
        }
      )
      try {
        trackCheckout(userInformation, orderResponse)
        trackOrderCreated(orderResponse, shopwareProducts)
        // if (process.env.NEXT_PUBLIC_ADCELL) {
        //   fetch(
        //     `https://t.adcell.com/t/track.js?pid=10882&eventid=13793&referenz=${orderResponse.orderNumber}&betrag=${orderResponse.amountTotal}`
        //   ).catch((err) => console.log(err))
        // }
      } catch (perr) {
        console.log(perr)
      }

      await router.push(
        paymentHandling?.data?.redirectUrl ?? `/success?id=${orderResponse.id}`
      )
      await loadCustomerOrders()
      getCart(api)
        .then(async (data) => {
          setIsLoading(false)
          return setCartInformation(await processCart(data))
        })
        .catch((error) => console.log(error))

      return true
    } catch (err) {
      // createNew Cart
      err?.messages?.map((errorDetail) => toast(errorDetail.detail))
    }
    setIsLoading(false)
  }

  async function createPayment(id, digitalWalletId = undefined) {
    try {
      await changeOrderPaymentMethod(id, selectedPaymentMethod.id, api)

      const paymentHandling = await api._axiosInstance.post(
        `${process.env.NEXT_PUBLIC_SW_ENDPOINT}/store-api/stayspiced-payment`,
        {
          orderId: id,
          finishUrl: `${process.env.NEXT_PUBLIC_FRONTEND}/success`,
          errorUrl: `${process.env.NEXT_PUBLIC_FRONTEND}/payment?id=${id}`,
          card: creditCardToken,
          stripeDigitalWalletsPaymentMethodId: digitalWalletId,
        }
      )
      loadCustomerOrders()
      router.push(paymentHandling?.data?.redirectUrl ?? '/success')
    } catch (err) {
      console.log(err)
    }
  }

  function getPriceForProductVariant(product: IProduct, variant: IProduct) {
    if (
      variant === undefined &&
      (product?.children === undefined ||
        product?.children?.filter((child) => child.classname === 'Product')
          .length === 0)
    ) {
      return product.Prices?.find((price) => price.PriceCode === userGroup)
        ?.Price
    }
    if (variant === undefined) {
      const allPrices = _.orderBy(
        _.flatten(
          product.children
            ?.filter((child) => child.classname === 'Product')
            ?.map((child: IProduct) => child.Prices)
        )?.filter((price: IPrice) => price?.PriceCode === userGroup),
        (price: IPrice) => price.Price,
        'asc'
      )

      if (allPrices && allPrices.length > 0) {
        return allPrices[0].Price
      }
    } else {
      return variant?.Prices?.find((price) => price.PriceCode === userGroup)
        ?.Price
    }

    return 0
  }

  function getIdForShopwareProduct(product: IProduct) {
    return shopwareProducts?.find(
      (prod) =>
        prod.id ===
        product?.ShopData?.find(
          (shopdata) =>
            shopdata?.Product?.OSProductNumber === product?.OSProductNumber &&
            shopdata.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID
        )?.ShopwareId
    )
  }

  useEffect(() => {
    if (api) {
      loadSessionCookieOrGenerateAnew()
      if (shopwareProducts === undefined) {
        refreshProducts().catch((err) => console.log(err))
      }

      getAvailableSalutations(api)
        .then((data) => setSalutations(data.elements))
        .catch((error) => console.log(error))

      getAvailableCountries(api)
        .then((data) => setAvailableCountries(data.elements))
        .catch((error) => console.log(error))

      getAvailableLanguages(api)
        .then((data) => setAvailableLanguages(data.elements))
        .catch((error) => console.log(error))
    }
  }, [api])

  async function addNewsletterRcpt(data: any) {
    if (api) {
      await newsletterSubscribe(
        {
          email: (data as any).email,
          option: 'subscribe',
          storefrontUrl: process.env.NEXT_PUBLIC_SW_ENDPOINT,
        },
        api
      )
    }
  }

  function productIsOnWishlist(productId: string): boolean {
    return (userWishlist?.products?.elements as any)?.find(
      (product) => product.id === productId
    )
  }

  async function toggleWishlistProduct(productId: string) {
    let wishListResponse

    try {
      if (productIsOnWishlist(productId)) {
        wishListResponse = await removeWishlistProduct(productId, api)
      } else {
        wishListResponse = await addWishlistProduct(productId, api)
      }

      if (wishListResponse.success) {
        await refreshWishlist()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const [wishlistProductCount, setWishlistProductCount] = useState(0)

  async function refreshWishlist() {
    try {
      const customerWishlist = await getWishlistProducts(undefined, api)

      setWishlistProductCount(
        (customerWishlist?.products as any)?.elements?.length ?? 0
      )

      if (customerWishlist) {
        setUserWishlist(customerWishlist)
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function refreshProducts() {
    const data = await axios.get(
      `https://pim.stayspiced.com/api/products?file=${process.env.NEXT_PUBLIC_PRICELIST}.json`
    )
    const res = data.data.data
    const products = res.flat()

    setShopwareProducts(products)
  }

  useEffect(() => {
    if (token) {
      loadUserInformation()
    }
  }, [token])

  const [basketArticle, setBasketArticle] = useState<IProduct>(undefined)

  useEffect(() => {
    if (basketArticle) {
      setTimeout(() => {
        setBasketArticle(undefined)
      }, 5000)
    }
  }, [basketArticle])

  const [isCreditCard, setIsCreditCard] = useState(false)

  const [isMobilePay, setIsMobilePay] = useState(false)

  useEffect(() => {
    if (selectedPaymentMethod) {
      setIsCreditCard(
        (selectedPaymentMethod as any)?.shortName ===
          'stripe.shopware_payment.payment_handler.card'
      )
    }
  }, [selectedPaymentMethod])

  useEffect(() => {
    if (selectedPaymentMethod) {
      setIsMobilePay(
        (selectedPaymentMethod as any)?.shortName ===
          'stripe.shopware_payment.payment_handler.digital_wallets'
      )
    }
  }, [selectedPaymentMethod])

  useEffect(() => {
    if (Array.isArray(cartInformation?.transactions)) {
      setSelectedPaymentMethod(
        paymentMethods?.find(
          (pm) =>
            pm.id === (cartInformation?.transactions[0] as any)?.paymentMethodId
        )
      )
    }
  }, [cartInformation, paymentMethods])

  const [orderInformation, setOrderInformation] = useState<Order>(undefined)

  async function loadOrder(id) {
    const order = await loadOrderWithId(id)

    setOrderInformation(order)
  }

  async function tryCreateUserFromOxidAndStartReset(username: string) {
    const data = await axios.post(
      `https://oxid.stayspiced.com/shop/userraw/index.php`,
      { username }
    )
    if (data && data.data.success) {
      // We have customer data
      const { userInfo } = data.data
      const { countryList } = data.data
      const country = countryList?.find(
        (x) =>
          x.oxcountry__oxid?.value === userInfo?.oxuser__oxcountryid?.rawValue
      )
      const shopwareCountry = availableCountries?.find(
        (x) => x.iso === country?.oxcountry__oxisoalpha2?.rawValue
      )
      const shopwareSalutation =
        salutations?.find(
          (x) => x.salutationKey === userInfo?.oxuser__oxsal?.rawValue
        ) ?? salutations[0]
      const registerData = {
        email: username,
        password: 'sdfsdfasdfasdfsdf34234%$',
        company: userInfo?.oxuser__company2?.rawValue,
        salutationId: shopwareSalutation?.id,
        firstName: userInfo?.oxuser__oxfname?.rawValue,
        lastName: userInfo?.oxuser__oxlname?.rawValue,
        storefrontUrl: process.env.NEXT_PUBLIC_SW_ENDPOINT,
        acceptedDataProtection: true,
        billingAddress: {
          city: userInfo?.oxuser__oxcity?.rawValue,
          firstName: userInfo?.oxuser__oxfname?.rawValue,
          lastName: userInfo?.oxuser__oxlname?.rawValue,
          salutationId: shopwareSalutation?.id,
          street: `${userInfo?.oxuser__oxstreet?.rawValue} ${userInfo.oxuser__oxstreetnr?.rawValue}`,
          zipcode: userInfo?.oxuser__oxzip?.rawValue,
          countryId: shopwareCountry?.id,
        },
      }
      // Try to Register the User
      return register(registerData, api)
    }
  }

  async function passwordRecovery(email: string) {
    const { locale } = router
    const language =
      availableLanguages.find((x) =>
        x.translationCode.code.startsWith(locale)
      ) ?? head(availableLanguages)
    await setCurrentLanguage((language as any)?.id ?? undefined, api)
    await resetPassword({ email }, api)
      .then(() => true)
      .catch(async (err) => {
        try {
          // await tryCreateUserFromOxidAndStartReset(email)
          await passwordRecovery(email)
        } catch (error) {
          console.log(error)
        }
      })
  }

  const { t } = useTranslation('common')

  async function changePassword(data) {
    return updatePassword(
      {
        newPassword: data.password,
        password: data.old_password,
        newPasswordConfirm: data.password_repeat,
      },
      api
    )
      .then((x) => t('PASSWORD_SET_SUCCESS'))
      .catch((err) => t('PASSWORD_SET_ERROR'))
  }

  async function changeMail(data) {
    return updateEmail(
      {
        email: data.email,
        emailConfirmation: data.email_confirmation,
        password: data.password,
      },
      api
    )
      .then((x) => t('EMAIL_SET_SUCCESS'))
      .catch((err) => t('EMAIL_SET_ERROR'))
  }

  async function redeemVoucher(data) {
    try {
      const result = await addPromotionCode(data, api)
      if (
        Object.keys(result.errors).length > 0 &&
        !Object.keys(result.errors)[0].startsWith('promotion-discount-added')
      ) {
        return false
      }
      setCartInformation(await processCart(result))
      return true
    } catch (err) {
      return false
    }
  }

  async function passwordReset(data) {
    return confirmPasswordReset(
      {
        newPassword: data.password,
        hash: data.hash,
      },
      api
    )
      .then((x) => true)
      .catch((err) => false)
  }

  const [showLogin, setShowLogin] = useState<boolean>(false)

  const [loginState, setLoginState] = useState(false)
  const [loginMessage, setLoginMessage] = useState(undefined)

  const getGroupPrice = (shopwareProduct) => {
    const neededGroup = priceRules[userInformation?.groupId]
    return (
      shopwareProduct?.prices?.find((price) => price.ruleId === neededGroup)
        ?.price[0]?.net ?? undefined
    )
  }

  const getProductDelivery = async (id) => {
    try {
      const product = await getProduct(id, undefined, api)
      return !(
        product.product.isCloseout && product.product.availableStock <= 0
      )
    } catch (ex) {
      return false
    }
  }

  function getProductInfoByShopwareId(productNumber: string) {
    return shopwareProducts?.find(
      (prod) =>
        prod.productNumber.toLocaleLowerCase() ===
        productNumber.toLocaleLowerCase()
    )
  }

  async function customAddToBasket(
    productId: string,
    quantity: number,
    value: number = 0,
    recipient: string = '',
    sender: string = '',
    isVoucher = false,
    isAffiliate = false,
    referer = ''
  ) {
    try {
      const item = isVoucher
        ? {
            id: productId,
            referencedId: productId,
            quantity,
            type: 'product',
            payload: {
              isAffiliate,
              netiNextEasyCoupon: {
                voucherValue: value,
                recipientName: recipient,
              },
              netiNextEasyCouponDesigns: {
                selectedDesign: 'acfc89b627f74b1ab5d9fa0b53c6c139',
                personalization: {
                  GIVER_NAME: sender,
                  RECIPIENT_NAME: recipient,
                  MESSAGE: '',
                },
              },
            },
          }
        : {
            id: productId,
            referencedId: productId,
            quantity,
            type: 'product',
            payload: {
              isAffiliate,
              referer,
            },
          }
      const response = await api._axiosInstance.post(
        `/store-api/checkout/cart/line-item`,
        {
          items: [item],
        }
      )
      if (response.status === 200) {
        return response.data
      }
      return []
    } catch (ex) {
      console.log(ex)
      return []
    }
  }

  async function confirmDoubleOptIn(em: string, hash: string) {
    try {
      const response = await api._axiosInstance.post(
        `/store-api/newsletter/confirm`,
        {
          em,
          hash,
        }
      )
      if (response.status === 200) {
        return true
      }
      return false
    } catch (ex) {
      return false
    }
  }

  return {
    pageLogin,
    token,
    userInformation,
    cartInformation,
    api,
    isLoggedIn,
    pageLogout,
    setCartInformation,
    removeItem,
    pageRegister,
    salutations,
    addToBasket,
    increaseItem,
    decrementItem,
    paymentMethods,
    selectPaymentMethod,
    selectedPaymentMethod,
    isCurrentPaymentMethod,
    createOrderAndHandlePayment,
    customerOrders,
    loadOrderWithId,
    loadAllAddressesForCustomers,
    isAddressDefaultBilling,
    isAddressDefaultShipping,
    setDefaultBillingAddress,
    setDefaultShippingAddress,
    customerAddresses,
    getPriceForProductVariant,
    getIdForShopwareProduct,
    shippingMethods,
    selectShippingMethod,
    isCurrentShippingMethod,
    changeQuantity,
    addAddress,
    availableCountries,
    deleteAddress,
    addNewsletterRcpt,
    showMenu,
    setShowMenu,
    shopwareProducts,
    toggleWishlistProduct,
    productIsOnWishlist,
    wishlistProductCount,
    userWishlist,
    basketArticle,
    setBasketArticle,
    error,
    createPayment,
    setCreditCardToken,
    isCreditCard,
    creditCardToken,
    isMobilePay,
    loadOrder,
    orderInformation,
    passwordRecovery,
    changePassword,
    changeMail,
    passwordReset,
    redeemVoucher,
    wrongGroup,
    isLoading,
    showLogin,
    setShowLogin,
    loginState,
    setLoginState,
    loginMessage,
    setLoginMessage,
    getGroupPrice,
    getProductInfoByShopwareId,
    getProductDelivery,
    confirmDoubleOptIn,
    deliveryDateEarliest,
    deliveryDateLatest,
    customAddToBasket,
    selectedShippingMethod,
    setShopLanguage,
  }
}

export interface ShopContext {
  pageLogin: (usenname, password) => Promise<any>
  token: string
  userInformation: Customer | undefined
  cartInformation: Cart | undefined
  api: ShopwareApiInstance | undefined
  isLoggedIn: boolean
  pageLogout: () => void
  setCartInformation
  removeItem
  pageRegister: (data) => void
  salutations
  addToBasket
  increaseItem
  decrementItem
  paymentMethods: PaymentMethod[]
  selectPaymentMethod
  selectedPaymentMethod
  isCurrentPaymentMethod
  createOrderAndHandlePayment
  customerOrders
  loadOrderWithId
  loadAllAddressesForCustomers
  isAddressDefaultBilling
  isAddressDefaultShipping
  setDefaultBillingAddress
  setDefaultShippingAddress
  customerAddresses: CustomerAddress[]
  getPriceForProductVariant
  getIdForShopwareProduct
  shippingMethods: ShippingMethod[]
  selectShippingMethod
  isCurrentShippingMethod
  changeQuantity
  addAddress
  availableCountries: Country[]
  deleteAddress
  shopwareProducts
  addNewsletterRcpt
  showMenu
  setShowMenu
  toggleWishlistProduct
  productIsOnWishlist
  wishlistProductCount
  userWishlist
  basketArticle
  setBasketArticle
  error
  createPayment
  setCreditCardToken
  isCreditCard
  creditCardToken
  isMobilePay
  loadOrder
  orderInformation
  passwordRecovery
  changePassword
  changeMail
  passwordReset
  redeemVoucher
  wrongGroup
  isLoading
  showLogin
  setShowLogin
  loginState
  setLoginState
  loginMessage
  setLoginMessage
  getGroupPrice
  getProductInfoByShopwareId
  getProductDelivery
  confirmDoubleOptIn
  deliveryDateEarliest
  deliveryDateLatest
  customAddToBasket
  selectedShippingMethod: ShippingMethod
  setShopLanguage
}

export const shopContext = React.createContext<ShopContext>(undefined)
