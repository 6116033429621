import { languageContext } from 'context/language-context'
import useBreakpoints from 'hooks/use-breakpoints'
import { IProduct } from 'models/product'
import Link from 'next-translate-routes/link'
import React, { useContext, useEffect } from 'react'

import { loadProduct } from '../../graph/get-product'
import IconBasket from '../../icons/icon-basket'
import { shopContext } from '../../providers/shop-context'
import { PictureTag } from '../helper/picture-tag'

export interface LineItemProps {
  lineItem: any
}

export interface PriceDefinitionInterface {
  price: number
  quantity: number
}

export const OrderLineItemRowOxid = ({ lineItem }: LineItemProps) => {
  const { currentLanguage } = useContext(languageContext)

  const [data, setData] = React.useState<IProduct>(undefined)

  useEffect(() => {
    loadProduct(lineItem.ARTIKEL, currentLanguage, 'variant')
      .then((pData) => {
        setData(pData)
      })
      .catch((err) => console.log(err))
  }, [])

  const { addToBasket, getIdForShopwareProduct } = useContext(shopContext)

  const { isXs } = useBreakpoints()

  return (
    <>
      {data && (
        <div
          className={`relative  flex items-center flex-row pb-2 min-h-32 flex-wrap`}
        >
          <div className="w-1/2 sm:w-1/12">
            {data && data.Images && data.Images.length > 0 && (
              <PictureTag
                imageUrl={data.Images[0]?.image.fullpath}
                className="z-0 m-auto"
                sizesVW="7"
              />
            )}
          </div>
          <div className="flex w-1/2 flex-col sm:w-1/5">
            <Link href={`/produkt/${data?.Slug}`}>
              <a>
                <h3 className="font-swDisp text-base font-bold italic">
                  {data?.ArticleName}
                </h3>
              </a>
            </Link>
            {data?.PackagingRelation?.length > 0 && (
              <p
                className="font-swText"
                dangerouslySetInnerHTML={{
                  __html: data?.PackagingRelation[0]?.PackagingName,
                }}
              />
            )}
            <p className="font-swText italic text-gray-400">
              ArtNr. {data?.OSProductNumber}
            </p>
            {/* {isXs && (
              <div className={`w-full flex flex-col `}>
                <h3 className="ml-auto font-swDisp text-2xl font-bold italic">
                  <span className="pr-4 text-base font-normal">
                    {lineItem.MENGE} x{" "}
                    {formatter.format(lineItem.unitPrice ?? 0)}
                  </span>
                  {formatter.format(lineItem.totalPrice ?? 0)}
                </h3>
              </div>
            )} */}
          </div>

          {!isXs && (
            <>
              <div className={`w-1/5 flex flex-col `}>
                <h3 className="ml-auto font-swDisp text-2xl font-bold italic">
                  {lineItem.quantity}
                </h3>
              </div>

              <div className={`w-1/5 flex flex-col `}>
                {/* <h3 className="ml-auto font-swDisp text-2xl font-bold italic">
                  {formatter.format(lineItem.totalPrice ?? 0)}
                </h3>
                <p className="ml-auto">
                  {formatter.format(lineItem.unitPrice ?? 0)}
                </p> */}
              </div>
              <div className={`w-1/5 flex flex-col mx-auto `}>
                <button
                  onClick={() =>
                    addToBasket(getIdForShopwareProduct(data)?.id, data, 1)
                  }
                >
                  <IconBasket
                    className={`mx-auto hover:text-9  cursor-pointer`}
                    width={30}
                  />
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
