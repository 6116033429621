import { Order } from "@shopware-pwa/commons/interfaces/models/checkout/order/Order"
import axios from "axios"
import _ from "lodash"
import { useTranslation } from "next-i18next"
import React, { useContext, useEffect } from "react"

import { shopContext } from "../../providers/shop-context"
import { AccountBox } from "./account-box"
import { OrderLine } from "./order-line"
import { OrderLineOxid } from "./order-line-oxid"

export const OrderBox = () => {
  const { customerOrders, userInformation } = useContext(shopContext)

  const [oldOrders, setOldOrders] = React.useState<any[]>(undefined)

  useEffect(() => {
    axios
      .post(`/api/order`, { username: userInformation.email })
      .then((res) => {
        setOldOrders(res.data.result)
      })
      .catch((err) => console.log(err))
  }, [])

  const { t } = useTranslation("common")

  return (
    <>
      <>
        <p className="pb-8 font-swDisp text-4xl font-bold italic">
          {t("YOUR_ORDERS")}
        </p>
        <AccountBox title={"Bestellungen"}>
          <div className={`flex flex-col`}>
            {customerOrders &&
              customerOrders.length > 0 &&
              _.orderBy(
                customerOrders,
                (order) => order.orderNumber,
                "desc"
              ).map((customerOrder: Order, index) => (
                <OrderLine
                  order={customerOrder}
                  key={customerOrder.id}
                  index={index}
                />
              ))}
            {oldOrders &&
              oldOrders?.map((order: any, index: number) => (
                <OrderLineOxid order={order} index={index} />
              ))}
          </div>
        </AccountBox>
      </>
    </>
  )
}
