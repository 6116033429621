import React from 'react'

interface AccountBoxProps {
  children: React.ReactElement | React.ReactElement[]
  title?: string
  half?: boolean
}

export const AccountBox = ({ children, title, half }: AccountBoxProps) => {
  return (
    <div
      className={`w-full ${
        half ? 'sm:w-1/2' : ''
      } flex flex-col  first:m-0 sm:p-6 mb-4 sm:leading-9`}
    >
      <h3 className={`font-swDisp text-2xl font-bold italic pb-4`}>{title} </h3>
      {children}
    </div>
  )
}

AccountBox.defaultProps = {
  half: false,
}
