import _ from 'lodash'

export function trackCartUpdated(userInformation: any, data: any) {
  window?.sendinblue?.track(
    'cart_updated',
    {
      email: userInformation?.email,
      firstname: userInformation?.firstName,
      lastname: userInformation?.lastName,
    },
    {
      data: {
        real_url: 'https://sw6.stayspiced.com/warenkorb',
        products: data.lineItems.map((p: any) => ({
          name: p?.label,
          price: p?.price?.unitPrice,
          quantity: p?.quantity,
          image: `https://pim.stayspiced.com/spiceworld/image?pn=${p?.payload?.productNumber}`,
        })),
      },
    }
  )
}

export function trackCheckout(userInformation: any, orderData: any) {
  window?.sendinblue?.track(
    'order_completed',
    {
      email: userInformation?.email,
      firstname: userInformation?.firstName,
      lastname: userInformation?.lastName,
      ORDER_ID: orderData.orderNumber,
      ORDER_VALUE: orderData.amountTotal,
      ORDER_DATE: orderData.orderDate,
      ORDER_AMOUNT: _.sumBy(orderData.lineItems, (item: any) => item?.quantity),
    },
    {
      data: {
        ORDER_ID: orderData.orderNumber,
        id: orderData.orderNumber,
        ORDER_VALUE: orderData.amountTotal,
        revenue: orderData.amountTotal,
        ORDER_DATE: orderData.orderDate,
        date: orderData.orderDate,
        ORDER_AMOUNT: _.sumBy(
          orderData.lineItems,
          (item: any) => item?.quantity
        ),
      },
    }
  )
}
