import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { shopContext } from '../../providers/shop-context'
import { CustomInputField } from './address-upsert-box'

interface LoginBoxProps {
  showLogin?: any
  setShowLogin?: any
  setLoginState?: any
  loginMessage?: any
  showClose?: boolean
}

export const LoginBox = ({
  showLogin,
  setShowLogin,
  setLoginState,
  loginMessage,
  showClose = true,
}: LoginBoxProps) => {
  const { pageLogin, error, isLoggedIn, passwordRecovery, wrongGroup } =
    useContext(shopContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const router = useRouter()

  const onSubmit = async (data) => {
    await pageLogin(data.username, data.password)
  }

  const onRecovery = async (data) => {
    await passwordRecovery(data.username)

    setRecoverySuccess(true)
  }

  useEffect(() => {
    if (isLoggedIn) {
      showLogin(false)
      // if (cartInformation.lineItems.length > 0) {
      //   router.push('/warenkorb')
      // } else {
      //   router.push('/account')
      // }
    }
  }, [isLoggedIn])

  function handleKeyInput(evt: any) {
    if (evt.key === 'Escape') {
      setShowLogin(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', (evt) => {
      handleKeyInput(evt)
    })
  }, [])

  const { t } = useTranslation('common')

  const [recovery, setRecovery] = useState(false)

  const [recoverySuccess, setRecoverySuccess] = useState(false)

  return (
    <>
      {recovery === false && (
        <>
          <div className={`flex flex-row`}>
            <h2 className={`text-2xl font-swDisp italic font-bold mr-auto`}>
              {t('LOGIN')}
            </h2>
            {showClose && (
              <button
                className="ml-auto"
                type="button"
                onClick={() => setShowLogin(false)}
              >
                <FontAwesomeIcon
                  className="w-4 text-xxl hover:text-9"
                  icon={faXmark}
                />
              </button>
            )}
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="py-4">
            {loginMessage && (
              <p className={`text-red-500 pb-4`}>{loginMessage}</p>
            )}
            <div className="flex flex-col space-y-4">
              <CustomInputField
                label={t('EMAIL')}
                name={`username`}
                register={register}
                required={true}
              />
              <CustomInputField
                label={t('PASSWORD')}
                name={`password`}
                register={register}
                type={`password`}
                required={true}
              />
              {error && !wrongGroup && (
                <span className="font-swDisp text-red-500">
                  {t('LOGIN_ERROR')}
                </span>
              )}
              <button type="button" onClick={() => setRecovery(true)}>
                {t('FORGOT_PASSWORD')}
              </button>

              {wrongGroup && (
                <span
                  className="font-swDisp text-black text-lg font-bold text-center bg-aktion border border-black p-4"
                  dangerouslySetInnerHTML={{ __html: t(`LOGIN_${wrongGroup}`) }}
                ></span>
              )}
              <div className="w-full items-center sm:inline-flex">
                <div className="mr-auto">
                  <button
                    className="w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white sm:w-32"
                    type="submit"
                  >
                    {t('LOGIN')}
                  </button>
                </div>
                {showLogin && (
                  <div className="ml-auto">
                    <button
                      onClick={() => setLoginState(false)}
                      className="mt-4 inline-block w-full cursor-pointer border border-black bg-white px-6 py-3 text-center font-swText font-bold italic text-black hover:border-9  hover:bg-9 hover:text-white sm:mt-0"
                    >
                      <p>{t('NEW_ACCOUNT')}</p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </>
      )}
      {recovery === true && (
        <>
          <div className={`flex flex-row`}>
            <h1 className={`text-2xl font-swDisp italic font-bold mr-auto`}>
              {t('PASSWORD_RECOVERY')}
            </h1>

            <button
              className="ml-auto"
              type="button"
              onClick={() => {
                if (setShowLogin) {
                  setShowLogin(false)
                }
                setRecoverySuccess(false)
                setRecovery(false)
              }}
            >
              <FontAwesomeIcon
                className="w-4 text-xxl hover:text-9"
                icon={faXmark}
              />
            </button>
          </div>
          {recoverySuccess === false && (
            <form onSubmit={handleSubmit(onRecovery)} className="py-8">
              <p className={`pb-4`}>{t('PASSWORD_RECOVERY_INTRO')}</p>
              <div className="flex flex-col space-y-8">
                <CustomInputField
                  label={t('EMAIL')}
                  name={`username`}
                  register={register}
                  required={true}
                />

                <div className="w-full items-center sm:inline-flex">
                  <div className="mr-auto">
                    <button
                      className="w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white sm:w-40"
                      type="submit"
                    >
                      {t('RECOVERY')}
                    </button>
                  </div>
                  {showLogin && (
                    <div className="ml-auto">
                      <button onClick={() => setLoginState(false)}>
                        <p className="mt-4 inline-block w-full cursor-pointer border border-black bg-white px-6 py-3 text-center font-swText font-bold italic text-black hover:border-9  hover:bg-9 hover:text-white sm:mt-0">
                          {t('NEW_ACCOUNT')}
                        </p>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          )}
          {recoverySuccess === true && <p>{t('PASSWORD_RESET_SUCCESS')}</p>}
        </>
      )}
    </>
  )
}
