import useBreakpoints from 'hooks/use-breakpoints'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { searchContext } from '../../context/search-context'
import { FindologicBox } from '../components/findologic-box'

export const SearchResults = () => {
  const {
    items,
    hasFocus,
    setHasFocus,
    recipes,
    sets,
    searchQuery,
    setSearchQuery,
  } = useContext(searchContext)

  const [currentTab, setCurrentTab] = useState(0)

  const { isXs } = useBreakpoints()

  const router = useRouter()

  useEffect(() => {
    setSearchQuery('')
  }, [router.asPath])

  const { t } = useTranslation()

  return (
    <>
      {items && hasFocus && searchQuery?.length > 0 && (
        <div
          className={`bg-white drop-shadow-lg fixed mx-auto w-full   z-200 top-36 sm:top-32`}
        >
          <div>
            <div className={`grid grid-cols-3 pt-4 sm:container sm:mx-auto`}>
              <button
                className={`w-full border border-grey-800 p-1 ${
                  currentTab === 0 ? 'bg-9 text-white' : ''
                }`}
                onClick={() => setCurrentTab(0)}
              >
                {t('SHOP')} ({items.length})
              </button>
              <button
                className={`w-full border border-grey-800 p-1 ${
                  currentTab === 1 ? 'bg-9 text-white' : ''
                }`}
                onClick={() => setCurrentTab(1)}
              >
                {t('RECIPES')} ({recipes.length})
              </button>
              <button
                className={`w-full border border-grey-800 p-1 ${
                  currentTab === 2 ? 'bg-9 text-white' : ''
                }`}
                onClick={() => setCurrentTab(2)}
              >
                {t('GIFTS')} ({sets.length})
              </button>
            </div>
          </div>
          <div className="container mx-auto flex w-full flex-col flex-wrap overflow-y-scroll">
            {items && currentTab === 0 && items.length > 0 && (
              <ul className="w-full border-b border-gray-600 p-4  text-left lg:border-b-0 lg:pt-3">
                <div className={`grid grid-cols-2 sm:grid-cols-6`}>
                  {items?.slice(0, isXs ? 4 : 6).map((item) => (
                    <FindologicBox data={item} showCta={false} key={uuidv4()} />
                  ))}
                </div>
              </ul>
            )}
            {recipes && currentTab === 1 && recipes.length > 0 && (
              <ul className="w-full border-b border-gray-600 p-4  text-left lg:border-b-0 lg:pt-3">
                <div className={`grid grid-cols-2 sm:grid-cols-6 `}>
                  {recipes?.slice(0, isXs ? 4 : 6).map((item) => (
                    <FindologicBox
                      data={item}
                      showPrize={false}
                      key={uuidv4()}
                    />
                  ))}
                </div>
              </ul>
            )}
            {sets && currentTab === 2 && sets.length > 0 && (
              <ul className="w-full border-b border-gray-600 p-4  text-left lg:border-b-0 lg:pt-3">
                <div className={`grid grid-cols-2 sm:grid-cols-6`}>
                  {sets?.slice(0, isXs ? 4 : 6).map((item) => (
                    <FindologicBox
                      data={item}
                      showPrize={false}
                      key={uuidv4()}
                    />
                  ))}
                </div>
              </ul>
            )}
            {items && items.length > 0 && (
              <button
                className={`mx-auto text-center w-full inline-block py-2 bg-9 text-white`}
                onClick={() => {
                  setHasFocus(false)

                  router.push(`/suche/${searchQuery}`)
                }}
              >
                {t('SHOW_ALL')}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  )
}
