import Link from 'next-translate-routes/link'
import React, { useState } from 'react'

import { IProduct } from '../../models/product'

const IMAGE_SERVER_URL = process.env.NEXT_PUBLIC_IMAGE_SERVER_URL

const ORIGIN_IMAGE_SERVER_URL = process.env.NEXT_PUBLIC_ORIGIN_IMAGE_SERVER_URL

const BLOB_URL = process.env.NEXT_PUBLIC_BLOB_URL

const SIZES = [200, 400, 600, 800, 1000, 1200, 1400, 1600]

export interface PictureTagProps {
  imageUrl?: string
  rawUrl?: string
  className?: string
  wrapperClassName?: string
  sizesVW: string
  hasFullSize?: boolean
  product?: IProduct
  width?: number
  altText?: string
}

export function supportFormatWebp() {
  if (typeof window !== 'undefined' && window && document) {
    const elem = document?.createElement('canvas')

    if (elem && elem.getContext && elem.getContext('2d')) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
    }
  }

  // very old browser like IE 8, canvas not supported
  return false
}

export function UrlBuilder(
  imageUrl,
  width,
  format,
  lossless,
  origin = false,
  webp = true
) {
  if (imageUrl.startsWith('https://')) {
    return imageUrl
  }
  const SERVER = origin === false ? IMAGE_SERVER_URL : ORIGIN_IMAGE_SERVER_URL

  return `${SERVER}${encodeURI(imageUrl)}${webp ? '' : '&png=1'}`
}

export const PictureTag = ({
  imageUrl,
  className,
  wrapperClassName,
  rawUrl,
  hasFullSize,
  product,
  width,
  altText,
}: PictureTagProps) => {
  const [fallback, setFallback] = useState(false)

  return (
    <div
      className={`relative m-auto ${
        hasFullSize ? 'h-full' : ''
      } flex align-middle ${wrapperClassName} justify-center items-center`}
    >
      {(imageUrl || rawUrl) && (
        <picture>
          {!fallback ? (
            <img
              loading="lazy"
              src={
                rawUrl ||
                UrlBuilder(
                  imageUrl,
                  width,
                  'png',
                  false,
                  fallback,
                  supportFormatWebp()
                )
              }
              className={`${className} m-auto`}
              onError={() => {
                setFallback(true)
              }}
              alt={altText ?? ''}
            />
          ) : (
            <img
              loading="lazy"
              src={`https://pim.stayspiced.com/sonstiges/failover.png`}
              className={`${className} m-auto`}
              alt={'failover'}
            />
          )}
        </picture>
      )}
      {product !== undefined && product.Images && product.Images.length > 0 && (
        <div className="absolute right-0 bottom-0 -mb-7 w-2/6">
          <Link href={`/produkt/${product.Slug}`}>
            <a>
              <PictureTag
                imageUrl={product?.Images[0]?.image?.fullpath}
                sizesVW="100"
              />
            </a>
          </Link>
        </div>
      )}
    </div>
  )
}

PictureTag.defaultProps = {
  hasFullSize: true,
}
