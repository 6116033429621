import React, { useState } from 'react'

export interface PageContext {
  pageColor: string
  setPageColor: (pageColor: string) => void
}

export const usePage = (): PageContext => {
  const [pageColor, setColor] = useState<string>(undefined)

  const setPageColor = (color: string) => setColor(color)

  return {
    pageColor,
    setPageColor,
  }
}

export const pageContext = React.createContext<PageContext>(undefined)
