import { gql } from 'graphql-request'

export const ArtikelGridFragment = gql`
  fragment ArtikelGridFragment on object_ArtikelGrid {
    ... on object_ArtikelGrid {
      classname
      id
      shortFilter {
        ... on object_GRIND {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_NUTR {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_USAGE {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_VKZ {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_WORLD {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_IDEEN {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_VIP {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_ANLASS {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_SCHARFGRAD {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_MERCH {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
    }
  }
`

export const RezeptGridFragment = gql`
  fragment RezeptGridFragment on object_RezeptGrid {
    ... on object_RezeptGrid {
      classname
      id
    }
  }
`

export const ProductCompareFragment = gql`
  fragment ProductCompareFragment on object_ProductCompare {
    ... on object_ProductCompare {
      classname
      id
      Headline
      ColumnHeadline
      ColumnIntroText
      TableStructure {
        group
        title
      }
      TableContent {
        product {
          ...ProductMinimumFragment
        }
        tableValues
        headline
        image {
          ... on hotspotimage {
            ...ImageFragment
          }
        }
      }
    }
  }
`

export const TestimonialFragment = gql`
  fragment TestimonialFragment on object_Testimonials {
    ... on object_Testimonials {
      classname
      id
      Name
      StarCounter
      Testimonial
      Image {
        ... on asset {
          fullpath
          filename
        }
      }
    }
  }
`

export const FooterSocialFragment = gql`
  fragment FooterSocialFragment on object_FooterSocial {
    ... on object_FooterSocial {
      classname
      id
    }
  }
`

export const FooterTrustedFragment = gql`
  fragment FooterTrustedFragment on object_FooterTrusted {
    ... on object_FooterTrusted {
      classname
      id
    }
  }
`

export const TrustedShopWrapperFragment = gql`
  fragment TrustedShopWrapperFragment on object_TrustedShopsSlider {
    ... on object_TrustedShopsSlider {
      classname
      id
    }
  }
`

export const TestimonialWrapperFragment = gql`
  fragment TestimonialWrapperFragment on object_TestimonialWrapper {
    ... on object_TestimonialWrapper {
      classname
      id
      Farbe
      Headline
      SubHeadline
      Testimonials {
        ...TestimonialFragment
      }
    }
  }
`

export const KnownFromFragment = gql`
  fragment KnownFromFragment on object_KnownFrom {
    ... on object_KnownFrom {
      classname
      id
      ExternalUrl
      CtaTarget {
        ... on object_LandingPage {
          classname
          Slug: slug
        }
        ... on object_Recipe {
          slug
        }
        ... on object_CategoryShop {
          classname
          Slug
        }
        ... on object_CategoryVirtual {
          classname
          Slug: slug
        }
      }
      HeroImage {
        ... on hotspotimage {
          ...ImageFragment
        }
      }
    }
  }
`

export const KnownFromWrapperFragment = gql`
  fragment KnownFromWrapperFragment on object_KnownFromWrapper {
    ... on object_KnownFromWrapper {
      classname
      id
      Farbe
      Headline
      SubHeadline
      KnownFromElements {
        ...KnownFromFragment
      }
    }
  }
`

export const TrustElementFragment = gql`
  fragment TrustElementFragment on object_TrustElement {
    ... on object_TrustElement {
      classname
      id
      Headline
      Description
      Icon {
        ... on asset {
          fullpath
          filename
        }
      }
    }
  }
`

export const TrustElementWrapperFragment = gql`
  fragment TrustElementWrapperFragment on object_TrustWrapper {
    ... on object_TrustWrapper {
      classname
      id
      Headline
      Farbe
      Trustelemente {
        ...TrustElementFragment
      }
    }
  }
`

export const ImageFragment = gql`
  fragment ImageFragment on hotspotimage {
    crop {
      cropHeight
      cropLeft
      cropTop
      cropWidth
    }
    image {
      filename
      fullpath
      srcset(thumbnail: "Cover") {
        url
      }
    }
  }
`

export const PricesFragment = gql`
  fragment PricesFragment on object_ProductPrice {
    ... on object_ProductPrice {
      AmountPrice
      Currency
      GrossPrice
      Price
      PriceCode
    }
  }
`

export const ProductMinimumFragment = gql`
  fragment ProductMinimumFragment on object_Product {
    ... on object_Product {
      id
      OSProductNumber
      IsBio
      ArticleName
      Usage
      Slug
      ColorCode
      DosenBild {
        fullpath
        id
      }
      SchuettungsBild {
        fullpath
        id
      }
      Images {
        image {
          id
          fullpath
        }
      }
      WORLD {
        ... on object_WORLD {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      ShopData {
        ... on object_ShopwareProduct {
          ShopwareId
          Shop {
            ... on object_ShopwareShop {
              id
            }
          }
          Product {
            ... on object_Product {
              OSProductNumber
              Slug
            }
          }
        }
      }
      Reviews {
        ... on object_Review {
          Rating
          id
          Comment
          Datum
          Name
        }
      }
      Sale {
        ... on object_Aktionen {
          Name
          Farbe
          Shop {
            ... on object_ShopwareShop {
              id
            }
          }
        }
      }
      MERCH {
        ... on object_MERCH {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      VKZ {
        ... on object_VKZ {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      GRIND {
        ... on object_GRIND {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      IDEEN {
        ... on object_IDEEN {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      VIP {
        ... on object_VIP {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      ANLASS {
        ... on object_ANLASS {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      SCHARFGRAD {
        ... on object_SCHARFGRAD {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      USAGEREL {
        ... on object_USAGE {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
      NUTR {
        ... on object_NUTR {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }

      children(objectTypes: ["variant"]) {
        ... on object_Product {
          classname
          id
          DosenBild {
            fullpath
            id
          }
          ShopData {
            ... on object_ShopwareProduct {
              ShopwareId
              Shop {
                ... on object_ShopwareShop {
                  id
                }
              }
              Product {
                ... on object_Product {
                  OSProductNumber
                  Slug
                }
              }
            }
          }

          OSProductNumber
          Slug
        }
      }
      Images {
        ...ImageFragment
      }
    }
  }
`

export const ProductFragment = gql`
  fragment ProductFragment on object_Product {
    id
    classname
    IsActive
    OSProductNumber
    IsBio
    Slug
    SellUnit
    EANCode
    ArticleName
    AdditionalHintPortions
    Portion
    NutritionPortionUnit
    BulletPoints {
      BulletPoint
    }
    DosenBild {
      fullpath
      id
    }
    SchuettungsBild {
      fullpath
      id
    }
    Images {
      image {
        id
        fullpath
      }
    }
    Reviews {
      ... on object_Review {
        Rating
        id
        Comment
        Datum
        Name
        Headline
      }
    }
    ShopData {
      ... on object_ShopwareProduct {
        ShopwareId
        Shop {
          ... on object_ShopwareShop {
            id
          }
        }
        Product {
          ... on object_Product {
            OSProductNumber
            Slug
          }
        }
      }
    }
    SEO {
      __typename
      ... on fieldcollection_SEOInformation {
        Title
        Description
        Keywords
      }
    }
    NewShortDescription
    AlternativeView
    GoodToKnow
    Usage
    HeroVideo {
      data {
        ... on asset {
          filename
          fullpath
        }
        ... on VideoDataDescriptor {
          id
        }
      }
    }
    Taste
    Production
    CaloriesKJ
    Ingredients
    CaloriesKCAL
    TotalFat
    SaturatedFat
    TotalCarbs
    Declaration {
      ...DeclarationIconsFragment
    }
    WORLD {
      ... on object_WORLD {
        id
        classname
        Name
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }
    MERCH {
      ... on object_MERCH {
        id
        classname
        Name
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }
    VKZ {
      ... on object_VKZ {
        id
        classname
        Name
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }
    GRIND {
      ... on object_GRIND {
        id
        classname
        Name
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }
    IDEEN {
      ... on object_IDEEN {
        Name
        id
        classname
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }
    VIP {
      ... on object_VIP {
        Name
        id
        classname
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }
    ANLASS {
      ... on object_ANLASS {
        Name
        id
        classname
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }

    SCHARFGRAD {
      ... on object_SCHARFGRAD {
        Name
        id
        classname
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }

    USAGEREL {
      ... on object_USAGE {
        id
        classname
        Name
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }
    NUTR {
      ... on object_NUTR {
        id
        classname
        Name
        buttonImage {
          ...ImageFragment
        }
        buttonTitle
        Farbe
      }
    }
    TotalSugars
    NettoWeight
    Protein
    Sodium
    Fibers
    Broteinheiten
    ColorCode
    Tip
    PackagingRelation {
      ... on object_Packaging {
        id
        Name
        PackagingName: PackagingNameTranslatable
        Description
        Images {
          image {
            id
            fullpath
          }
        }
      }
    }
    Recipes {
      ... on object_Recipe {
        Image {
          image {
            id
            fullpath
          }
        }
        id
        slug
        RecipeName
        Introduction
      }
    }
    ContainedProducts {
      ... on object_Product {
        id
        OSProductNumber
        Slug
        IsBio
        ArticleName
        DosenBild {
          fullpath
          id
        }
        Reviews {
          ... on object_Review {
            Rating
            id
            Comment
            Datum
            Name
            Headline
          }
        }
        Recipes {
          ... on object_Recipe {
            Image {
              image {
                id
                fullpath
              }
            }
            id
            slug
            RecipeName
            Introduction
          }
        }
        children(objectTypes: ["variant"]) {
          ... on object_Product {
            OSProductNumber
            Slug
            EANCode
            IsActive
            Reviews {
              ... on object_Review {
                Rating
                id
                Comment
                Datum
                Name
                Headline
              }
            }
            ShopData {
              ... on object_ShopwareProduct {
                ShopwareId
                Shop {
                  ... on object_ShopwareShop {
                    id
                  }
                }
                Product {
                  ... on object_Product {
                    OSProductNumber
                    Slug
                  }
                }
              }
            }
          }
        }
        ColorCode
        Images {
          image {
            id
            fullpath
          }
        }
      }
    }
    Sale {
      ... on object_Aktionen {
        Name
        Farbe
        ShortDescription
        Shop {
          ... on object_ShopwareShop {
            id
          }
        }
      }
    }

    Sachmerkmale {
      name
      features {
        __typename
        ... on csFeatureInput {
          name
          description
          text
        }
        ... on csFeatureCountry {
          name
          description
          country
        }

        ... on csFeatureSelect {
          name
          description
          selection
        }
      }
    }
    categories {
      ... on object_CategoryErp {
        id
        Name
        Landingpage {
          ... on object_LandingPage {
            id
          }
        }
      }
    }
    categoriesShop {
      ... on object_CategoryShop {
        id
        Name
        Slug
        Landingpage {
          ... on object_LandingPage {
            id
          }
        }
      }
    }
    children(objectTypes: ["variant", "object"]) {
      ...LandingPageTreeFragment
      ... on object_Product {
        id
        classname
        NettoWeight
        IsActive
        EANCode
        AdditionalHintPortions
        Portion
        NutritionPortionUnit
        DosenBild {
          fullpath
          id
        }
        SchuettungsBild {
          fullpath
          id
        }
        ShopData {
          ... on object_ShopwareProduct {
            ShopwareId
            Shop {
              ... on object_ShopwareShop {
                id
              }
            }
            Product {
              ... on object_Product {
                OSProductNumber
                Slug
              }
            }
          }
        }
        Images {
          image {
            id
            fullpath
          }
        }
        OSProductNumber
        Slug
        children(objectTypes: ["variant"]) {
          ... on object_Product {
            OSProductNumber
            Slug
            ShopData {
              ... on object_ShopwareProduct {
                ShopwareId
                Shop {
                  ... on object_ShopwareShop {
                    id
                  }
                }
                Product {
                  ... on object_Product {
                    OSProductNumber
                    Slug
                  }
                }
              }
            }
          }
        }
        IsBio
        SEO {
          __typename
          ... on fieldcollection_SEOInformation {
            Title
            Description
            Keywords
          }
        }
        PackagingRelation {
          ... on object_Packaging {
            id
            Name
            PackagingName: PackagingNameTranslatable
            Images {
              image {
                id
                fullpath
              }
            }
          }
        }
      }
    }
  }
`

export const HeroEntryFragment = gql`
  fragment HeroEntryFragment on object_HeroEntry {
    ... on object_HeroEntry {
      id
      IntroText
      Name
      Subline
      tagType
      Anordnung
      Farbe
      ImageSize
      TrustText
      TrustColor
      Position
      Fullscreen
      seventyPercentBackground
      classname
      Verlinkung {
        ... on object_LandingPage {
          classname
          Slug: slug
        }
        ... on object_CategoryVirtual {
          classname
          Slug: slug
        }
        ... on object_CategoryShop {
          classname
          Slug
        }
      }
      CTAText
      HeroImages {
        ... on hotspotimage {
          ...ImageFragment
        }
      }
      HeroVideo {
        ... on Video {
          data {
            ... on asset {
              fullpath
              filename
            }
            ... on VideoDataDescriptor {
              id
            }
          }
        }
      }
      Products {
        ...ProductMinimumFragment
      }
    }
  }
`

export const ProductTeaserFragment = gql`
  fragment ProductTeaserFragment on object_ProductTeaser {
    ... on object_ProductTeaser {
      Headline1
      Headline2
      Headline3
      classname
      seventyPercentBackground
      Farbe
      Image {
        ... on hotspotimage {
          ...ImageFragment
        }
      }
      ImageAlignment
      Products {
        ...ProductMinimumFragment
      }
    }
  }
`

export const ProductTeaserSliderFragment = gql`
  fragment ProductTeaserSliderFragment on object_ProductTeaserSlider {
    ... on object_ProductTeaserSlider {
      Headline1
      Headline2
      Headline3
      seventyPercentBackground
      Farbe
      classname
      ImageAlignment
      Products {
        ...ProductMinimumFragment
      }
    }
  }
`

export const ProductSliderFragment = gql`
  fragment ProductSliderFragment on object_ProductSlider {
    ... on object_ProductSlider {
      Headline
      classname
      Farbe
      seventyPercentBackground
      Products {
        ...ProductMinimumFragment
      }
    }
  }
`

export const EndlessSliderFragment = gql`
  fragment EndlessSliderFragment on object_EndlessSlider {
    ... on object_EndlessSlider {
      Headline
      classname
      seventyPercentBackground
      Farbe
      Products {
        ...ProductMinimumFragment
      }
    }
  }
`

export const DeclarationIconsFragment = gql`
  fragment DeclarationIconsFragment on object_DeclarationIcons {
    ... on object_DeclarationIcons {
      classname
      Title
      Description
      Landingpage {
        ... on object_LandingPage {
          Slug: slug
        }
      }
      Icon {
        ...ImageFragment
      }
    }
  }
`

export const TextTwoColumnFragment = gql`
  fragment TextTwoColumnFragment on object_TextTwoColumn {
    ... on object_TextTwoColumn {
      Farbe
      seventyPercentBackground
      HeadlineLeft
      HeadlineRight
      TextLeft

      TextRight
      id
      classname
    }
  }
`

export const TextImageFragment = gql`
  fragment TextImageFragment on object_TextImage {
    ... on object_TextImage {
      Farbe
      id
      classname
      CTAText
      seventyPercentBackground
      Content
      TrustText
      TrustColor
      Position
      Headline
      Image {
        ...ImageFragment
      }
      ImageAlignment
      Subheadline
      Landingpage {
        ... on object_LandingPage {
          classname
          Slug: slug
        }
        ... on object_CategoryVirtual {
          classname
          Slug: slug
        }
        ... on object_CategoryShop {
          classname
          Slug
        }
      }
    }
  }
`

export const AccordionFragment = gql`
  fragment AccordionFragment on object_Accordion {
    ... on object_Accordion {
      Farbe

      Fragen {
        Detail
        Name
      }
      Headline
      Image {
        ...ImageFragment
      }
      Anordnung
      Subheadline
      id
      classname
    }
  }
`

export const BannerFragment = gql`
  fragment BannerFragment on object_Banner {
    ... on object_Banner {
      id
      index
      classname
      Title
      tagType
      Relation {
        ... on object_LandingPage {
          Slug: slug
        }
      }
      BackgroundImage {
        ...ImageFragment
      }
    }
  }
`

export const BannerWrapperFragment = gql`
  fragment BannerWrapperFragment on object_BannerWrapper {
    ... on object_BannerWrapper {
      id
      classname
      children(objectTypes: ["object"]) {
        ...BannerFragment
      }
    }
  }
`

export const RecipeFragment = gql`
  fragment RecipeFragment on object_Recipe {
    ... on object_Recipe {
      id
      Image {
        ...ImageFragment
      }
      id
      slug
      RecipeName
      Introduction
      Products {
        ...ProductMinimumFragment
      }
    }
  }
`

export const RecipeBoxFragment = gql`
  fragment RecipeBoxFragment on object_RecipeBox {
    ... on object_RecipeBox {
      id
      classname
      seventyPercentBackground
      Headline
      Farbe
      Recipes {
        ...RecipeFragment
      }
    }
  }
`

export const MediaVideoFragment = gql`
  fragment MediaVideoFragment on object_MediaVideo {
    ... on object_MediaVideo {
      id
      classname
      Headline

      Farbe
      YouTubeId
    }
  }
`

export const HeroProductFragment = gql`
  fragment HeroProductFragment on object_HeroProduct {
    ... on object_HeroProduct {
      id
      classname
      Farbe
      Anordnung
      MarketingText
      seventyPercentBackground
      Product {
        ...ProductMinimumFragment
      }
    }
  }
`

export const HeroRecipeFragment = gql`
  fragment HeroRecipeFragment on object_HeroRecipe {
    ... on object_HeroRecipe {
      id
      classname
      Anordnung
      MarketingText
      Farbe
      seventyPercentBackground
      Recipe {
        ...RecipeFragment
      }
    }
  }
`

export const MapFragment = gql`
  fragment MapFragment on object_Map {
    ... on object_Map {
      classname
      id
    }
  }
`

export const TextBlockFragment = gql`
  fragment TextBlockFragment on object_TextBlock {
    ... on object_TextBlock {
      id
      classname
      Content
      seventyPercentBackground
      Farbe
    }
  }
`

export const CategoriesFragment = gql`
  fragment CategoriesFragment on object_tree {
    ... on object_CategoryShop {
      Name
      Slug
      id
      classname
    }
    ... on object_CategoryVirtual {
      Name: name
      classname
      id
      Slug: slug
      Kategorien {
        ... on object_CategoryShop {
          id
        }
      }
      Attributes {
        ... on object_GRIND {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_NUTR {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_USAGE {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_VKZ {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_WORLD {
          id
          classname
          Name
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_IDEEN {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_VIP {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_ANLASS {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_SCHARFGRAD {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
        ... on object_MERCH {
          Name
          id
          classname
          buttonImage {
            ...ImageFragment
          }
          buttonTitle
          Farbe
        }
      }
    }
  }
`

export const CategoryTeaserFragment = gql`
  fragment CategoryTeaserFragment on object_CategoryTeaser {
    ... on object_CategoryTeaser {
      id
      classname
      ComponentTitle
      CategoryTeaserElemets {
        Category {
          ...CategoriesFragment
        }
        Image {
          ...ImageFragment
        }
        Title
      }
    }
  }
`

export const HeroNewFragment = gql`
  fragment HeroNewFragment on object_HeroNew {
    ... on object_HeroNew {
      id
      classname
      Headline
      tagType
      TrustText
      TrustColor
      Position
      CtaTargetScroll
      CtaTargetSecondaryScroll
      CtaTarget {
        ... on object_LandingPage {
          classname
          Slug: slug
        }
        ... on object_Product {
          classname
          Slug
        }
        ... on object_Recipe {
          classname
          slug
        }
        ... on object_CategoryShop {
          classname
          Slug
        }
        ... on object_CategoryVirtual {
          classname
          Slug: slug
        }
      }
      CtaTargetSecondary {
        ... on object_LandingPage {
          classname
          Slug: slug
        }
        ... on object_Recipe {
          classname

          slug
        }
        ... on object_Product {
          classname
          Slug
        }
        ... on object_CategoryShop {
          classname
          Slug
        }
        ... on object_CategoryVirtual {
          classname
          Slug: slug
        }
      }
      SubHeadline
      CallToAction
      CallToActionSecondary
      seventyPercentBackground
      Farbe
      HeroImage {
        ... on hotspotimage {
          ...ImageFragment
        }
      }
      HeroImageMobile {
        ... on hotspotimage {
          ...ImageFragment
        }
      }
    }
  }
`

export const TrendingCategoriesFragment = gql`
  fragment TrendingCategoriesFragment on object_TrendingCategories {
    ... on object_TrendingCategories {
      id
      classname
      Headline
      Kategorien {
        ...CategoriesFragment
      }
    }
  }
`

export const SpacerFragment = gql`
  fragment SpacerFragment on object_Spacer {
    ... on object_Spacer {
      id
      classname
      Farbe
      seventyPercentBackground
      SpaceInRem
    }
  }
`

export const FormularFragment = gql`
  fragment FormularFragment on object_Formular {
    ... on object_Formular {
      id
      classname
      Headline
      Formular
      Description
    }
  }
`

export const FullScreenHeroFragment = gql`
  fragment FullScreenHeroFragment on object_FullScreenHero {
    ... on object_FullScreenHero {
      id
      classname
      Headline
      PlayerFunctions
      tagType
      ImageSize
      seventyPercentBackground
      TrustText
      TrustColor
      Position
      CtaTarget {
        ... on object_LandingPage {
          Slug: slug
        }
        ... on object_Recipe {
          slug
        }
      }
      SubHeadline
      CallToAction
      Farbe
      ThirdLine
      Description
      HeroVideo {
        ... on Video {
          data {
            ... on asset {
              fullpath
              filename
            }
            ... on VideoDataDescriptor {
              id
            }
          }
        }
      }
      HeroVideoMobile {
        ... on Video {
          data {
            ... on asset {
              fullpath
              filename
            }
            ... on VideoDataDescriptor {
              id
            }
          }
        }
      }
      TeaserProduct {
        ...ProductMinimumFragment
      }
      HeroImage {
        ... on hotspotimage {
          ...ImageFragment
        }
      }
      HeroImageMobile {
        ... on hotspotimage {
          ...ImageFragment
        }
      }
    }
  }
`

export const HeadlineFragment = gql`
  fragment HeadlineFragment on object_Headline {
    ... on object_Headline {
      id
      classname
      Headline
      tagType
      seventyPercentBackground
      SubHeadline
      Farbe
      ThirdLine
      Description
    }
  }
`

export const SliderWrapperFragment = gql`
  fragment SliderWrapperFragment on object_SliderWrapper {
    ... on object_SliderWrapper {
      id
      classname
      children {
        ...TextImageFragment
        ...TextTwoColumnFragment
      }
    }
  }
`

export const LPArtikelGripFragment = gql`
  fragment LPArtikelGripFragment on object_LPArtikelGrip {
    ... on object_LPArtikelGrip {
      id
      classname
      Headline
      Products {
        ...ProductMinimumFragment
      }
    }
  }
`

export const PdfViewFragment = gql`
  fragment PdfViewFragment on object_PDFViewer {
    ... on object_PDFViewer {
      id
      classname
      Headline
      PDFDocument {
        ... on asset {
          fullpath
          filename
        }
      }
    }
  }
`

export const LandingPageTreeFragment = gql`
  fragment LandingPageTreeFragment on object_tree {
    ...ArtikelGridFragment
    ...RezeptGridFragment
    ...HeroEntryFragment
    ...ProductTeaserFragment
    ...ProductSliderFragment
    ...HeroProductFragment
    ...TextBlockFragment
    ...FullScreenHeroFragment
    ...EndlessSliderFragment
    ...RecipeBoxFragment
    ...BannerWrapperFragment
    ...ProductTeaserSliderFragment
    ...AccordionFragment
    ...TextImageFragment
    ...TextTwoColumnFragment
    ...TrendingCategoriesFragment
    ...MediaVideoFragment
    ...SliderWrapperFragment
    ...PdfViewFragment
    ...LPArtikelGripFragment
    ...HeadlineFragment
    ...FormularFragment
    ...HeroRecipeFragment
    ...MapFragment
    ...TrustElementWrapperFragment
    ...TestimonialWrapperFragment
    ...TrustedShopWrapperFragment
    ...HeroNewFragment
    ...KnownFromFragment
    ...KnownFromWrapperFragment
    ...FooterSocialFragment
    ...FooterTrustedFragment
    ...SpacerFragment
    ...ProductCompareFragment
    ...CategoryTeaserFragment
  }
  ${RezeptGridFragment}
  ${MediaVideoFragment}
  ${ArtikelGridFragment}
  ${HeroEntryFragment}
  ${ProductTeaserFragment}
  ${ProductMinimumFragment}
  ${ImageFragment}
  ${ProductSliderFragment}
  ${HeroProductFragment}
  ${TextBlockFragment}
  ${FullScreenHeroFragment}
  ${EndlessSliderFragment}
  ${RecipeBoxFragment}
  ${RecipeFragment}
  ${BannerFragment}
  ${BannerWrapperFragment}
  ${ProductTeaserSliderFragment}
  ${AccordionFragment}
  ${TextImageFragment}
  ${TextTwoColumnFragment}
  ${CategoriesFragment}
  ${TrendingCategoriesFragment}
  ${SliderWrapperFragment}
  ${PdfViewFragment}
  ${LPArtikelGripFragment}
  ${HeadlineFragment}
  ${FormularFragment}
  ${HeroRecipeFragment}
  ${MapFragment}
  ${TrustElementWrapperFragment}
  ${TestimonialWrapperFragment}
  ${TrustElementFragment}
  ${TestimonialFragment}
  ${TrustedShopWrapperFragment}
  ${HeroNewFragment}
  ${KnownFromFragment}
  ${KnownFromWrapperFragment}
  ${FooterSocialFragment}
  ${FooterTrustedFragment}
  ${SpacerFragment}
  ${ProductCompareFragment}
  ${CategoryTeaserFragment}
`
