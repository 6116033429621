import { useTranslation } from 'next-i18next'
import React, { useContext } from 'react'

import { formatter } from '../../helper/currency-formatter'
import { shopContext } from '../../providers/shop-context'

export const BasketSum = () => {
  const { cartInformation, deliveryDateEarliest, deliveryDateLatest } =
    useContext(shopContext)

  const { t } = useTranslation('common')

  return (
    <div className={`flex flex-col sm:px-4`}>
      <div className="grid w-full grid-cols-12">
        <div
          className={`col-span-8 sm:col-span-10 text-right font-swText text-base`}
        >
          {t('SHIPPING_COSTS')}
        </div>
        <div
          className={`col-span-4 sm:col-span-2 font-bold font-swText text-xl text-right`}
        >
          {formatter.format(
            cartInformation?.deliveries &&
              cartInformation?.deliveries[0].shippingCosts?.totalPrice
          )}
        </div>
      </div>
      <div className="grid w-full grid-cols-12">
        <div
          className={`col-span-8 pt-1 sm:col-span-10 text-right font-swText text-sm justify-center items-center `}
        >
          {t('TOTAL_SUM')}
        </div>
        <div
          className={`col-span-4 sm:col-span-2 font-bold font-swText text-xl text-right`}
        >
          {formatter.format(cartInformation?.price?.totalPrice ?? 0)}
        </div>
      </div>
    </div>
  )
}
