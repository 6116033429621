import IconHeart from 'icons/icon-heart'
import { shopContext } from 'providers/shop-context'
import React, { useContext } from 'react'

export interface IWishlistButtonProps {
  productId: string
}

export const WishlistButton = ({ productId }: IWishlistButtonProps) => {
  const { toggleWishlistProduct, productIsOnWishlist, isLoggedIn } =
    useContext(shopContext)

  async function toggleProduct(e) {
    e.preventDefault()

    toggleWishlistProduct(productId)
  }

  return (
    <>
      {isLoggedIn && (
        <button
          onClick={(e) => toggleProduct(e)}
          className={`absolute top-2 sm:top-4 right-4 sm:right-8 h-5 w-5 ml-auto hover:text-aktion z-50 ${
            productIsOnWishlist(productId) ? 'text-aktion' : 'text-black'
          }`}
        >
          <IconHeart width={30} className={`fill-current`} />
        </button>
      )}
    </>
  )
}
