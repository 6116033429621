import {
  faBarcodeRead,
  faBoxOpenFull,
  faMapPin,
  faPotFood,
  faUserChef,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { accountContext } from 'context/account-context'
import useBreakpoints from 'hooks/use-breakpoints'
import { useRouter } from 'next-translate-routes/router'
import { useTranslation } from 'next-i18next'
import React, { useContext } from 'react'

import { shopContext } from '../../providers/shop-context'
import { Account } from './account'
import { AddressListBox } from './address-list-box'
import { OrderBox } from './order-box'

export interface SideMenuProps {
  setMenuState: any
}

interface SideMenuLinkProps {
  title: string
  setMenuState: any
  component: any

  icon: any
}

export const SideMenuLink = ({ title, component, icon }: SideMenuLinkProps) => {
  const { setMenuState } = useContext(accountContext)

  const { setShowMenu } = useContext(shopContext)

  const router = useRouter()

  return (
    <li className={`list-none text-sm  sm:text-base`}>
      <button
        onClick={() => {
          setMenuState(component)

          setShowMenu(false)

          router.push(`/account`)
        }}
        className={`font-swDisp italic text-sm sm:text-xl hover:text-9 py-2 sm:py-4 flex justify-center`}
      >
        {icon} {title}
      </button>
    </li>
  )
}

export const SideMenu = () => {
  const { pageLogout, userInformation } = useContext(shopContext)

  const { setMenuState } = useContext(accountContext)

  const { isXs } = useBreakpoints()

  const { t } = useTranslation('common')

  return (
    <>
      {!isXs && (
        <button
          onClick={() => setMenuState(<Account />)}
          className="font-swDisp text-2xl font-bold hover:text-9"
        >
          Servus, {userInformation.firstName}
        </button>
      )}
      <div className="relative px-8 pb-32 sm:px-0 sm:pb-24">
        <ul className={` sm:pt-4 inline sm:block relative`}>
          <SideMenuLink
            title={t('YOUR_ACCOUNT')}
            setMenuState={setMenuState}
            component={<Account />}
            icon={
              <FontAwesomeIcon className="w-8 pr-2 text-xl" icon={faUserChef} />
            }
          />
          <SideMenuLink
            title={t('YOUR_ADDRESSES')}
            setMenuState={setMenuState}
            icon={
              <FontAwesomeIcon className="w-8 pr-2 text-xl" icon={faMapPin} />
            }
            component={<AddressListBox />}
          />
          <SideMenuLink
            title={t('YOUR_RECIPES')}
            setMenuState={setMenuState}
            icon={
              <FontAwesomeIcon className="w-8 pr-2 text-xl" icon={faPotFood} />
            }
            component={<></>}
          />
          <SideMenuLink
            title={t('YOUR_ORDERS')}
            setMenuState={setMenuState}
            icon={
              <FontAwesomeIcon
                className="w-8 pr-2 text-xl"
                icon={faBoxOpenFull}
              />
            }
            component={<OrderBox />}
          />
          <SideMenuLink
            title={t('DIRECT_ORDER')}
            setMenuState={setMenuState}
            icon={
              <FontAwesomeIcon
                className="w-8 pr-2 text-xl"
                icon={faBarcodeRead}
              />
            }
            component={<></>}
          />
        </ul>
        <button
          type="button"
          className={` w-full py-2 font-bold text-black inline-block border border-black text-center hover:bg-black hover:text-white font-swDisp`}
          onClick={() => pageLogout()}
        >
          Logout
        </button>
      </div>
    </>
  )
}
