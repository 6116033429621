import Link from 'next-translate-routes/link'
import React, { useState } from 'react'

import { IHeaderMenuContent, IHeaderMenuRoot } from '../../models/settings'
import { ConditionalLink } from '../building-blocks/banner'
import { PictureTag } from '../helper/picture-tag'

interface HeaderMenuRootProps {
  data: IHeaderMenuRoot
}

export const HeaderMenuRoot = ({ data }: HeaderMenuRootProps) => {
  const [hover, setHover] = useState<boolean>(false)

  return (
    <li
      className={`${
        hover === true ? `bg-green-500` : `bg-white`
      } z-100 group hoverable`}
    >
      <a
        href={`${data.Link ?? '#'}`}
        className="relative block py-6 px-4 font-swDisp text-base font-bold lg:p-6 lg:text-xl"
      >
        {data.Name}
      </a>
      <div
        className={`py-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-white group-hover:block hidden`}
      >
        <div className="container mx-auto flex w-full flex-wrap justify-between">
          {data &&
            data.children &&
            data.children.map((child: IHeaderMenuContent) => {
              return (
                <ul
                  className="mx-auto w-1/5  px-4 py-6 text-center lg:border-b-0 lg:pt-3"
                  key={child.id}
                >
                  <ConditionalLink
                    link={`/${
                      child.ReferenzSeite?.classname?.includes('Category')
                        ? 'kategorie/'
                        : ''
                    }${child.ReferenzSeite?.Slug}`}
                    shouldShowLink={child.ReferenzSeite?.Slug !== undefined}
                  >
                    <a>
                      {child.HeroImage && (
                        <PictureTag
                          imageUrl={child.HeroImage.fullpath}
                          sizesVW="10"
                          className="mx-auto py-4 text-center"
                          hasFullSize={false}
                        />
                      )}
                      <h3
                        className={`font-bold border-b border-gray-600  font-swDisp text-center text-xl  relative mb-2 hover:text-9`}
                      >
                        {child.Name}
                      </h3>
                    </a>
                  </ConditionalLink>

                  {child.SubMenuItem &&
                    child.SubMenuItem.map((subMenu) => (
                      <li
                        className="text-center font-swDisp font-bold"
                        onClick={() => setHover(false)}
                        key={subMenu.id}
                      >
                        <Link
                          href={`/${
                            subMenu?.classname?.includes('Category')
                              ? 'kategorie/'
                              : subMenu?.classname?.includes('Recipe')
                              ? 'rezepte/'
                              : ''
                          }${subMenu.Slug}`}
                        >
                          <a>
                            <p className={`hover:text-9`}>{subMenu.Name}</p>
                          </a>
                        </Link>
                      </li>
                    ))}
                </ul>
              )
            })}
        </div>
      </div>
    </li>
  )
}
