import Link from 'next-translate-routes/link'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect, useState } from 'react'

import { loadProduct } from '../../graph/get-product'
import useBreakpoints from '../../hooks/use-breakpoints'
import { IProduct } from '../../models/product'
import { ReviewMiniView } from '../../pages/produkt/[slug]'
import { shopContext } from '../../providers/shop-context'
import CtaButtons from '../components/cta-buttons'
import { ProductPrice } from '../components/product-box'
import { WishlistButton } from '../components/wishlist-button'
import { PictureTag } from '../helper/picture-tag'

export const RecommendationBox = ({ productNumber }) => {
  const [data, setData] = useState<IProduct>(undefined)
  const { locale } = useRouter()
  useEffect(() => {
    loadProduct(productNumber, locale)
      .then((p) => setData(p))
      .catch((err) => console.log(err))
  }, [])

  const [selectedVariant, setSelectedVariant] = useState<IProduct>()

  const { getIdForShopwareProduct, shopwareProducts } = useContext(shopContext)

  const [shopwareProduct, setShopwareProduct] = useState<any>()

  useEffect(() => {
    setShopwareProduct(getIdForShopwareProduct(selectedVariant || data))
  }, [shopwareProducts, data, selectedVariant])

  useEffect(() => {
    if (data) {
      setSelectedVariant(
        data.children &&
          data.children.filter((child) => child.classname === 'Product')
            ?.length > 0
          ? (data.children
              .filter((child) => child.classname === 'Product')
              ?.find((x) =>
                x.OSProductNumber?.toLowerCase().includes('xm')
              ) as IProduct) ??
              (data.children.filter(
                (child) => child.classname === 'Product'
              )[0] as IProduct)
          : data
      )
    }
  }, [data])

  const { isXs } = useBreakpoints()
  return (
    <>
      {data &&
        shopwareProduct &&
        shopwareProduct?.price?.length > 0 &&
        shopwareProduct?.price[0]?.gross > 0 && (
          <>
            <div
              className={`h-full flex flex-col flex-grow justify-content items-center content-center p-2 hover:shadow-lg`}
            >
              <Link href={`/produkt/${data.Slug}`}>
                <div
                  className={`mx-auto flex mb-auto flex-grow flex-0 cursor-pointer relative ${'bg-product-bg'} dark:bg-transparent`}
                >
                  <div>
                    {isXs && data.Sale && data.Sale[0] && (
                      <>
                        {(data.Sale[0].Shop === undefined ||
                          data.Sale[0].Shop?.find(
                            (x) =>
                              x.id.toString() ===
                              process.env.NEXT_PUBLIC_SHOPWARE_ID
                          )) && (
                          <div
                            className={`rotate-90 absolute origin-bottom-left -mt-4 z-30 px-4  bg-${data.Sale[0].Farbe}`}
                          >
                            <div className="m-auto flex font-bold sm:text-2xl">
                              {data.Sale[0].Name}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <PictureTag
                      imageUrl={
                        (selectedVariant?.DosenBild &&
                          selectedVariant?.DosenBild.fullpath) ??
                        (data.Images && data?.Images[0]?.image?.fullpath) ??
                        '/sonstiges/failover.png'
                      }
                      sizesVW={`100`}
                      className={`w-full sm:w-9/12 mx-auto p-4`}
                      altText={
                        selectedVariant?.DosenBild?.alt ?? data.ArticleName
                      }
                    />
                  </div>
                  <WishlistButton productId={shopwareProduct?.id} />
                </div>
              </Link>
              <div className="mt-auto w-full pt-4 grid grid-cols-1 sm:grid-cols-2">
                <div className={`flex flex-col`}>
                  <div className={`flex-col flex sm:hidden`}>
                    <ReviewMiniView data={data} />
                    {shopwareProduct && (
                      <div className={`sm:text-right ml-auto hidden sm:flex`}>
                        <ProductPrice shopwareProduct={shopwareProduct} />
                      </div>
                    )}
                  </div>
                  <Link href={`/produkt/${data.Slug}`}>
                    <a>
                      <p
                        className={`relative sm:mx-auto text-sm sm:text-base italic font-bold max-w-full
                        } font-swDisp dark:text-white h-12 sm:h-auto`}
                      >
                        {data.ArticleName}
                      </p>
                    </a>
                  </Link>
                </div>
                {shopwareProduct && (
                  <div className={`sm:text-right ml-auto flex sm:hidden`}>
                    <ProductPrice shopwareProduct={shopwareProduct} />
                  </div>
                )}
                <div className={` flex-col hidden sm:flex`}>
                  <ReviewMiniView data={data} />
                  {shopwareProduct && (
                    <div className={`sm:text-right ml-auto`}>
                      <ProductPrice shopwareProduct={shopwareProduct} />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-auto w-full">
                {shopwareProduct && (
                  <CtaButtons
                    data={selectedVariant}
                    rawData={data}
                    shopwareProduct={shopwareProduct}
                    isAffiliate={true}
                    isSmall={true}
                  />
                )}
              </div>
            </div>
          </>
        )}
    </>
  )
}
