import { OrderLineItem } from '@shopware-pwa/commons/interfaces/models/checkout/order/OrderLineItem'
import { languageContext } from 'context/language-context'
import { loadProduct } from 'graph/get-product'
import useBreakpoints from 'hooks/use-breakpoints'
import { IProduct } from 'models/product'
import Link from 'next-translate-routes/link'
import React, { useContext, useEffect } from 'react'

import { formatter } from '../../helper/currency-formatter'
import IconBasket from '../../icons/icon-basket'
import { shopContext } from '../../providers/shop-context'
import { PictureTag } from '../helper/picture-tag'

export interface LineItemProps {
  lineItem: OrderLineItem
}

export interface PriceDefinitionInterface {
  price: number
  quantity: number
}

export const OrderLineItemRow = ({ lineItem }: LineItemProps) => {
  const { currentLanguage } = useContext(languageContext)

  const [data, setData] = React.useState<IProduct>(undefined)

  useEffect(() => {
    // @ts-ignore
    loadProduct(lineItem.payload.productNumber, currentLanguage, 'variant')
      .then((pData) => {
        setData(pData)
      })
      .catch((err) => console.log(err))
  }, [])

  const { addToBasket } = useContext(shopContext)

  const { isXs } = useBreakpoints()

  return (
    <div
      className={`relative  flex items-center flex-row pb-2 min-h-32 flex-wrap`}
    >
      <div className="w-1/2 sm:w-1/12">
        {data && data.Images && data.Images.length > 0 && (
          <PictureTag
            imageUrl={data.Images[0]?.image.fullpath}
            className="z-0 m-auto"
            sizesVW="7"
            altText={data.Images[0]?.image.alt}
          />
        )}
      </div>
      <div className="flex w-1/2 flex-col sm:w-1/5">
        <Link href={`/produkt/${data?.Slug}`}>
          <a>
            <h3 className="font-swDisp text-base font-bold italic">
              {data?.ArticleName}
            </h3>
          </a>
        </Link>
        {data?.PackagingRelation?.length > 0 && (
          <p
            className="font-swText"
            dangerouslySetInnerHTML={{
              __html: data?.PackagingRelation[0]?.PackagingName,
            }}
          />
        )}
        <p className="font-swText italic text-gray-400">
          ArtNr. {data?.OSProductNumber}
        </p>
        {isXs && (
          <div className={`w-full flex flex-col `}>
            <h3 className="ml-auto font-swDisp text-2xl font-bold italic">
              <span className="pr-4 text-base font-normal">
                {lineItem.quantity} x{' '}
                {formatter.format(lineItem.unitPrice ?? 0)}
              </span>
              {formatter.format(lineItem.totalPrice ?? 0)}
            </h3>
          </div>
        )}
      </div>

      {!isXs && (
        <>
          <div className={`w-1/5 flex flex-col `}>
            <h3 className="ml-auto font-swDisp text-2xl font-bold italic">
              {lineItem.quantity}
            </h3>
          </div>

          <div className={`w-1/5 flex flex-col `}>
            <h3 className="ml-auto font-swDisp text-2xl font-bold italic">
              {formatter.format(lineItem.totalPrice ?? 0)}
            </h3>
            <p className="ml-auto">
              {formatter.format(lineItem.unitPrice ?? 0)}
            </p>
          </div>
          <div className={`w-1/5 flex flex-col mx-auto `}>
            <button onClick={() => addToBasket((lineItem as any).productId)}>
              <IconBasket
                className={`mx-auto hover:text-9  cursor-pointer`}
                width={30}
              />
            </button>
          </div>
        </>
      )}
    </div>
  )
}
