import React, { FC } from 'react'

interface IProps {
  className?: string
  width?: number
  children?: any
}

const IconHeart: FC<IProps> = ({ children, className, width = 40 }: IProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox="0 0 24.407 21.674"
        className={className}
      >
        <g id="Icon" transform="translate(1.497 1.5)">
          <path
            id="Path"
            d="M6.925,1a7.089,7.089,0,0,1,5.045,2.09L12,3.114l.025-.025a7.135,7.135,0,1,1,10.09,10.09l-1.087,1.086-7.968,7.969a1.5,1.5,0,0,1-2.121,0L1.88,13.179A7.135,7.135,0,0,1,6.925,1ZM12,6.735A1.5,1.5,0,0,1,10.935,6.3L9.849,5.21A4.135,4.135,0,0,0,4,11.057l1.086,1.085L12,19.051l7.995-7.995A4.135,4.135,0,0,0,14.142,5.21L13.056,6.3A1.5,1.5,0,0,1,12,6.735Z"
            transform="translate(-1.29 -2.499)"
          />
        </g>
      </svg>
      {children}
    </>
  )
}

export default IconHeart
