import React from 'react'

import { IProduct } from '../../models/product'
import ProductBox from './product-box'

interface ProductOverviewProps {
  data: IProduct[]
}

export const ProductOverview = ({ data }: ProductOverviewProps) => {
  return (
    <div className="flex grid grid-cols-2 sm:grid-cols-3">
      {data &&
        data.map((product) => (
          <ProductBox showPrize={false} showCta={false} data={product} />
        ))}
    </div>
  )
}
