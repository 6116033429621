import { TextField } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect } from 'react'

import useBreakpoints from '../../hooks/use-breakpoints'
import { IProduct } from '../../models/product'
import { shopContext } from '../../providers/shop-context'

export interface CtaButtonProps {
  data: IProduct
  className?: string
  withWishlist?: boolean
  amount?: number
  rawData?: IProduct

  shopwareProduct?: any
  isAffiliate?: boolean
  isSmall?: boolean
}

export const ctaButtons = ({
  data,
  className,
  withWishlist,
  rawData,
  amount = 1,
  shopwareProduct,
  isAffiliate = false,
  isSmall = false,
}: CtaButtonProps) => {
  const {
    addToBasket,
    getIdForShopwareProduct,
    productIsOnWishlist,
    toggleWishlistProduct,
    getProductDelivery,
    getGroupPrice,
    setShowLogin,
    isLoggedIn,
  } = useContext(shopContext)

  const { t } = useTranslation('common')

  const [buyable, setBuyable] = React.useState(false)

  const [recipient, setRecipient] = React.useState('')
  const [sender, setSender] = React.useState('')

  const router = useRouter()

  const { isXs } = useBreakpoints()

  useEffect(() => {
    if (shopwareProduct?.isCloseout === true) {
      console.log(data.IsActive)
      getProductDelivery(shopwareProduct?.id)
        .then((resp) => setBuyable(resp && data.IsActive))
        .catch(() => setBuyable(data.IsActive))
    } else {
      setBuyable(data.IsActive)
    }
  }, [shopwareProduct])

  return (
    <div className={`flex flex-col w-full`}>
      {rawData?.OSProductNumber.startsWith('GS00x') && (
        <div className={`flex gap-2 w-full pb-4`}>
          <div className={`w-full`}>
            <TextField
              type="text"
              variant="standard"
              className={`w-full`}
              label={`Versender`}
              onChange={(x) => setSender(x.target.value)}
            />
          </div>
          <div className={`w-full`}>
            <TextField
              type="text"
              variant="standard"
              className={`w-full`}
              label={`Empfänger`}
              onChange={(x) => setRecipient(x.target.value)}
            />
          </div>
        </div>
      )}

      <div className={` flex gap-2 w-full`}>
        <button
          type="button"
          className={`${className} w-full p-2 py-4 ${
            isSmall ? 'text-sm sm:text-base' : ''
          } flex font-bold justify-center border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed`}
          disabled={buyable === false}
          onClick={() =>
            addToBasket(
              getIdForShopwareProduct(data)?.id,
              rawData,
              amount,
              recipient,
              sender,
              isAffiliate,
              router.asPath
            )
          }
        >
          {!isSmall && isXs && (
            <img className={`inline-block w-4 mr-2`} src={`/icons/cart.png`} />
          )}
          {buyable === false ? t('NOT_AVAILABLE') : t('TO_BASKET')}
        </button>
        {withWishlist && withWishlist === true && (
          <button
            className={`ml-auto border border-black fill-current px-2 font-swDisp text-xl font-bold hover:bg-aktion sm:px-4 ${
              productIsOnWishlist(shopwareProduct?.id)
                ? 'bg-red-500'
                : 'bg-white'
            }`}
            onClick={() =>
              isLoggedIn
                ? toggleWishlistProduct(getIdForShopwareProduct(data)?.id)
                : setShowLogin(true)
            }
          >
            <img src="/icons/Heart.svg" width="20" height="20" className={``} />
          </button>
        )}
      </div>
    </div>
  )
}

export default ctaButtons
