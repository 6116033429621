import { gql, request } from 'graphql-request'
import { IHeader, ITopHeader } from 'models/settings'
import { useQuery } from 'react-query'

const endpoint = process.env.NEXT_PUBLIC_GRAPH_URL

export function useHeader(currentLanguage: string) {
  return useQuery([`${currentLanguage}HEADER`], async () => {
    const data = await request(
      endpoint,
      gql`
        query ($language: String, $headerNode: Int) {
          getHeader(defaultLanguage: $language, id: $headerNode) {
            id
            classname
            TrustText
            children {
              ... on object_HeaderMenuRoot {
                id
                Name
                Link
                children {
                  ... on object_HeaderMenuContent {
                    id
                    HeroImage {
                      fullpath
                    }
                    ReferenzSeite {
                      ... on object_LandingPage {
                        id
                        classname
                        Slug: slug
                        Name
                      }

                      ... on object_CategoryVirtual {
                        id
                        classname
                        Slug: slug
                        Name: name
                      }

                      ... on object_CategoryShop {
                        id
                        classname
                        Slug
                        Name
                      }
                    }
                    Name
                    SubMenuItem {
                      ... on object_LandingPage {
                        id
                        classname
                        Slug: slug
                        Name
                      }

                      ... on object_CategoryVirtual {
                        id
                        classname
                        Slug: slug
                        Name: name
                      }

                      ... on object_CategoryShop {
                        id
                        classname
                        Slug
                        Name
                      }

                      ... on object_RecipeVirtual {
                        id
                        classname
                        Slug: slug
                        Name: name
                      }
                    }
                  }
                }
              }
            }
            Logo {
              image {
                id
                fullpath
              }
            }
          }
        }
      `,
      {
        language: currentLanguage,
        headerNode: Number.parseInt(process.env.NEXT_PUBLIC_HEADER),
      }
    )

    return data.getHeader as IHeader
  })
}

export function useTopHeader(currentLanguage: string) {
  return useQuery([`${currentLanguage}-TOPHEADER`], async () => {
    const data = await request(
      endpoint,
      gql`
        query ($language: String, $headerNode: Int) {
          getTopHeader(defaultLanguage: $language, id: $headerNode) {
            id
            classname
            Farbe
            Headline
            Aktiv
            Landingpage {
              ... on object_LandingPage {
                Slug: slug
              }
            }
          }
        }
      `,
      {
        language: currentLanguage,
        headerNode: Number.parseInt(process.env.NEXT_PUBLIC_TOP_HEADER),
      }
    )

    return data.getTopHeader as ITopHeader
  })
}
