import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRouter } from "next-translate-routes/router"
import { useTranslation } from "next-i18next"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { shopContext } from "../../providers/shop-context"
import { CustomInputField } from "./address-upsert-box"

interface LoginBoxProps {
  showLogin?: any
  setShowLogin?: any
  setLoginState?: any
}

export const PasswordChange = ({ showLogin, setShowLogin }: LoginBoxProps) => {
  const { changePassword } = useContext(shopContext)

  const { t } = useTranslation("common")

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const router = useRouter()

  const [recoveryMessage, setRecoveryMessage] = useState(undefined)

  const onSubmit = async (data) => {
    const changeMessage = await changePassword(data)

    setRecoveryMessage(changeMessage)
  }

  function handleKeyInput(evt: any) {
    if (evt.key === "Escape") {
      setShowLogin(false)
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", (evt) => {
      handleKeyInput(evt)
    })
  }, [])

  return (
    <>
      <div className={`flex flex-row`}>
        <h1 className={`text-4xl font-swDisp italic font-bold mr-auto`}>
          {t("PASSWORD_CHANGE")}
        </h1>
        <button
          className="ml-auto"
          type="button"
          onClick={() => setShowLogin(false)}
        >
          <FontAwesomeIcon
            className="w-8 text-xxl hover:text-9"
            icon={faXmark}
          />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="py-8">
        <div className="flex flex-col space-y-8">
          {recoveryMessage === undefined && (
            <>
              <CustomInputField
                label={t("OLD_PASSWORD")}
                name={`old_password`}
                register={register}
                type={`password`}
                required={true}
              />
              <CustomInputField
                label={t("NEW_PASSWORD")}
                name={`password`}
                register={register}
                type={`password`}
                required={true}
              />
              <CustomInputField
                label={t("NEW_PASSWORD_REPEAT")}
                name={`password_repeat`}
                register={register}
                type={`password`}
                required={true}
              />
            </>
          )}
          {recoveryMessage && (
            <span className="text-red-500">{recoveryMessage}</span>
          )}
          <div className="w-full items-center sm:inline-flex">
            <div className="mr-auto">
              <button
                className="w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white sm:w-64"
                type="submit"
              >
                {t("CHANGE_PASSWORD")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
