import { gql, request } from 'graphql-request'
import { useQuery } from 'react-query'

import { IAccount } from '../models/account'
import {
  DeclarationIconsFragment,
  LandingPageTreeFragment,
} from './graph-fragments'

const endpoint = process.env.NEXT_PUBLIC_GRAPH_URL

export function useProductElements(currentLanguage: string) {
  return useQuery([`${currentLanguage}product-generic`], async () => {
    const productElements = await request(
      endpoint,
      gql`
        query ($language: String) {
          getAccount(defaultLanguage: $language, id: 76446) {
            classname
            id
            Icons {
              ...DeclarationIconsFragment
            }
            children {
              ...LandingPageTreeFragment
            }
          }
        }
        ${LandingPageTreeFragment}
        ${DeclarationIconsFragment}
      `,
      {
        language: currentLanguage,
      }
    )

    return productElements.getAccount as IAccount
  })
}
