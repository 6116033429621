import { DynamicHeadline } from 'components/text/base-text';
import Link from 'next-translate-routes/link';
import React from 'react';

import { IBanner } from '../../models/landingpage';

export interface BannerProps {
  data: IBanner
}

export interface ConditionalLinkProps {
  link?: string | undefined
  shouldShowLink: boolean
  children?: JSX.Element | JSX.Element[]
  className?: string
}

export const ConditionalLink = ({
  link,
  shouldShowLink,
  children,
  className,
}: ConditionalLinkProps) => {
  return shouldShowLink && link !== undefined ? (
    <Link href={link.startsWith('/') ? link : `/${link}`}>
      <a className={className}>{children}</a>
    </Link>
  ) : (
    <>{children}</>
  )
}

export const Banner = ({ data }: BannerProps) => {
  const crop = data.BackgroundImage?.crop

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12">
        <div className="has-overlay relative h-full w-full">
          <ConditionalLink
            link={`/${data?.Relation?.Slug}`}
            shouldShowLink={data.Relation?.Slug !== undefined}
          >
            <div
              className={`w-full h-full m-auto bg-opacity-50 bg-black dark:bg-white transition-all`}
              style={{
                backgroundImage: `url(https://spiceworld.azureedge.net/?image=${data.BackgroundImage?.image.fullpath}&cw=${crop?.cropWidth}&cl=${crop?.cropLeft}&ct=${crop?.cropTop}&ch=${crop?.cropHeight}&bg=1)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div
                className={`w-full ${
                  data.Relation?.Slug !== undefined ? `block` : `hidden`
                } absolute h-full bg-black bg-opacity-25 overlay`}
              />
              <DynamicHeadline
                className="py-48 text-center font-swDisp text-3xl font-bold uppercase italic text-white dark:text-black sm:text-6xl"
                text={data.Title}
                tagType={data.tagType}
              />
            </div>
          </ConditionalLink>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Banner)