import { useTranslation } from 'next-i18next'
import React, { useContext } from 'react'

import { shopContext } from '../../providers/shop-context'

export const PersonalInformation = () => {
  const { userInformation } = useContext(shopContext)

  const { t } = useTranslation('common')

  return (
    <div className={`py-4`}>
      <p className={`text-lg  font-swText`}>
        {t('YOUR_NAME')}: {userInformation.firstName} {userInformation.lastName}
      </p>

      <p className={`text-lg font-swText`}>
        {t('YOUR_EMAIL')}: {userInformation.email}
      </p>
    </div>
  )
}
