import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'next-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { shopContext } from '../../providers/shop-context'
import { CustomInputField } from './address-upsert-box'

interface LoginBoxProps {
  showLogin?: any
  setShowLogin?: any
  setLoginState?: any
}

export const EmailChange = ({ showLogin, setShowLogin }: LoginBoxProps) => {
  const { changeMail } = useContext(shopContext)

  const { t } = useTranslation('common')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [recoveryMessage, setRecoveryMessage] = useState(undefined)

  const onSubmit = async (data) => {
    const changeMessage = await changeMail(data)

    setRecoveryMessage(changeMessage)
  }

  function handleKeyInput(evt: any) {
    if (evt.key === 'Escape') {
      setShowLogin(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', (evt) => {
      handleKeyInput(evt)
    })
  }, [])

  return (
    <>
      <div className={`flex flex-row`}>
        <h1 className={`text-4xl font-swDisp italic font-bold mr-auto`}>
          {t('E_MAIL')}
        </h1>
        <button
          className="ml-auto"
          type="button"
          onClick={() => setShowLogin(false)}
        >
          <FontAwesomeIcon
            className="w-8 text-xxl hover:text-9"
            icon={faXmark}
          />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="py-8">
        <div className="flex flex-col space-y-8">
          {recoveryMessage === undefined && (
            <>
              <CustomInputField
                label={t('PASSWORD')}
                name={`password`}
                register={register}
                type={`password`}
                required={true}
              />
              <CustomInputField
                label={t('NEW_EMAIL')}
                name={`email`}
                register={register}
                type={`text`}
                required={true}
              />
              <CustomInputField
                label={t('NEW_EMAIL_REPEAT')}
                name={`email_confirmation`}
                register={register}
                type={`text`}
                required={true}
              />
            </>
          )}
          {recoveryMessage && (
            <span className="text-red-500">{recoveryMessage}</span>
          )}
          <div className="w-full items-center sm:inline-flex">
            <div className="mr-auto">
              <button
                className="w-full cursor-pointer border border-black bg-white px-6 py-3 font-swText font-bold italic text-black hover:border-9 hover:bg-9 hover:text-white sm:w-64"
                type="submit"
              >
                {t('CHANGE_EMAIL')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
