import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import useBreakpoints from '../../hooks/use-breakpoints'
import { IPdfDocument } from '../../models/landingpage'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export const PdfViewer = ({ Headline, PDFDocument }: IPdfDocument) => {
  const [numPages, setNumPages] = useState(null)

  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const { isXs } = useBreakpoints()

  return (
    <div className="container mx-auto flex w-full max-w-full flex-col">
      <h2 className={`font-swDisp text-4xl mx-auto italic font-bold py-8`}>
        {Headline}
      </h2>
      {!isXs && (
        <>
          <div className="container mx-auto flex w-full flex-row justify-center gap-x-4 pb-8">
            {PDFDocument && (
              <Document
                file={`/api/pdf/load?url=https://pim.stayspiced.com${PDFDocument.fullpath}`}
                onLoadSuccess={onDocumentLoadSuccess}
                className={`max-w-full`}
                renderMode={`svg`}
              >
                <Page
                  className={`max-w-full`}
                  scale={1.5}
                  pageNumber={pageNumber}
                />
              </Document>
            )}
          </div>
          <div className={`flex flex-row mx-auto container`}>
            <button
              type="button"
              disabled={pageNumber === 1}
              onClick={() => setPageNumber(pageNumber - 1)}
              className=" mx-auto inline-block w-32 border border-black py-2 text-center font-swDisp font-bold text-black hover:bg-black hover:text-white disabled:border-gray-400 disabled:text-gray-400 disabled:hover:cursor-no-drop disabled:hover:bg-white "
            >
              Zurück
            </button>
            <a
              href={`https://pim.stayspiced.com${PDFDocument.fullpath}`}
              target={`_blank`}
              className=" mx-auto inline-block w-32 border border-black py-2 text-center font-swDisp font-bold text-black hover:bg-black hover:text-white disabled:border-gray-400 disabled:text-gray-400 disabled:hover:cursor-no-drop disabled:hover:bg-white "
            >
              PDF öffnen
            </a>
            <button
              type="button"
              disabled={pageNumber === numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
              className=" mx-auto inline-block w-32 border border-black py-2 text-center font-swDisp font-bold text-black hover:bg-black hover:text-white disabled:border-gray-400 disabled:text-gray-400 disabled:hover:cursor-no-drop disabled:hover:bg-white"
            >
              Vor
            </button>
          </div>
        </>
      )}
      {isXs && (
        <>
          <div className="container mx-auto flex w-full flex-row justify-center gap-x-4 pb-8">
            {PDFDocument && (
              <Document
                file={`/api/pdf/load?url=https://pim.stayspiced.com${PDFDocument.fullpath}`}
                onLoadSuccess={onDocumentLoadSuccess}
                className={`max-w-full`}
                renderMode={`svg`}
              >
                <Page
                  className={`max-w-full`}
                  scale={0.7}
                  pageNumber={pageNumber}
                />
              </Document>
            )}
          </div>
          <div className={`flex flex-row mx-auto container`}>
            <a
              href={`https://pim.stayspiced.com${PDFDocument.fullpath}`}
              target={`_blank`}
              className=" mx-auto inline-block w-32 border border-black py-2 text-center font-swDisp font-bold text-black hover:bg-black hover:text-white disabled:border-gray-400 disabled:text-gray-400 disabled:hover:cursor-no-drop disabled:hover:bg-white "
            >
              PDF öffnen
            </a>
          </div>
        </>
      )}
    </div>
  )
}
