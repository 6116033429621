import React, { useContext, useEffect, useState } from 'react'

import { languageContext } from '../../context/language-context'
import { useFooter } from '../../graph/get-footer'

export const PaymentLogos = () => {
  const sizeClass = 'w-[3.4rem] sm:w-[4.6rem]'
  const { currentLanguage } = useContext(languageContext)

  const { data } = useFooter(currentLanguage)

  const [hasApplePay, setHasApplePay] = useState(false)

  useEffect(() => {
    // @ts-ignore
    if (window && window.ApplePaySession) {
      setHasApplePay(true)
    }
  }, [window])

  return (
    <div className="mx-auto sm:ml-auto flex sm:flex flex-wrap">
      {data &&
        data.PaymentIcons &&
        data.PaymentIcons.map((img) => (
          <img
            src={`https://pim.stayspiced.com${img.image.fullpath}`}
            className={sizeClass}
            key={img.image.filename}
          />
        ))}
    </div>
  )
}
