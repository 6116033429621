import { faTruck } from '@fortawesome/pro-light-svg-icons'
import { faStar, faStarHalf } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { Breadcrumb } from 'components/components/breadcrumb'
import { PaymentLogos } from 'components/components/payment-logos'
import { ProductPrice } from 'components/components/product-box'
import Layout from 'components/layout'
import { ImageSlider } from 'components/slider/image-slider'
import { loadProduct } from 'graph/get-product'
import _, { isArray } from 'lodash'
import { Image, IProduct, ValueForIdentifier } from 'models/product'
import Head from 'next/head'
import { GetStaticPaths } from 'next/types'
import { Trans, useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next-translate-routes/router'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { ConditionalLink } from '../../components/building-blocks/banner'
import { ComponentFactory } from '../../components/component-factory'
import CtaButtons from '../../components/components/cta-buttons'
import { RefLang } from '../../components/components/RefLang'
import { WishlistButton } from '../../components/components/wishlist-button'
import { UrlBuilder } from '../../components/helper/picture-tag'
import { ProductContentAlternative } from '../../components/product/product-content-alternative'
import { ProductContentDefault } from '../../components/product/product-content-default'
import { useProductElements } from '../../graph/get-product-elements'
import { formatter } from '../../helper/currency-formatter'
import { trackItemView } from '../../helper/gtag'
import useBreakpoints from '../../hooks/use-breakpoints'
import { IDeclarationIcon } from '../../models/settings'
import { shopContext } from '../../providers/shop-context'

interface LinkElementProps {
  title: any
  anchor: string
  offset: number
  setSticky?: any
  show: boolean
  step?: number
}

export const LinkElement = ({
  title,
  anchor,
  setSticky,
  offset,
  show,
}: LinkElementProps) => {
  return (
    <>
      {show && (
        <Link
          activeClass="active text-9"
          className="text-swDisp cursor-pointer text-xs hover:text-9 sm:text-lg sm:font-bold"
          to={anchor}
          spy
          smooth
          offset={offset}
          duration={500}
          onSetActive={() => setSticky(true)}
        >
          {title}
        </Link>
      )}
    </>
  )
}

export const LinkElementWithoutFunction = ({
  title,
  anchor,
  offset,
  show,
  setSticky,
  step,
}: LinkElementProps) => {
  return (
    <>
      {show && (
        <Link
          activeClass=""
          className="font-bold font-swDisp italic"
          to={anchor}
          spy
          smooth
          offset={offset}
          duration={500}
          onSetActive={() => setSticky(step)}
        >
          {title}
        </Link>
      )}
    </>
  )
}

export interface ReviewContainerProps {
  data: IProduct
}

export interface ReviewDetailProps {
  review: any
}

export const ReviewDetail = ({ review }: ReviewDetailProps) => {
  return (
    <div className={`flex flex-col py-4 border-black border-b`}>
      <div className={`w-full flex items-center justify-center`}>
        <span className="mr-auto w-9/12 font-swText text-base font-bold">
          {review.title}
        </span>
        <div className={`flex flex-col`}>
          <span className={`ml-auto flex`}>
            {Array.from({ length: 5 }, (v, i) => {
              if (i + 1 <= Math.floor(review?.rating)) {
                return (
                  <FontAwesomeIcon
                    className="w-4 text-lg text-base"
                    icon={faStar}
                  />
                )
              }
              if (
                review?.rating % 1 !== 0 &&
                i + 1 === Math.ceil(review?.rating)
              ) {
                if (review?.rating % 1 > 0.5) {
                  return (
                    <FontAwesomeIcon
                      className="w-4 text-lg text-base"
                      icon={faStar}
                    />
                  )
                }
                return (
                  <FontAwesomeIcon
                    className="w-4 text-base text-black"
                    icon={faStarHalf}
                  />
                )
              }
              return (
                <FontAwesomeIcon
                  className="w-4 text-base text-gray-200"
                  icon={faStar}
                />
              )
            })}
          </span>
        </div>
      </div>

      <div className={`flex`}>
        <div className={`flex flex-col w-3/4`}>
          <p
            className={`text-base`}
            dangerouslySetInnerHTML={{ __html: review.text }}
          />
          {review?.comment && (
            <p
              className={`text-sm italic pt-2`}
              dangerouslySetInnerHTML={{ __html: review.comment }}
            />
          )}
          <p className={`text-xs pt-4 font-bold italic `}>{review.name}</p>
        </div>
        {review?.trusted === true && (
          <a
            href={`https://www.trustedshops.de/bewertung/info_XD9D9D3ED276967D44E1B987A3F8FEA6D.html`}
            className={`ml-auto h-12 w-12`}
            target={`_blank`}
          >
            <img
              src={`https://pim.stayspiced.com/TrustedShops-1.png`}
              className={` w-full`}
            />
          </a>
        )}
      </div>
    </div>
  )
}

interface ReviewMiniViewProps {
  data: IProduct
}

export const ReviewMiniView = ({ data }: ReviewMiniViewProps) => {
  const [reviewRating, setReviewRating] = useState(undefined)
  const [reviewCounter, setReviewCounter] = useState(undefined)

  useEffect(() => {
    setReviewCounter(data?.Reviews?.length)
    setReviewRating(
      _.sumBy(data?.Reviews, (rating) => rating.Rating) /
        (data?.Reviews?.length ?? 1)
    )
  }, [data])

  useEffect(() => {
    if (reviewCounter === 0) {
      axios
        .get(
          `https://pim.stayspiced.com/spiceworld/reviews/${data.OSProductNumber}`
        )
        .then((res) => {
          setReviewRating(res.data.median)
          setReviewCounter(res.data.count)
        })
        .catch((err) => {})
    }
  }, [reviewCounter])

  if (reviewCounter === 0) {
    return <span className={`ml-auto flex h-6`}></span>
  }

  return (
    <span className={`sm:ml-auto flex justify-center items-center`}>
      {Array.from({ length: 5 }, (v, i) => {
        if (i + 1 <= Math.floor(reviewRating)) {
          return (
            <FontAwesomeIcon
              className="w-4 sm:w-6 text-xl text-black"
              icon={faStar}
            />
          )
        }
        if (reviewRating % 1 !== 0 && i + 1 === Math.ceil(reviewRating)) {
          if (reviewRating % 1 > 0.5) {
            return (
              <FontAwesomeIcon
                className="w-4 sm:w-6 text-xl text-black"
                icon={faStar}
              />
            )
          }
          return (
            <FontAwesomeIcon
              className="w-4 sm:w-6 text-xl text-black"
              icon={faStarHalf}
            />
          )
        }
        return (
          <FontAwesomeIcon
            className="w-4 sm:w-6 text-xl text-gray-200"
            icon={faStar}
          />
        )
      })}
      <span className={`text-sm sm:text-base`}>({reviewCounter})</span>
    </span>
  )
}

export const ReviewContainer = ({ data }: ReviewContainerProps) => {
  const [reviewRating, setReviewRating] = useState(undefined)
  const [reviewCounter, setReviewCounter] = useState(undefined)
  useEffect(() => {
    axios
      .get(
        `https://pim.stayspiced.com/spiceworld/reviews/${data.OSProductNumber}`
      )
      .then((res) => {
        setReviewRating(res.data.median)
        setReviewCounter(res.data.count)
      })
      .catch((err) => {})
  }, [data])

  return (
    <Link
      activeClass="active text-9"
      className="text-swDisp cursor-pointer text-xs hover:text-9 sm:text-lg sm:font-bold ml-auto"
      to={'reviews'}
      spy
      smooth
      offset={-240}
      duration={500}
    >
      <span className={`ml-auto flex items-center justify-center`}>
        {Array.from({ length: 5 }, (v, i) => {
          if (i + 1 <= Math.floor(reviewRating)) {
            return (
              <FontAwesomeIcon
                className="w-6 text-xl text-black"
                icon={faStar}
              />
            )
          }
          if (reviewRating % 1 !== 0 && i + 1 === Math.ceil(reviewRating)) {
            if (reviewRating % 1 > 0.5) {
              return (
                <FontAwesomeIcon
                  className="w-6 text-xl text-black"
                  icon={faStar}
                />
              )
            }
            return (
              <FontAwesomeIcon
                className="w-6 text-xl text-black"
                icon={faStarHalf}
              />
            )
          }
          return (
            <FontAwesomeIcon
              className="w-6 text-xl text-gray-200"
              icon={faStar}
            />
          )
        })}
        ({reviewCounter})
      </span>
    </Link>
  )
}

export const translateString = (text: string) => {
  return <Trans>{text}</Trans>
}

interface IDeliveryProps {
  shopwareProduct: any
}

const DeliveryInfo = ({ shopwareProduct }: IDeliveryProps) => {
  const { t } = useTranslation('common')

  const stockState =
    shopwareProduct.availableStock > 10
      ? 'STOCK_GREEN'
      : shopwareProduct.availableStock > 0
      ? 'STOCK_YELLOW'
      : !(shopwareProduct.isCloseout === true)
      ? 'STOCK_ORANGE'
      : 'STOCK_RED'

  const stockColor =
    stockState === 'STOCK_GREEN'
      ? 'bg-green-500'
      : stockState === 'STOCK_YELLOW'
      ? 'bg-yellow-500'
      : stockState === 'STOCK_ORANGE'
      ? 'bg-orange-500'
      : 'bg-red-500'

  return (
    <div className={`grid grid-cols-1 gap-y-6 sm:gap-y-0 sm:grid-cols-2 pt-4`}>
      <p className={`flex items-center`}>
        <div
          className={`w-4 h-4 rounded-full ${stockColor} inline-block`}
        ></div>
        <span className={`pl-2 font-swDisp text-xs sm:text-sm`}>
          {t(stockState)}
        </span>
      </p>

      {shopwareProduct && shopwareProduct.availableStock > 0 && (
        <p className="font-swDisp text-xs sm:text-sm flex items-center ">
          <FontAwesomeIcon
            className="text-base sm:text-xl pr-2 sm:px-2"
            icon={faTruck}
          />
          {t('SHIPPING_TIME')} {shopwareProduct?.deliveryTime?.min} -{' '}
          {shopwareProduct?.deliveryTime?.max} {t('DAYS')}
        </p>
      )}
      {shopwareProduct && shopwareProduct.availableStock <= 0 && (
        <p className="font-swDisp text-sm ">
          <FontAwesomeIcon className="text-xl px-2" icon={faTruck} />
          {t('SHIPPING_TIME')}{' '}
          {shopwareProduct?.deliveryTime?.min + shopwareProduct?.restockTime} -{' '}
          {shopwareProduct?.deliveryTime?.max + shopwareProduct?.restockTime}{' '}
          {t('DAYS')}
        </p>
      )}
    </div>
  )
}

interface BulletPointsProps {
  data: IProduct
}

const BulletPoints = ({ data }: BulletPointsProps) => {
  return (
    <div className={`pt-4 `}>
      {data.BulletPoints?.map((bullet) => {
        return (
          <div className={`grid grid-cols-12 py-2`}>
            <span className={`col-span-1 items-center align-middle pt-2`}>
              <img src={`/icons/checkmark.png`} className={`pr-4`} />{' '}
            </span>{' '}
            <p className={`font-swDisp text-base col-span-11`}>
              {bullet.BulletPoint}
            </p>
          </div>
        )
      })}
    </div>
  )
}

const DeclarationIconProduct = (data: IDeclarationIcon) => {
  return (
    <div className="grid grid-cols-12 col-span-6">
      <ConditionalLink
        link={`/${data?.Landingpage?.Slug}`}
        shouldShowLink={data?.Landingpage !== null}
        className={`text-center mx-auto`}
      >
        <div className={`col-span-2 content-center`}>
          <img
            className="mx-auto w-12 pr-2 sm:w-12"
            src={`https://pim.stayspiced.com/${data.Icon.image.fullpath}`}
          />
        </div>
        <div className={`col-span-10 content-center`}>
          <p className="font-swDisp text-xs sm:text-sm">{data.Title}</p>
        </div>
      </ConditionalLink>
    </div>
  )
}

const Index = ({ data, seoSlugs, structuredDataStatic }): JSX.Element => {
  const router = useRouter()

  const [selectedVariant, setSelectedVariant] = useState<IProduct>(undefined)

  const [images, setImages] = useState<Image[]>(undefined)

  const breakpoint = useBreakpoints()

  // const [structuredData, setStructuredData] = useState(structuredDataStatic)
  const [shopwareProduct, setShopwareProduct] = useState<any>(undefined)
  const [tracked, setTracked] = useState<boolean>(false)
  const { getIdForShopwareProduct, shopwareProducts, api } =
    useContext(shopContext)
  const [quantity, setQuantity] = useState<number>(1)

  useEffect(() => {
    if (data) {
      setSelectedVariant(
        data.children.filter((p) => p.classname !== 'Product') &&
          data.children?.filter(
            (p) =>
              p.classname === 'Product' &&
              p?.ShopData?.find(
                (d) => d.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID
              )
          )?.length > 0
          ? (data.children
              .filter(
                (p) =>
                  p.classname === 'Product' &&
                  p?.ShopData?.find(
                    (d) => d.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID
                  )
              )
              .find((x) =>
                x?.OSProductNumber?.toLowerCase().includes('xm')
              ) as IProduct) ??
              (data.children.filter(
                (p) => p.classname === 'Product'
              )[0] as IProduct)
          : data
      )
      setQuantity(1)
    }
  }, [data])

  useEffect(() => {
    if (selectedVariant) {
      const distinctImages = _.uniqWith(
        [
          ...(selectedVariant.DosenBild ? [selectedVariant.DosenBild] : []),
          ...(selectedVariant?.Images?.map((w) => w.image) ??
            data.Images?.map((w) => w.image) ??
            []),
          ...(selectedVariant?.PackagingRelation &&
          selectedVariant?.PackagingRelation[0]?.Images
            ? selectedVariant?.PackagingRelation[0]?.Images?.map((w) => w.image)
            : []),
        ],
        (item1, item2) => item1?.id === item2?.id
      )

      setImages(distinctImages.filter((i) => i !== undefined && i !== null))
    }
  }, [selectedVariant])

  useEffect(() => {
    if (shopwareProducts && selectedVariant) {
      setShopwareProduct(getIdForShopwareProduct(selectedVariant || data))
      console.log(shopwareProduct)
    }
  }, [shopwareProducts, selectedVariant])

  useEffect(() => {
    if (shopwareProduct && selectedVariant && data?.ArticleName) {
      if (!tracked) {
        setTracked(true)
        trackItemView(
          { ...selectedVariant, ArticleName: data?.ArticleName },
          shopwareProduct
        )
      }
    }
    if (
      shopwareProduct &&
      data &&
      data.OSProductNumber &&
      data.OSProductNumber.startsWith('GS00x')
    ) {
    }
  }, [shopwareProduct, selectedVariant])

  const { t } = useTranslation('common')
  const { data: genericElements } = useProductElements(router.locale)

  const { isXs } = useBreakpoints()
  return (
    <>
      {seoSlugs && (
        <NextSeo
          languageAlternates={Object.keys(seoSlugs.slugs).map((k) => {
            return {
              hrefLang: k,
              href: seoSlugs.slugs[k],
            }
          })}
        />
      )}
      {data && (
        <>
          <Head>
            <title>
              {(data &&
                data.SEO?.length > 0 &&
                isArray(data.SEO) &&
                data.SEO[0].Title) ??
                (data && data.ArticleName)}
            </title>
            {structuredDataStatic && (
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(structuredDataStatic),
                }}
              />
            )}

            {data.SEO && isArray(data.SEO) && data.SEO?.length > 0 && (
              <>
                <meta name="keywords" content={data?.SEO[0].Keywords} />
                <meta
                  name="description"
                  content={
                    (data?.SEO[0].Description !== ''
                      ? data?.SEO[0].Description
                      : undefined) ??
                    data.NewShortDescription ??
                    data.GoodToKnow
                  }
                />
                <meta
                  name="title"
                  content={
                    (data && data.SEO?.length > 0 && data.SEO[0].Title) ??
                    (data && data.ArticleName)
                  }
                />
              </>
            )}
          </Head>
          <RefLang router={router} />
          {images && data.SEO?.length > 0 && (
            <NextSeo
              title={
                (data && data.SEO?.length > 0 && data.SEO[0].Title) ??
                (data && data.ArticleName)
              }
              openGraph={{
                images: [
                  {
                    url:
                      images && images.length > 0
                        ? UrlBuilder(images[0].fullpath, 400, 'png', false)
                        : 'https://pim.stayspiced.com/sonstiges/failover.png',
                  },
                ],
                type: 'product',
                locale: router.locale,
                description:
                  data.SEO[0].Description ??
                  data.NewShortDescription ??
                  data.GoodToKnow,
                url: `${process.env.NEXT_PUBLIC_FRONTEND}${router.asPath}`,
                title:
                  (data && data.SEO?.length > 0 && data.SEO[0].Title) ??
                  (data && data.ArticleName),
              }}
              description={
                data.SEO[0].Description ??
                data.NewShortDescription ??
                data.GoodToKnow
              }
            />
          )}
          <script
            type="text/javascript"
            src={`https://t.adcell.com/js/inlineretarget.js?method=product&pid=10882&productId=${data.OSProductNumber}&productName=${data.ArticleName}&productSeparator=,`}
            async
            defer
          ></script>

          <div
            className={`fixed sm:hidden w-full sm:w-auto px-4 sm:px-0 bg-white bottom-0 sm:bottom-auto z-100 sm:z-auto sm:mx-0 flex gap-8 py-4`}
          >
            {shopwareProduct && shopwareProduct.price && (
              <CtaButtons
                data={selectedVariant as IProduct}
                shopwareProduct={shopwareProduct}
                withWishlist
                amount={quantity}
                rawData={data}
                className="inline-block grow px-8"
              />
            )}
          </div>

          <div className="container mx-auto w-full pb-4">
            {data && (
              <div className="sm:flex">
                <div className="w-full flex-col sm:flex sm:w-8/12">
                  <div
                    className={`flex flex-col w-full relative bg-${data.ColorCode} bg-opacity-70`}
                  >
                    <Breadcrumb Product={data} />
                    <div className="absolute top-10 flex grid grid-cols-1 sm:left-4 z-[99]">
                      {data.Sale &&
                        data.Sale.map((item, index) => {
                          return (
                            <>
                              {(item.Shop === undefined ||
                                item.Shop?.find(
                                  (x) =>
                                    x.id.toString() ===
                                    process.env.NEXT_PUBLIC_SHOPWARE_ID
                                )) && (
                                <div
                                  className={`cursor-pointer p-1 sm:p-0 sm:w-32 sm:h-32 mt-1 sm:mt-4 clear-both row relative float-none flex sm:mx-auto text-center sm:rounded-full bg-${item.Farbe}`}
                                  key={index}
                                >
                                  <div className="m-auto flex font-bold sm:text-2xl italic font-swDisp">
                                    {item.Name}
                                  </div>
                                </div>
                              )}
                            </>
                          )
                        })}
                    </div>
                    <ImageSlider
                      images={images}
                      video={data?.HeroVideo}
                      selectedVariant={selectedVariant}
                      baseVariant={data}
                    />
                    <WishlistButton productId={shopwareProduct?.id} />
                  </div>
                  {!data.AlternativeView && !breakpoint.isXs && (
                    <ProductContentDefault
                      data={data}
                      selectedVariant={selectedVariant}
                    />
                  )}
                  {data.AlternativeView && !breakpoint.isXs && (
                    <ProductContentAlternative
                      data={data}
                      selectedVariant={selectedVariant}
                    />
                  )}
                </div>
                {/* TRENNER */}
                <div className="w-full sm:w-4/12">
                  <div className="sticky top-[13rem] p-4">
                    <p className="flex w-full capitalize">
                      {t(
                        ValueForIdentifier(data, '0102')?.selection?.toString()
                      )}
                      {data && <ReviewContainer data={data} />}
                    </p>
                    <h1 className="py-4 font-swDisp text-2xl font-bold italic sm:text-4xl">
                      {data.ArticleName}
                    </h1>
                    {data.Sale &&
                      data.Sale.map((item, index) => {
                        return (
                          <>
                            {(item.Shop === null ||
                              item.Shop?.find(
                                (x) =>
                                  x.id.toString() ===
                                  process.env.NEXT_PUBLIC_SHOPWARE_ID
                              )) && (
                              <>
                                {item.ShortDescription && (
                                  <div
                                    className={`flex text-center italic p-2 mx-auto text-centerl bg-${item.Farbe}`}
                                    key={index}
                                  >
                                    <div
                                      className="m-auto flex base mx-auto font-swDisp font-bold"
                                      dangerouslySetInnerHTML={{
                                        __html: item.ShortDescription,
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )
                      })}
                    <BulletPoints key={`bullet-${data.id}`} data={data} />
                    {shopwareProduct &&
                      shopwareProduct.price &&
                      shopwareProduct.price.length > 0 && (
                        <>
                          <div className={`flex flex-col`}>
                            <ProductPrice shopwareProduct={shopwareProduct} />
                            <div
                              className={`flex flex-col justify-center sm:justify-start`}
                            >
                              <p className="font-swDisp text-xs sm:text-sm sm:pt-4 ">
                                {' '}
                                {t('INCL_COSTS')} / {` `}
                                {(shopwareProduct?.price[0].listPrice?.gross ??
                                  shopwareProduct?.price[0].gross) > 30 ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: t('DELIVERY_FREE'),
                                    }}
                                  ></span>
                                ) : (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: t('DELIVERY_INFO'),
                                    }}
                                  ></span>
                                )}
                              </p>

                              {(data?.SellUnit?.toLowerCase() === 'kg' ||
                                data?.AlternativeView) && (
                                <p className="font-swDisp text-xs sm:text-sm ">
                                  {t('BASE_PRICE')}:{' '}
                                  {data?.SellUnit?.toLowerCase() === 'kg' ||
                                  data?.AlternativeView
                                    ? `${formatter.format(
                                        (shopwareProduct?.price[0].listPrice
                                          ?.gross ??
                                          shopwareProduct?.price[0].gross ??
                                          0) /
                                          (selectedVariant?.NettoWeight * 10)
                                      )} / 100g`
                                    : ``}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="py-2 pt-8">
                            {data.children &&
                              data.children.filter(
                                (p) => p.classname === 'Product'
                              ).length > 0 && (
                                <>
                                  {!isXs && (
                                    <p className="pb-1 font-swText text-base">
                                      {t('SELECT_SIZE')}
                                    </p>
                                  )}
                                  <div className="flex">
                                    <div className="relative inline-block grow text-gray-700">
                                      <select
                                        className="focus:shadow-outline h-14 w-full border-black  appearance-none border pl-3 pr-6 text-base placeholder:text-gray-600"
                                        onChange={(value) =>
                                          setSelectedVariant(
                                            data.children.find(
                                              (variant) =>
                                                variant.OSProductNumber ===
                                                value.target.value
                                            ) as IProduct
                                          )
                                        }
                                      >
                                        {data.children
                                          .filter(
                                            (child) =>
                                              child.classname === 'Product' &&
                                              getIdForShopwareProduct(child) !==
                                                undefined
                                          )

                                          .map((child: IProduct) => (
                                            <option
                                              value={child.OSProductNumber}
                                              selected={
                                                selectedVariant === child
                                              }
                                              key={child.OSProductNumber}
                                            >
                                              {child.PackagingRelation &&
                                                child.PackagingRelation[0]
                                                  ?.PackagingName}
                                            </option>
                                          ))}
                                      </select>
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                        <svg
                                          className="h-8 w-8 fill-current"
                                          viewBox="0 0 20 20"
                                        >
                                          <path
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                    <div className="relative flex  w-1/5 w-full flex-1 grow-0 flex-row bg-transparent">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setQuantity(quantity - 1)
                                        }
                                        className="h-full w-12 cursor-pointer border border-black text-gray-600 outline-none  hover:bg-gray-400 hover:text-gray-700"
                                      >
                                        <span className="m-auto text-2xl bold">
                                          −
                                        </span>
                                      </button>
                                      <input
                                        className="text-md md:text-basecursor-default flex w-12 items-center border border-black text-center font-semibold text-gray-700  outline-none outline-none hover:text-black focus:text-black focus:outline-none"
                                        name="custom-input-number"
                                        disabled
                                        value={quantity}
                                      />
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setQuantity(quantity + 1)
                                        }
                                        className="h-full w-12 cursor-pointer border border-black text-gray-600 hover:bg-gray-400 hover:text-gray-700"
                                      >
                                        <span className="m-auto text-2xl bold">
                                          +
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            {(data.children === undefined ||
                              data.children.filter(
                                (p) => p.classname === 'Product'
                              ).length === 0) && (
                              <div className="flex-0 relative inline-block hidden w-full w-full grow pt-4 text-gray-700 sm:block">
                                <div className="relative flex  w-1/5 w-full flex-1 grow-0 flex-row bg-transparent">
                                  <button
                                    type="button"
                                    onClick={() => setQuantity(quantity - 1)}
                                    className="h-full w-12 cursor-pointer border border-1 text-gray-600 outline-none  hover:bg-gray-400 hover:text-gray-700"
                                  >
                                    <span className="m-auto text-2xl font-bold">
                                      −
                                    </span>
                                  </button>
                                  <input
                                    className="text-md md:text-basecursor-default flex w-12 items-center border border-1 text-center font-semibold text-gray-700  outline-none outline-none hover:text-black focus:text-black focus:outline-none"
                                    name="custom-input-number"
                                    disabled
                                    value={quantity}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => setQuantity(quantity + 1)}
                                    className="h-full w-12 cursor-pointer border border-1 text-gray-600 hover:bg-gray-400 hover:text-gray-700"
                                  >
                                    <span className="m-auto text-2xl font-bold">
                                      +
                                    </span>
                                  </button>
                                </div>
                              </div>
                            )}
                            <DeliveryInfo shopwareProduct={shopwareProduct} />

                            {!isXs && (
                              <div className="flex gap-8 py-4">
                                {shopwareProduct && shopwareProduct.price && (
                                  <CtaButtons
                                    data={selectedVariant as IProduct}
                                    shopwareProduct={shopwareProduct}
                                    amount={quantity}
                                    rawData={data}
                                    className="inline-block grow px-8"
                                  />
                                )}
                              </div>
                            )}
                          </div>

                          <PaymentLogos />
                          <div className={`grid grid-cols-12 gap-2 pt-8`}>
                            {genericElements?.Icons?.map((icon) => (
                              <DeclarationIconProduct {...icon} />
                            ))}
                          </div>
                        </>
                      )}
                    {/* <BaseText text={data.NewShortDescription} /> */}
                  </div>
                </div>
                {!data.AlternativeView && breakpoint.isXs && (
                  <div className="px-4">
                    <ProductContentDefault
                      data={data}
                      selectedVariant={selectedVariant}
                    />
                  </div>
                )}
                {data.AlternativeView && breakpoint.isXs && (
                  <div className="px-4">
                    <ProductContentAlternative
                      data={data}
                      selectedVariant={selectedVariant}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {genericElements &&
        genericElements.children?.map((child) => {
          if (child.classname !== 'Accordion') {
            return <ComponentFactory data={[child]} key={child.id} />
          }
          return (
            <>
              {data?.children?.find((c) => c.classname === 'Accordion') && (
                <ComponentFactory
                  data={[
                    data?.children?.find((c) => c.classname === 'Accordion'),
                  ]}
                  key={
                    data?.children?.find((c) => c.classname === 'Accordion').id
                  }
                />
              )}
              <ComponentFactory data={[child]} key={child.id} />
            </>
          )
        })}
    </>
  )
}

Index.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export default Index

export async function getStaticProps({ params, locale }) {
  const data = (await loadProduct(params.slug, locale)) ?? null
  if (data === null) {
    return {
      notFound: true,
    }
  }
  let seoSlugs
  try {
    seoSlugs = await axios.get(
      `https://pim.stayspiced.com/ecom/languagepaths?locale=${locale}&slug=/produkt/${params.slug}`
    )
  } catch (ex) {
    seoSlugs = undefined
  }

  const selectedVariant: IProduct = (data.children.find((child) =>
    child.OSProductNumber?.includes('xM')
  ) ?? data) as IProduct

  const shopwareData = await axios.get(
    `https://pim.stayspiced.com/api/products?file=${process.env.NEXT_PUBLIC_PRICELIST}.json`
  )
  const res = shopwareData.data.data
  const shopwareProducts = res.flat()

  const shopwareProductId =
    selectedVariant.ShopData.find(
      (x) =>
        x.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID &&
        x.Product?.OSProductNumber === selectedVariant.OSProductNumber
    )?.ShopwareId ?? null

  const shopwareProduct = shopwareProducts.find(
    (s) => s.id === shopwareProductId
  )

  const ratingCount = data.Reviews?.length ?? 1
  const mediaRating = _.sumBy(data.Reviews, (r) => r.Rating) / ratingCount

  const structuredDataStatic = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: data.ArticleName,
    image:
      selectedVariant?.Images?.map(
        (image) =>
          `https://spiceworld.azureedge.net/?image=${image.image.fullpath}`
      ) ?? null,
    description: data.NewShortDescription ?? data.GoodToKnow,
    sku: selectedVariant.OSProductNumber,
    mpn: selectedVariant.OSProductNumber,
    gtin13: selectedVariant.EANCode,
    brand: 'STAY SPICED!',
    review:
      data.Reviews?.map((review) => {
        return {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: review.Rating,
            bestRating: '5',
          },
          author: {
            '@type': 'Person',
            name: review.Name,
          },
        }
      }) ?? null,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: mediaRating,
      reviewCount: data.Reviews?.length,
    },
    offers: {
      '@type': 'Offer',
      url: `${process.env.NEXT_PUBLIC_FRONTEND}/produkt/${params.slug}`,
      priceCurrency: 'EUR',
      price:
        shopwareProduct?.price[0]?.listPrice?.gross ??
        shopwareProduct?.price[0]?.gross ??
        null,
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock',
    },
  } as any

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'footer'])),
      data,
      seoSlugs: seoSlugs?.data ?? null,
      structuredDataStatic: structuredDataStatic ?? null,
    },
    revalidate: 600,
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = [
    {
      params: { slug: 'erdbeer-fruchtpulver-100-frucht' },
    },
  ]

  return { paths, fallback: 'blocking' }
}
