import { Account } from 'components/account/account'
import React, { useState } from 'react'

export interface AccountContext {
  menuState: any
  setMenuState: any
}

export const useAccountContext = (): AccountContext => {
  const [menuState, setMenuState] = useState<JSX.Element>(<Account />)

  return {
    menuState,
    setMenuState,
  }
}

export const accountContext = React.createContext<AccountContext>(undefined)
