import { Product } from '@shopware-pwa/commons/interfaces/models/content/product/Product'
import axios from 'axios'
import { useTranslation } from 'next-i18next'
import React, { useContext, useEffect, useState } from 'react'

import { shopContext } from '../../providers/shop-context'

export const BasketPromotionItem = ({ promotion }) => {
  const { cartInformation, addToBasket } = useContext(shopContext)
  const [isValid, setIsValid] = useState(false)
  const { t } = useTranslation('common')
  useEffect(() => {
    if (cartInformation) {
      setIsValid(
        cartInformation.lineItems.filter((l) =>
          promotion.productSku.includes((l.payload as Product)?.productNumber)
        ).length >= promotion.productCount &&
          cartInformation.lineItems.filter((l) =>
            (l.payload as Product)?.productNumber?.includes(
              promotion.targetProduct.sku
            )
          ).length === 0
      )
    }
  }, [cartInformation])

  return (
    <>
      {isValid === true && (
        <div key={promotion.id} className={`grid grid-cols-6 pt-4`}>
          <img
            src={`${process.env.NEXT_PUBLIC_SVG_URL}${promotion.image}`}
            className={`img-fluid w-32 hidden sm:block`}
          />
          <div className={`justify-center flex flex-col col-span-4`}>
            <p className={`font-swDisp text-sm font-bold italic sm:text-base`}>
              {promotion.text}
            </p>
          </div>
          <div
            className={`flex flex-col justify-center col-span-2 sm:col-span-1`}
          >
            <button
              type="button"
              className={` w-full py-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed`}
              onClick={() =>
                addToBasket(promotion.targetProduct.swId, null, 1, 'basket')
              }
            >
              {t('TO_BASKET')}
            </button>
          </div>
        </div>
      )}
    </>
  )
}
export const BasketPromotion = () => {
  const [promotions, setPromotions] = React.useState([])

  useEffect(() => {
    axios
      .get(
        `https://pim.stayspiced.com/discount/${process.env.NEXT_PUBLIC_SHOPWARE_ID}`
      )
      .then((response) => {
        setPromotions(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <>
      {promotions.map((promotion) => (
        <BasketPromotionItem key={promotion.id} promotion={promotion} />
      ))}
    </>
  )
}

export default BasketPromotion
