import React, { useContext } from 'react'

import { languageContext } from '../../context/language-context'
import { useFooter } from '../../graph/get-footer'

export const ShippingLogos = () => {
  const sizeClass = 'w-56'
  const { currentLanguage } = useContext(languageContext)

  const { data } = useFooter(currentLanguage)

  return (
    <div className="sm:mr-auto hidden sm:flex flex-row flex-wrap">
      {data &&
        data.ShippingIcons &&
        data.ShippingIcons.map((img) => (
          <img
            src={`https://pim.stayspiced.com${img.image.fullpath}`}
            className={sizeClass}
            key={img.image.filename}
          />
        ))}
    </div>
  )
}
