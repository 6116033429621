import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Modal } from '@mui/material'
import { SxProps } from '@mui/system'
import { RegisterBox } from 'components/account/register-box'
import { PictureTag } from 'components/helper/picture-tag'
import { languageContext } from 'context/language-context'
import Flags from 'country-flag-icons/react/3x2'
import { useHeader, useTopHeader } from 'graph/get-header'
import _ from 'lodash'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import Link from 'next-translate-routes/link'
import { useRouter } from 'next-translate-routes/router'
import React, { useContext, useEffect, useState } from 'react'

import { searchContext, useSearchContext } from '../../context/search-context'
import useBreakpoints from '../../hooks/use-breakpoints'
import IconBasket from '../../icons/icon-basket'
import IconHeart from '../../icons/icon-heart'
import { IconMagnifier } from '../../icons/icon-magnifier'
import IconUser from '../../icons/icon-user'
import { shopContext } from '../../providers/shop-context'
import { LoginBox } from '../account/login-box'
import { ConditionalLink } from '../building-blocks/banner'
import { HeaderRootDesktop } from '../header/header-root-desktop'
import { HeaderRootMobile } from '../header/header-root-mobile'
import { SearchBar } from '../search/search-bar'
import { SearchResults } from '../search/search-results'

interface AccountHeaderComponentProps {
  showLogin: any
}

export const AccountHeaderComponent = ({
  showLogin,
}: AccountHeaderComponentProps) => {
  const { isLoggedIn } = useContext(shopContext)

  return (
    <>
      {isLoggedIn && (
        <Link href="/account">
          <a>
            <IconUser
              width={25}
              className={` hover:text-red-600 fill-current hover:text-red-500`}
            />
          </a>
        </Link>
      )}
      {!isLoggedIn && (
        <button onClick={() => showLogin(true)}>
          <IconUser
            width={25}
            className={` hover:text-red-600 fill-current hover:text-red-500`}
          />
        </button>
      )}
    </>
  )
}

export const AccountMerkzettelComponent = ({
  showLogin,
}: AccountHeaderComponentProps) => {
  const { wishlistProductCount, isLoggedIn } = useContext(shopContext)

  return (
    <>
      {isLoggedIn && (
        <Link href="/wunschliste">
          <a>
            <div className="relative inline-flex">
              <IconHeart
                width={25}
                className={` fill-current hover:text-red-500`}
              >
                <div className="absolute -top-3 -right-3 inline-flex h-5 w-5 items-center justify-center rounded-full bg-gray-200 text-red-600">
                  {wishlistProductCount}
                </div>
              </IconHeart>
            </div>
          </a>
        </Link>
      )}
      {!isLoggedIn && (
        <button onClick={() => showLogin(true)}>
          <IconHeart
            width={25}
            className={` fill-current hover:text-red-500`}
          />
        </button>
      )}
    </>
  )
}

export const TopHeader = () => {
  const { currentLanguage } = useContext(languageContext)
  const { isXs } = useBreakpoints()

  const { data } = useTopHeader(currentLanguage)

  return (
    <>
      {data && data.Aktiv && !isXs && (
        <div
          className={`h-8 bg-${data.Farbe}  flex justify-center items-center whitespace-nowrap`}
        >
          <p className={`text-center font-swDisp font-bold text-sm sm:text-lg`}>
            <ConditionalLink
              shouldShowLink={data.Landingpage !== undefined}
              link={`${data?.Landingpage?.Slug}`}
              className={`hover:underline`}
            >
              <p>{data.Headline}</p>
            </ConditionalLink>
          </p>
        </div>
      )}
    </>
  )
}

export const TrustHeader = () => {
  const { currentLanguage } = useContext(languageContext)
  const { isXs } = useBreakpoints()
  const { userInformation } = useContext(shopContext)

  const { t } = useTranslation('common')

  return (
    <>
      <div className={`h-8 bg-gray-300 items-center sm:flex hidden`}>
        <div className={`flex container mx-auto items-center`}>
          <div className={`flex flex-row space-x-2 w-1/2 mr-auto items-center`}>
            <Link href={`/geschichte`}>
              <p
                className={`font-swDisp font-bold italic  hover:underline cursor-pointer`}
              >
                <img className={`inline px-2`} src={`/icons/Regional.png`} />
                {t('REGIONAL')}
              </p>
            </Link>

            <span className={`inline pl-2`}>|</span>
            <Link href={`/qualitaet-der-reingewuerze`}>
              <p
                className={`font-swDisp font-bold italic hover:underline  cursor-pointer`}
              >
                <img
                  className={`inline px-2`}
                  src={`/icons/NoAdditional.png`}
                />
                {t('NO_ADDITIONAL')}
              </p>
            </Link>

            <span className={`inline px-2`}>|</span>

            <Link href={`/faq`}>
              <p
                className={`font-swDisp font-bold italic hover:underline cursor-pointer`}
              >
                <img className={`inline pr-2`} src={`/icons/Shipping.png`} />
                {t('SHIPPING_FREE')}
              </p>
            </Link>
          </div>
          <div className={`flex flex-row space-x-2 w-1/2 justify-end`}>
            <p className={`font-swDisp font-bold italic  hover:underline`}>
              <a
                href={`mailto:support@stayspiced.com`}
                className={`hover:underline`}
              >
                <img className={`inline px-2`} src={`/icons/Mail.png`} />
                {t('MAIL_CONTACT')}
              </a>
            </p>
            <span className={`inline px-2`}>|</span>
            <p className={`font-swDisp font-bold italic`}>
              <a href={`tel:+43662890919`} className={`hover:underline`}>
                <img className={`inline pr-2`} src={`/icons/Phone.png`} />
                {t('TELEFON_CONTACT')}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div
        className={`h-8 bg-gray-300 items-center flex sm:hidden mx-auto w-full`}
      >
        <div
          className={`flex container mx-auto items-center text-center w-full`}
        >
          <div
            className={`flex flex-row w-full mx-auto text-center items-center`}
          >
            <Link href={`/faq`}>
              <p
                className={`font-swDisp font-bold italic hover:underline cursor-pointer text-center mx-auto w-full`}
              >
                <img className={`inline pr-2`} src={`/icons/Shipping.png`} />
                {t('SHIPPING_FREE')}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export const Header = () => {
  const { currentLanguage, switchLanguage } = useContext(languageContext)
  const { setShopLanguage } = useContext(shopContext)

  const { data } = useHeader(currentLanguage)

  const {
    cartInformation,
    showMenu,
    setShowMenu,
    basketArticle,
    setBasketArticle,
    showLogin,
    setShowLogin,
    userInformation,
    setLoginState,
    loginState,
    loginMessage,
    setLoginMessage,
    orderInformation,
  } = useContext(shopContext)

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const search = useSearchContext()
  const { isXs } = useBreakpoints()

  const { t } = useTranslation('common')

  const router = useRouter()

  useEffect(() => {
    if (router.asPath) {
      setShowSearch(false)
    }
  }, [router.asPath])

  const style = isXs
    ? ({
        overflowY: 'scroll',
        overflowX: 'none',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2,
      } as SxProps)
    : ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '33%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2,
      } as SxProps)

  useEffect(() => {
    if (router.asPath) {
      setShowLogin(false)
    }
  }, [router.asPath])

  const language = router.locale ?? 'de'

  const [showLanguageSelector, setShowLanguageSelector] = useState(false)

  const { route } = router

  useEffect(() => {
    // @ts-ignore
    window?._upEventBus?.publish('uptain.readData')
  }, [cartInformation, orderInformation, userInformation])

  return (
    <>
      <searchContext.Provider value={search}>
        <Head>
          <>
            <script
              type="text/javascript"
              id="__up_data_qp"
              src="https://app.uptain.de/js/uptain.js?x=cxQNU6h4RsHXQvB4"
              data-scv={
                orderInformation?.price?.positionPrice ??
                cartInformation?.price?.positionPrice ??
                0
              }
              data-currency="EUR"
              data-email={userInformation?.email}
              data-usedvoucher={
                orderInformation?.lineItems
                  ?.filter((lineItem) => lineItem?.type === 'promotion')
                  .map((lineItem) => lineItem?.referencedId)
                  .join(',') ??
                cartInformation?.lineItems
                  ?.filter((lineItem) => lineItem?.type === 'promotion')
                  .map((lineItem) => lineItem?.referencedId)
                  .join(',')
              }
              data-success={route.includes('success') ? 1 : 0}
              data-ordernumber={orderInformation?.orderNumber}
              async
              defer
            ></script>
          </>
          <script
            src={`/tracker.js`}
            async
            data-usercentrics="marketing"
          ></script>
        </Head>

        <div className="sticky top-0 z-100 bg-white ">
          <TopHeader />
          <TrustHeader />

          {data && (
            <>
              <div className="flex w-full flex-row px-4 sm:p-0">
                <div className="container mx-auto flex content-center items-center gap-4 py-4 align-middle sm:gap-8">
                  {isXs && (
                    <button onClick={() => setShowMenu(!showMenu)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"></path>
                      </svg>
                    </button>
                  )}
                  <Link href="/">
                    <a>
                      <img
                        className="m-1 w-8 sm:w-64"
                        src={`${
                          isXs
                            ? '/icons/logo-mobile.png'
                            : `https://pim.stayspiced.com/${data.Logo.image.fullpath}`
                        }`}
                      />
                    </a>
                  </Link>
                  {!isXs && <SearchBar />}
                  <div className="ml-auto z-200">
                    <ul className="ml-left flex items-center justify-center gap-6 z-200">
                      {isXs && (
                        <li>
                          <button onClick={() => setShowSearch(!showSearch)}>
                            <IconMagnifier
                              width={25}
                              className={` fill-current `}
                            />
                          </button>
                        </li>
                      )}
                      <li onClick={() => setShowLanguageSelector(true)}>
                        {language === 'de' && (
                          <Flags.DE className="w-8 cursor-pointer" />
                        )}
                        {language === 'it' && (
                          <Flags.IT className="w-8 cursor-pointer" />
                        )}

                        {language === 'fr' && (
                          <Flags.FR className="w-8 cursor-pointer" />
                        )}
                        {showLanguageSelector === true && (
                          <div
                            className={`absolute my-2 border  bg-white px-4 -ml-4`}
                          >
                            {language !== 'de' && (
                              <span
                                onClick={() => {
                                  switchLanguage('de')
                                  setShopLanguage('de')
                                }}
                              >
                                <Flags.DE className="w-8 cursor-pointer py-2" />
                              </span>
                            )}
                            {language !== 'it' && (
                              <span
                                onClick={() => {
                                  switchLanguage('it')
                                  setShopLanguage('it')
                                }}
                              >
                                <Flags.IT className="w-8 cursor-pointer py-2" />
                              </span>
                            )}
                            {language !== 'fr' && (
                              <span
                                onClick={() => {
                                  switchLanguage('fr')
                                  setShopLanguage('fr')
                                }}
                              >
                                <Flags.FR className="w-8 cursor-pointer py-2" />
                              </span>
                            )}
                          </div>
                        )}
                      </li>

                      <li>
                        <AccountHeaderComponent showLogin={setShowLogin} />
                      </li>
                      <li>
                        <AccountMerkzettelComponent showLogin={setShowLogin} />
                      </li>

                      <li>
                        <div className="relative inline-flex">
                          <Link href="/warenkorb?step=0">
                            <a>
                              <IconBasket
                                width={25}
                                className="stroke-current hover:text-red-500"
                              >
                                <div className="absolute -top-3 -right-3 inline-flex h-5 w-5 items-center justify-center rounded-full bg-aktion text-black">
                                  {(cartInformation &&
                                    cartInformation?.lineItems &&
                                    _.sumBy(
                                      cartInformation?.lineItems,
                                      (x) => x.quantity
                                    )) ??
                                    0}
                                </div>
                              </IconBasket>
                            </a>
                          </Link>
                          {basketArticle && (
                            <div className="absolute top-8 -right-4 z-200 flex w-96 sm:w-104 flex-col items-center border bg-white/95 shadow-lg sm:right-0">
                              <div className="flex w-full border-b border-black">
                                <p className="mr-auto w-full py-2 pl-4 font-swDisp text-2xl font-bold italic">
                                  {t('ARTICLE_ADDED')}
                                </p>
                                <button
                                  onClick={() => setBasketArticle(undefined)}
                                  type="button"
                                >
                                  <FontAwesomeIcon
                                    className="ml-auto w-8 text-xxl hover:text-9"
                                    icon={faXmark}
                                  />
                                </button>
                              </div>
                              <div className=" flex w-full flex-row border-b border-black bg-white">
                                <div className="w-1/4">
                                  <PictureTag
                                    imageUrl={
                                      (basketArticle.Images &&
                                        basketArticle?.Images[0]?.image
                                          ?.fullpath) ??
                                      '/sonstiges/failover.png'
                                    }
                                    sizesVW={'100'}
                                    className={`w-full mx-auto p-4`}
                                  />
                                </div>
                                <div className="flex w-3/4 flex-col justify-center">
                                  <span className="font-swText text-xs font-light text-black sm:text-sm">
                                    ArtNr. {basketArticle?.OSProductNumber}
                                  </span>
                                  <p className="font-swDisp text-sm font-bold italic sm:text-base">
                                    {basketArticle?.ArticleName}
                                  </p>
                                </div>
                              </div>
                              <div className="mx-auto flex w-3/4 flex-col">
                                <Link href="/warenkorb">
                                  <a
                                    className={`text-center my-4 w-full py-2 font-bold inline-block border border-black bg-white hover:bg-black hover:text-white font-swDisp`}
                                  >
                                    {t('GOTO_BASKET')}
                                  </a>
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container mx-auto flex hidden w-full flex-row sm:block">
                {data && <HeaderRootDesktop data={data} />}
              </div>
            </>
          )}
        </div>
        {isXs && data && <HeaderRootMobile data={data} />}
        {isXs && showSearch && <SearchBar />}
        <Modal open={showLogin}>
          <Box sx={style}>
            {loginState && (
              <LoginBox
                showLogin={setShowLogin}
                setShowLogin={setShowLogin}
                setLoginState={setLoginState}
                loginMessage={loginMessage}
              />
            )}
            {!loginState && (
              <RegisterBox
                showLogin={setShowLogin}
                setShowLogin={setShowLogin}
                setLoginState={setLoginState}
                setLoginMessage={setLoginMessage}
                allowGuest={true}
              />
            )}
          </Box>
        </Modal>
        <SearchResults />
      </searchContext.Provider>
    </>
  )
}
