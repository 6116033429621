import { gql, request } from 'graphql-request'
import { IProduct } from 'models/product'

import {
  DeclarationIconsFragment,
  LandingPageTreeFragment,
  ProductFragment,
} from './graph-fragments'

const endpoint = process.env.NEXT_PUBLIC_GRAPH_URL

export async function loadProduct(product, currentLanguage, type = 'product') {
  const data = await request(
    endpoint,
    gql`
      query ($filter: String, $language: String) {
        getProductListing(
          defaultLanguage: $language
          first: 1
          sortBy: "OSProductNumber"
          filter: $filter
        ) {
          edges {
            node {
              ...ProductFragment
            }
          }
        }
      }
      ${ProductFragment}
      ${DeclarationIconsFragment}
      ${LandingPageTreeFragment}
    `,
    {
      filter: `{"$or": [{"slug": "${product}"}, {"OSProductNumber": "${product}"}]}`,
      language: currentLanguage,
    }
  )

  return data.getProductListing.edges.map((edge) => edge.node)[0] as IProduct
}
