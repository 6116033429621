import { gql, request } from 'graphql-request'
import { IFooter } from 'models/settings'
import { useQuery } from 'react-query'

import { DeclarationIconsFragment, ImageFragment } from './graph-fragments'

const endpoint = process.env.NEXT_PUBLIC_GRAPH_URL

export function useFooter(currentLanguage) {
  return useQuery([`${currentLanguage}footer`], async () => {
    const data = await request(
      endpoint,
      gql`
        query ($language: String, $footerNode: Int) {
          getFooter(defaultLanguage: $language, id: $footerNode) {
            classname
            NewsletterButton
            NewsletterHeadline
            NewsletterSubline
            NewsletterImage {
              ...ImageFragment
            }
            PaymentIcons {
              ...ImageFragment
            }
            ShippingIcons {
              ...ImageFragment
            }
            Logo {
              ...ImageFragment
            }
            Icons {
              ...DeclarationIconsFragment
            }
            children {
              ... on object_FooterColumn {
                classname
                Name
                children {
                  ... on object_FooterTrusted {
                    classname
                    id
                  }
                  ... on object_FooterSocial {
                    classname
                    id
                  }
                  ... on object_FooterItem {
                    Name
                    classname
                    children {
                      ... on object_FooterText {
                        classname
                        id
                        Description
                      }

                      ... on object_FooterLink {
                        Name
                        classname
                        Link
                        Category {
                          ... on object_CategoryVirtual {
                            id
                            classname
                            Slug: slug
                            Name: name
                          }

                          ... on object_CategoryShop {
                            id
                            classname
                            Slug
                            Name
                          }
                        }
                        Landingpage {
                          ... on object_LandingPage {
                            Name
                            Slug: slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ${ImageFragment}
        ${DeclarationIconsFragment}
      `,
      {
        language: currentLanguage,
        footerNode: Number.parseInt(process.env.NEXT_PUBLIC_FOOTER),
      }
    )

    return data.getFooter as IFooter
  })
}
